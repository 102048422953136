/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Header,
    Button,
    Modal,
    Dimmer,
    Loader,
    Grid,
    Input,
    Dropdown,
} from 'semantic-ui-react';
import {
    Table,
    Column,
    Cell
} from 'fixed-data-table';
import 'react-dates/lib/css/_datepicker.css';
import Utility from '../../Utility';
import Settings from '../../Settings';
import { ItemsCell } from './ProductList';
import { ItemsCellIcon } from './LeaseOut';

import { connect } from 'react-redux'
import { number } from 'prop-types';

class SplitProductModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            item: null,
            product: [],
            amount: 0,
            focus: false,
            staffs: [],
            staff_id: null,
        }
    }
    async componentDidMount() {
        if (this.props.id) {
            const resp = await Utility.getAsync(Settings.baseReportUrl + `/lease/${this.props.id}/split-product/`);
            if (resp.status_code >= 400) {
                alert(resp.data.detail || 'มีข้อผิดพลาด')
                this.props.onClose()
            } else {
                this.setState({ item: resp.data, loading: false, })
            }
            const resp_staff = await Utility.getAsync(Settings.baseReportUrl + `/staffs/?is_enabled=1&branch=${this.props.auth.branch.id}`);
            if (resp_staff.status_code < 400) {
                let staffs = [];
                for (let i in resp_staff.data) {
                    staffs.push({
                        key: resp_staff.data[i].id,
                        value: resp_staff.data[i].id,
                        text: resp_staff.data[i].name,
                    })
                }
                this.setState({ staffs: staffs })
            }
        }
    }

    render() {

        const originProduct = this.state.item ? this.state.item.product.filter((p) => !this.state.product.includes(p.qrcode)) : []
        const moveProduct = this.state.item ? this.state.item.product.filter((p) => this.state.product.includes(p.qrcode)) : []

        const moveNew = (e, position) => {
            let item = originProduct[position]
            const qr = this.state.product
            qr.push(item.qrcode)
            this.setState({
                product: qr,
            })
        }

        const moveOrigin = (e, position) => {
            let item = moveProduct[position]
            const qr = this.state.product.filter((q) => q !== item.qrcode)
            this.setState({
                product: qr,
            })
        }

        const submit = async () => {
            if (window.confirm(`ยืนยันแบ่งใบขายฝาก ${this.props.number} หรือไม่`)) {
                if (this.state.product.length === 0) {
                    alert('กรุณาเลือกสินค้าที่จะแบ่ง')
                    return
                }
                if (!this.state.staff_id) {
                    alert('กรุณาเลือกสินค้าที่จะแบ่ง')
                    return
                }
                if (this.state.item.product.length === this.state.product.length) {
                    alert('ไม่สามารถเลือกสินค้าทั้งหมดได้')
                    return
                }
                if (this.state.amount === 0) {
                    alert('กรุณาใส่จำนวนเงินที่แบ่ง')
                    return
                }
                if (this.state.amount >= this.state.item.amount) {
                    alert('ไม่สามารถใส่จำนวนเงินที่แบ่งทั้งหมดได้')
                    return
                }
                this.setState({ loading: true })
                const resp = await Utility.postAsync(`${Settings.baseReportUrl}/lease/${this.props.id}/split-product/`, {
                    number: this.state.item.new,
                    max: this.state.item.max_amount,
                    qrcode: this.state.product.join(','),
                    amount: this.state.amount,
                    staff_id: this.state.staff_id,
                })
                if (resp.status_code >= 400) {
                    alert(resp.data.detail || 'มีข้อผิดพลาด')
                    this.setState({ loading: false, })
                } else {
                    if (resp.data.success) {
                        alert(`แบ่งใบขายฝากจาก ${this.props.number} เป็น ${resp.data.number} สำเร็จ`)
                        this.props.onClose(true)
                    } else {
                        alert('ข้อมูลมีการเปลี่ยนแปลง')
                        this.componentDidMount()
                    }
                }
            }

        }

        return (
            <Modal size="large" open={true} >
                <Button id='btnClose' circular icon='close' basic floated='right' name='' onClick={() => this.props.onClose()} />
                <Modal.Header>
                    <Header as='h3'>
                        <Header.Content>
                            แบ่งใบขายฝาก {this.props.number}
                        </Header.Content>
                    </Header>
                </Modal.Header>
                <Modal.Content className='scrolling2 '>
                    <div >
                        <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        {this.state.item && <Grid divided='vertically'>
                            <Grid.Row >
                                <Grid.Column width={8}>
                                    <Header floated='left' as='h4'>{this.props.number} (หลัก)</Header>
                                    <Table
                                        rowsCount={originProduct.length}
                                        rowHeight={50}
                                        headerHeight={30}
                                        width={window.innerWidth}
                                        height={235}>
                                        <Column
                                            fixed
                                            header={<Cell></Cell>}
                                            cell={
                                                <ItemsCellIcon data={originProduct} onClickItem={moveNew} icon='angle right' />
                                            }
                                            width={50}
                                        />
                                        <Column
                                            header={<Cell>ชื่อสินค้า</Cell>}
                                            cell={
                                                <ItemsCell position='top left' width={180} data={originProduct} field="product_name" />
                                            }
                                            width={180}
                                        />
                                        <Column
                                            header={<Cell className='text-center'>%</Cell>}
                                            cell={
                                                <ItemsCell position='top center' width={60} textAlign='text-center' data={originProduct} field="category_name" />
                                            }
                                            width={60}
                                        />
                                        <Column
                                            header={<Cell ><div className="text-right">น้ำหนัก</div></Cell>}
                                            cell={
                                                <ItemsCell position='top right' width={80} data={originProduct} field="weight" textAlign='text-right' type='weight' />
                                            }
                                            width={80}
                                        />
                                        <Column
                                            header={<Cell ><div className="text-right">มูลค่า</div></Cell>}
                                            cell={
                                                <ItemsCell position='top right' width={100} data={originProduct} field="amount" textAlign='text-right' type='price' />
                                            }
                                            width={100}
                                        />
                                    </Table>
                                    <Grid style={{ marginTop: '1rem' }}>
                                        <Grid.Row>
                                            <Grid.Column width={8}>
                                                <Input fluid label='จำนวนรายการ' placeholder='จำนวนรายการ' value={originProduct.length} readOnly className='text-right' />
                                            </Grid.Column>
                                            <Grid.Column width={8}>
                                                <Input fluid label='น้ำหนัก' placeholder='น้ำหนัก' value={Utility.weightFormat(originProduct.reduce((sum, item) => sum + (item.weight), 0))} readOnly className='text-right' />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <Input fluid label='จำนวนเงินคงเหลือ' placeholder='จำนวนเงิน' value={Utility.priceFormat(this.state.item.max_amount - this.state.amount)} readOnly className='text-right' />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <Header floated='left' as='h4'>{this.state.item.new} (ใหม่)</Header>
                                    <Table
                                        rowsCount={moveProduct.length}
                                        rowHeight={50}
                                        headerHeight={30}
                                        width={window.innerWidth}
                                        height={235}>
                                        <Column
                                            fixed
                                            header={<Cell></Cell>}
                                            cell={
                                                <ItemsCellIcon data={moveProduct} onClickItem={moveOrigin} icon='angle left' />
                                            }
                                            width={50}
                                        />
                                        <Column
                                            header={<Cell>ชื่อสินค้า</Cell>}
                                            cell={
                                                <ItemsCell position='top left' width={180} data={moveProduct} field="product_name" />
                                            }
                                            width={180}
                                        />
                                        <Column
                                            header={<Cell className='text-center'>%</Cell>}
                                            cell={
                                                <ItemsCell position='top center' width={60} textAlign='text-center' data={moveProduct} field="category_name" />
                                            }
                                            width={60}
                                        />
                                        <Column
                                            header={<Cell ><div className="text-right">น้ำหนัก</div></Cell>}
                                            cell={
                                                <ItemsCell position='top right' width={80} data={moveProduct} field="weight" textAlign='text-right' type='weight' />
                                            }
                                            width={80}
                                        />
                                        <Column
                                            header={<Cell ><div className="text-right">มูลค่า</div></Cell>}
                                            cell={
                                                <ItemsCell position='top right' width={100} data={moveProduct} field="amount" textAlign='text-right' type='price' />
                                            }
                                            width={100}
                                        />
                                    </Table>
                                    <Grid style={{ marginTop: '1rem' }}>
                                        <Grid.Row>
                                            <Grid.Column width={8}>
                                                <Input fluid label='จำนวนรายการ' placeholder='จำนวนรายการ' value={moveProduct.length} readOnly className='text-right' />
                                            </Grid.Column>
                                            <Grid.Column width={8}>
                                                <Input fluid label='น้ำหนัก' placeholder='น้ำหนัก' value={Utility.weightFormat(moveProduct.reduce((sum, item) => sum + (item.weight), 0))} readOnly className='text-right' />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <Input fluid label='*จำนวนเงินที่แบ่ง' placeholder='จำนวนเงิน'
                                                    value={this.state.focus ? this.state.amount.toString().replace(/,/g, '') : this.state.amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                                    onBlur={() => {
                                                        setTimeout(() => {
                                                            if (this.state.focus) {
                                                                this.setState({
                                                                    focus: false,
                                                                });
                                                            }
                                                        }, 0);
                                                    }}
                                                    onFocus={(e) => {
                                                        e.target.select()
                                                        if (!this.state.focus) {
                                                            this.setState({
                                                                focus: true,
                                                            });
                                                        }
                                                    }}
                                                    onChange={(e, d) => {
                                                        d.value = d.value.toString().replace(/,/g, '')
                                                        d.value = d.value.toString().replace(/-/g, '')
                                                        d.value = d.value.toString().replace(/^0+/, '')
                                                        if (!isNaN(Number(d.value))) {
                                                            if (d.value.length === 0) {
                                                                d.value = 0
                                                            }
                                                            if (d.value.toString().split(".")[1] != undefined) {
                                                                if (d.value.toString().split(".")[1].length > 2) return
                                                            }
                                                            if (d.value >= this.state.item.max_amount) {
                                                                d.value = this.state.item.max_amount
                                                            }
                                                            this.setState({
                                                                amount: d.value,
                                                            })
                                                        }
                                                    }}
                                                    className='text-right' />
                                                <small>มูลค่าประมาณ {Utility.priceFormat(moveProduct.reduce((sum, item) => sum + (item.amount), 0))}</small>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <label>*พนักงาน</label>
                                                <Dropdown
                                                    fluid
                                                    search
                                                    selection
                                                    options={this.state.staffs}
                                                    value={this.state.staff_id}
                                                    onChange={(e, v) => {
                                                        this.setState({
                                                            staff_id: v.value
                                                        })
                                                    }}
                                                    placeholder='*พนักงาน'
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <Button
                                                    content='แบ่งใบขายฝาก'
                                                    size='small'
                                                    onClick={submit}
                                                    floated='right'
                                                    icon='print'
                                                    labelPosition='left'
                                                    type='button'
                                                    primary
                                                    disabled={this.state.product.length === 0 || this.state.amount === 0 || this.state.loading || !this.state.staff_id}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>}

                    </div>

                </Modal.Content>
            </Modal >
        );
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
    })
}

export default connect(mapStateToProps)(SplitProductModal)
