/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Table,
    Column,
    Cell
} from 'fixed-data-table';
import {
    Form,
    Input,
    Grid,
    Button,
    Dropdown,
    Icon,
    Header,
    Dimmer,
    Loader,
    Popup,
} from 'semantic-ui-react';
import moment from 'moment';
import { connect } from 'react-redux'
import Utility from '../../Utility';
import Settings from '../../Settings';
import LeaseOutModal from './LeaseOutModal';


class ItemsCell extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { rowIndex, field, type, data, ...props } = this.props;
        let v = type == 'number' ? Utility.numberFormat(data[rowIndex][field])
            : type == 'price' ? Utility.priceFormat(data[rowIndex][field])
                : type == 'weight' ? Utility.weightFormat(data[rowIndex][field])
                    : data[rowIndex][field];
        let width = (this.props.width * 0.9) + 'px'
        var ellipsisStyle = {
            whiteSpace: 'nowrap',
            width: width,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
        return (
            <Cell {...props}>
                <Popup
                    position={this.props.position}
                    content={data[rowIndex][field]}
                    trigger={
                        <div style={ellipsisStyle} className={(this.props.textAlign == null ? '' : this.props.textAlign) + ' text-cell'}>{v}</div>}
                />
            </Cell>
        );
    }
}

export class ItemsCellIcon extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { rowIndex, field, data, ...props } = this.props;
        return (
            <Cell {...props} >
                <Button color='grey' size='mini' circular icon={this.props.icon} onClick={(e, v) => {
                    this.props.onClickItem(e, rowIndex)
                }}></Button>
            </Cell>
        );
    }
}

class Search extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loader: false,
            overdue: '',
            start_price: '',
            end_price: '',
            number: '',
            code: '',
        }
        this.handlerSerach = this.handlerSerach.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
        this.handlerOverdue = this.handlerOverdue.bind(this)
        this.clearFormSearch = this.clearFormSearch.bind(this)
    }

    handlerInput(e, v) {
        v.value = v.value.toString().replace(/,/g, '')
        if (isNaN(Number(v.value))) {
        } else {
            if (v.value.length === 0) {
                v.value = ''
            }
            this.setState({
                [v.name]: v.value
            })
        }

        if (v.name == 'number') {
            this.setState({
                [v.name]: v.value.trim()
            })
        }
    }

    handlerOverdue(e, v) {
        v.value = v.value.toString().replace(/,/g, '')
        if (isNaN(Number(v.value))) {
        } else {
            let due_date = null
            if (v.value.length === 0) {
                v.value = 0
            }
            let overdue = parseInt(v.value)
            if (overdue < 0)
                overdue = overdue * -1
            if (v.value != '') {

                let start = moment().add(-overdue, 'day')
                due_date = Utility.formatDate2(start)
            }
            this.setState({
                [v.name]: overdue,
                due_date: due_date
            })
        }

    }

    handlerSerach(e) {
        this.props.onSubmit(e, Utility.jsonToQueryString(this.state))
    }

    clearFormSearch(){
        this.setState({
            overdue:'',
            due_date:'',
            start_price:'',
            end_price: '',
            number:'',
        })
    }

    render() {
        return (<Form size='tiny'>
            <table>
                <tr>
                    <td><label>ระยะเวลาเกินกำหนด</label></td>
                    <td><Input name='overdue'
                        min={0}
                        className='text-right'
                        // type='number'
                        maxLength={4}
                        placeholder='ระยะเวลาเกินกำหนด'
                        value={this.state.overdue.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                        onChange={this.handlerOverdue} /> วัน </td>
                </tr>

                <tr>
                    <td><label>จำนวนเงิน</label></td>
                    <td>
                        <Input name='start_price'
                            className='text-right'
                            placeholder='ตั้งแต่'
                            // value={this.state.start_price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                            onBlur={() => {
                                setTimeout(() => {
                                    if (this.state.focus_start_price) {
                                        this.setState({
                                            focus_start_price: false,
                                        });
                                    }
                                }, 0);
                            }}
                            onFocus={(e) => {
                                e.target.select()
                                if (!this.state.focus_start_price) {
                                    this.setState({
                                        focus_start_price: true,
                                    });
                                }
                            }}
                            value={this.state.focus_start_price ? this.state.start_price.toString().replace(/,/g, '') : this.state.start_price.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            onChange={this.handlerInput} />
                        -
                            <Input
                            className='text-right' name='end_price'
                            placeholder='ถึง'
                            onBlur={() => {
                                setTimeout(() => {
                                    if (this.state.focus_end_price) {
                                        this.setState({
                                            focus_end_price: false,
                                        });
                                    }
                                }, 0);
                            }}
                            onFocus={(e) => {
                                e.target.select()
                                if (!this.state.focus_end_price) {
                                    this.setState({
                                        focus_end_price: true,
                                    });
                                }
                            }}
                            value={this.state.focus_end_price ? this.state.end_price.toString().replace(/,/g, '') : this.state.end_price.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            // value={this.state.end_price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                            onChange={this.handlerInput} />
                    </td>
                </tr>
                <tr>
                    <td><label>เลขที่ขายฝาก</label></td>
                    <td><Input name='number'
                        value={this.state.number}
                        placeholder='เลขที่ขายฝาก'
                        onChange={this.handlerInput} /></td>
                </tr>
            </table>
            <Button icon onClick={this.handlerSerach}><Icon name='search' /> ค้นหา</Button>
            <Button icon onClick={this.clearFormSearch}> รีเซ็ต</Button>
        </Form>)
    }
}


class LeaseOut extends Component {

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            items2: [],
            net_items1: [],
            net_items2: [],
            loader: false,
            lease_out_modal: false,
        }

        this.handlerSerach = this.handlerSerach.bind(this)
        this.handlerAddItem = this.handlerAddItem.bind(this)
        this.handlerDeleteItem = this.handlerDeleteItem.bind(this)
        this.handlerAddAll = this.handlerAddAll.bind(this)
        this.handlerDeleteAll = this.handlerDeleteAll.bind(this)
        this.handlerCalucate = this.handlerCalucate.bind(this)
        this.handlerOut = this.handlerOut.bind(this)
    }

    handlerCalucate(e) {
        e.preventDefault()

        let items = this.state.items;
        let lease_in = '';
        let j = 0;
        let limit = items.length
        for (let i in items) {
            lease_in += items[i].id
            if (j < limit - 1)
                lease_in += ","
            j++
        }

        this.calculet1(lease_in)
        let items2 = this.state.items2;
        lease_in = '';
        j = 0;
        limit = items2.length
        for (let i in items2) {
            lease_in += items2[i].id
            if (j < limit - 1)
                lease_in += ","
            j++
        }
        this.calculet2(lease_in)
    }

    componentDidMount() {

        var branches = Utility.getFetch(Settings.baseUrl + '/branches/?is_enabled=1');
        Promise.all([branches]).then((values) => {
            this.branches = values[0];
            this.products = values[1];
            let branches = [];
            for (let i in values[0]) {
                branches.push({
                    key: values[0][i].id,
                    value: values[0][i].id,
                    text: values[0][i].name,
                });
            }

            this.setState({
                branches: branches,
                loader_active: false

            });
        });

        window.addEventListener('resize', this.updateDimensions);
        this.updateDimensions()

    }


  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    
  }

  updateDimensions = () => {
    let width_1 = document.getElementById('width_1')
    let width_2 = document.getElementById('width_2')
    this.setState({ table_width: width_1.clientWidth, table_width2: width_2.clientWidth });
  };

    handlerAddItem(e, position) {

        let items = this.state.items;
        let items2 = this.state.items2

        let item = items[position]

        for (let i in items2) {
            if (item.id == items2[i].id) {
                alert('เลือกรายการซ้ำกรุณาตรวจสอบใหม่อีกครั้ง')
                return;
            }
        }

        items2.push(JSON.parse(JSON.stringify(item)))
        items.splice(position, 1)

        this.setState({
            items2: items2,
            items: items
        })

    }
    handlerDeleteItem(e, position) {
        let items = this.state.items;
        let items2 = this.state.items2
        let check = false
        if (items.length != 0) {
            for (let i in items) {
                if (items[i].id == items2[position].id) {
                    check = true
                    break
                }
            }
            if (!check) {
                items.push(JSON.parse(JSON.stringify(items2[position])))
                items2.splice(position, 1)
            } else {
                items2.splice(position, 1)
            }

        } else {
            items.push(JSON.parse(JSON.stringify(items2[position])))
            items2.splice(position, 1)
        }

        this.setState({
            items2: items2,
            items: items
        })

    }

    handlerAddAll(e) {

        let items = this.state.items;
        let items2 = this.state.items2;
        let ids = new Set(items.map(d => d.id));
        let merged = [...items, ...items2.filter(d => !ids.has(d.id))];
        this.setState({
            items2: merged,
            items: []
        })
    }

    handlerDeleteAll(e) {
        let items2 = this.state.items2;
        let items = this.state.items;
        var ids = new Set(items.map(d => d.id));
        let merged = [...items, ...items2.filter(d => !ids.has(d.id))];
        this.setState({
            items: merged,
            items2: []
        })
    }

    submitQRcode() {
        let number = this.state.code.trim()
        var lease = Utility.getFetch(Settings.baseUrl + '/lease/qrcode/' + number);
        Promise.all([lease]).then((values) => {
            if (values[0].status) {
                let lease_product = Utility.getFetch(Settings.baseUrl + '/lease/' + values[0].lease.id + '/product/?is_enabled=1');
                Promise.all([lease_product]).then((product) => {
                    for (let i in product[0]) {
                        if (product[0][i].qrcode == number) {
                            product[0][i].check = true
                        } else {
                            product[0][i].check = false
                        }
                    }
                    this.setState({
                        lease:values[0].lease,
                        lease_product:product[0],
                        lease_out_modal:true,
                    })
                });
            }
            else {
                alert(values[0].detail)
            }
        })
        this.setState({ code: '' })
    }

    handlerSerach(e, q) {
        e.preventDefault()

        this.setState({ loader: true })
        const { branch } = this.props.auth
        let url = Settings.baseUrl + '/lease_data/?is_enabled=1&status_in=1,2&branch=' + branch.id + "&" + q
        Utility.get(url, (s, d) => {
            if (s) {

                let items = []
                let items2 = this.state.items2.map(function (item) { return item.id })
                for (let i in d.lease) {
                    if (d.lease[i].status != '4' && d.lease[i].status != '3' && !items2.includes(d.lease[i].id))
                        items.push(d.lease[i])
                }
                this.setState({
                    items: this.setFieldValue(items, d.branch, d.customer),
                    loader: false
                })

            }
        })
    }

    handlerOut(e) {
        e.preventDefault()

        let items2 = this.state.items2
        this.is_stock = false
        if (items2.length > 0) {
            if (!window.confirm('ยืนยันคัดออก'))
                return;
            this.is_stock = window.confirm('ต้องการบันทึกประเภทและน้ำหนัก ไปยังสต็อกทองเก่าหรือไม่?')
            this.setState({
                loader: true
            })
            this.runSubmit();
        } else {
            alert('กรุณาเลือกรายการขายฝากเพื่อคัดออก')
        }
    }

    runSubmit() {
        let posiotn = 0;
        let items2 = this.state.items2
        let next = () => {
            if (posiotn == items2.length - 1) {
                this.setState({
                    items: [],
                    items2: [],
                    loader: false
                })
                alert('คัดออกสำเร็จ')
            } else {
                posiotn++;
                submit();
            }
        }
        let submit = () => {
            if (this.is_stock) {
                const url = Settings.baseUrl + "/lease/" + items2[posiotn].id + "/stock/?action=in"
                Utility.getAuth(url, () => {
                    next();
                })
            } else {
                next();
            }
        }

        this.setState({
            net_items1: '',
            weight1: '',
            n1: '',
            net_items2: '',
            weight2: '',
            n2: '',
        })

        submit();
    }

    setValue2(d, leases, categories) {

        let items = [];
        let object = {};
        let weight = 0
        let total = 0;

        let lease = {};

        for (let i in d) {
            const temp_lease = leases.find(k => k.id === d[i].lease)
            const category = categories.find(k => k.id === d[i].category)
            d[i]['lease'] = temp_lease
            d[i]['category'] = category

            if (lease['id_' + d[i].lease.id] == null)
                lease['id_' + d[i].lease.id] = {
                    weight: 0
                }
            lease['id_' + d[i].lease.id].weight += parseFloat(d[i].weight)
        }

        for (let i in d) {

            if (object['id_' + d[i].category.id] == null)
                object['id_' + d[i].category.id] = {
                    weight: 0,
                    average: 0
                }
            weight += parseFloat(d[i].weight)
            object['id_' + d[i].category.id].weight += parseFloat(d[i].weight)

            let ww = parseFloat(lease['id_' + d[i].lease.id].weight) / parseFloat(d[i].category.weight)
            let bb = (d[i].lease.amount / ww)

            object['id_' + d[i].category.id].average += (d[i].lease.amount) / (parseFloat(d[i].weight) / parseFloat(d[i].category.weight))
            object['id_' + d[i].category.id].category_name = d[i].category.name
        }

        for (let i in object) {
            object[i].weight = Utility.weightFormat(object[i].weight)
            object[i].average = Utility.priceFormat(object[i].average)
            items.push(object[i])
        }
        return items
    }

    setFieldValue(item, branchs, customers) {

        for (let i = 0; i < item.length; i++) {
            const customer = customers.find(k => k.id === item[i].customer)
            const branch = branchs.find(k => k.id === item[i].branch)

            item[i]['branch'] = branch
            item[i]['customer'] = customer

            item[i].branch_name = item[i].branch.name
            item[i].customer_name = item[i].customer.name
            item[i].amount_display = Utility.priceFormat(item[i].amount)
            item[i].status_title = Settings.status_lease[item[i].status]
            let amount = parseInt(item[i].amount)
            let interest = parseInt(item[i].interest)
            item[i].interest_m = Utility.priceFormat(amount * (interest / 100))
            item[i].enabled_title = item[i].is_enabled == 0 ? 'ยกเลิกบิลแล้ว' : ''
            item[i].start_date_display = Utility.formatDate(item[i].start_date)
            item[i].end_date_display = Utility.formatDate(item[i].end_date)
            item[i].close_date_display = item[i].status == 3 ? Utility.formatDate(item[i].close_date) : ''
            //this.branches
        }
        return item;
    }

    calculet1(lease_in) {
        if (lease_in == '') {
            this.setState({
                net_items1: [],
                weight1: 0,
                n1: 0
            })
            return;
        }
        this.setState({
            loader: true
        })
        Utility.post(Settings.baseUrl + '/lease/calculate/', { 'lease_in': lease_in }, (status, data) => {
            let weight = 0;
            let count = 0;
            let item = []
            for (let i in data.summary) {
                weight += parseFloat(data.summary[i].total_weight);
                count += parseFloat(data.summary[i].count);
                item.push({
                    average: Utility.priceFormat(data.summary[i].total_amount / data.summary[i].total_weight * data.summary[i].category__weight),
                    category_name: data.summary[i].category__name,
                    weight: Utility.weightFormat(data.summary[i].total_weight),
                })
            }
            this.setState({
                net_items1: item,
                weight1: Utility.weightFormat(weight),
                n1: Utility.numberFormat(count),
                loader: false
            })
        });
    }

    calculet2(lease_in) {

        if (lease_in == '') {
            this.setState({
                net_items2: [],
                weight2: 0,
                n2: 0
            })
            return;
        }
        this.setState({
            loader: true
        })
        Utility.post(Settings.baseUrl + '/lease/calculate/', { 'lease_in': lease_in }, (status, data) => {
            let weight = 0;
            let count = 0;
            let item = []
            for (let i in data.summary) {
                weight += parseFloat(data.summary[i].total_weight);
                count += parseFloat(data.summary[i].count);
                item.push({
                    average: Utility.priceFormat(data.summary[i].total_amount / data.summary[i].total_weight * data.summary[i].category__weight),
                    category_name: data.summary[i].category__name,
                    weight: Utility.weightFormat(data.summary[i].total_weight),
                })
            }
            this.setState({
                net_items2: item,
                weight2: Utility.weightFormat(weight),
                n2: Utility.numberFormat(count),
                loader: false
            })
        });
    }

    render() {
        const { loader } = this.state
        const items = this.state.items
        const items2 = this.state.items2
        const net_items1 = this.state.net_items1
        const net_items2 = this.state.net_items2
        return (
            <div>
                <Dimmer active={loader} inverted>
                    <Loader inverted>Loading</Loader>
                </Dimmer>
                <Form>
                    <Form.Group>
                        <Form.Field width={6}>
                            <Header floated='left' as='h2'>คัดออก</Header>
                        </Form.Field>
                        <Form.Field width={5}></Form.Field>
                        <Form.Field width={5}>
                            <label>QR Code สินค้าขายฝาก / เลขที่ขายฝาก </label>
                            <Input
                                floated='right'
                                className='text-right'
                                placeholder='QR Code สินค้าขายฝาก / เลขที่ขายฝาก'
                                autoFocus
                                ref={(c) => {
                                    this.qrcodee = c
                                }}
                                onKeyPress={(e) => {
                                    if (e.key == 'Enter') {
                                        this.submitQRcode()
                                    }
                                }}
                                onChange={(e) => this.setState({ code: e.target.value })}
                                value={this.state.code}
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>
                <Grid divided='vertically'>
                    <Grid.Row >
                        <Grid.Column width={8}>
                            <Search
                                branches={this.state.branches}
                                onSubmit={this.handlerSerach} />
                            <br />
                            <div id='width_1'>
                                <Table
                                    allowCellsRecycling={true}
                                    rowsCount={items.length}
                                    rowHeight={50}
                                    headerHeight={35}
                                    width={this.state.table_width}
                                    height={200}>
                                    <Column
                                        fixed
                                        header={<Cell></Cell>}
                                        cell={
                                            <ItemsCellIcon data={items} onClickItem={this.handlerAddItem} icon='angle right' />
                                        }
                                        width={70}
                                    />
                                    <Column

                                        header={<Cell>เลขที่ใบขายฝาก</Cell>}
                                        cell={
                                            <ItemsCell position='top left' width={150} id='LeaseNum' data={items} field="number" />
                                        }
                                        width={150}
                                    />
                                    <Column
                                        header={<Cell>ชื่อลูกค้า</Cell>}
                                        cell={
                                            <ItemsCell position='top left' width={150} data={items} field="customer_name" />
                                        }
                                        width={150}
                                    />
                                    <Column
                                        header={<Cell className="text-right" >จน. เงินต้น</Cell>}
                                        cell={
                                            <ItemsCell position='top right' width={150} data={items} field="amount_display" textAlign="text-right" />
                                        }
                                        width={150}
                                    />
                                    <Column
                                        header={<Cell className="text-right">รวม ดบ.รับ</Cell>}
                                        cell={
                                            <ItemsCell position='top right' width={150} data={items} field="total_interest" textAlign="text-right" type='price' />
                                        }
                                        width={150}
                                    />
                                    <Column
                                        header={<Cell className="text-right">อัตรา ดบ.</Cell>}
                                        cell={
                                            <ItemsCell position='top right' width={150} data={items} field="interest" textAlign="text-right" type='price' />
                                        }
                                        width={150}
                                    />
                                    <Column
                                        header={<Cell className="text-right">จน. เดือน</Cell>}
                                        cell={
                                            <ItemsCell position='top right' width={150} data={items} field="month" textAlign="text-right" type='number' />
                                        }
                                        width={150}
                                    />

                                    <Column
                                        header={<Cell className="text-right">ดบ./เดือน</Cell>}
                                        cell={
                                            <ItemsCell position='top right' width={150} data={items} field="interest_m" textAlign="text-right" />
                                        }
                                        width={150}
                                    />

                                    <Column
                                        header={<Cell>วันที่นำเข้า</Cell>}
                                        cell={
                                            <ItemsCell position='top left' width={150} data={items} field="start_date_display" />
                                        }
                                        width={150}
                                    />
                                    <Column
                                        header={<Cell>วันครบกำหนด</Cell>}
                                        cell={
                                            <ItemsCell position='top left' width={150} data={items} field="end_date_display" />
                                        }
                                        width={150}
                                    />
                                    {/* <Column
                                        header={<Cell>วันที่ไถ่คืน/วันที่คัดออก</Cell>}
                                        cell={
                                            <ItemsCell position='top left' width={150} data={items} field="close_date_display" />
                                        }
                                        width={150}
                                    /> */}
                                    <Column
                                        header={<Cell>สถานะ</Cell>}
                                        cell={
                                            <ItemsCell position='top left' width={150} data={items} field="status_title" />
                                        }
                                        width={150}
                                    />

                                    {/* <Column
                                        header={<Cell>สถานะยกเลิก</Cell>}
                                        cell={
                                            <ItemsCell position='top left' width={150} data={items} field="enabled_title" />
                                        }
                                        width={150}
                                    /> */}
                                    <Column
                                        header={<Cell>สาขา</Cell>}
                                        cell={
                                            <ItemsCell position='top left' width={150} data={items} field="branch_name" />
                                        }
                                        width={150}
                                    />


                                </Table>

                                <br />
                                <Table
                                    rowsCount={net_items1.length}
                                    rowHeight={30}
                                    headerHeight={35}
                                    width={this.state.table_width}
                                    height={200}>
                                    <Column
                                        header={<Cell>%ทอง</Cell>}
                                        cell={
                                            <ItemsCell position='top left' width={100} data={net_items1} field="category_name" />
                                        }
                                        width={100}
                                    />
                                    <Column
                                        header={<Cell className="text-right">น้ำหนักทอง(กรัม)</Cell>}
                                        cell={
                                            <ItemsCell position='top right' width={190} data={net_items1} field="weight" textAlign="text-right" />
                                        }
                                        width={190}
                                    />
                                    <Column
                                        header={<Cell className="text-right">ราคาทองเฉลี่ยบาทละ</Cell>}
                                        cell={
                                            <ItemsCell position='top right' width={160} data={net_items1} field="average" textAlign="text-right" />
                                        }
                                        width={160}
                                    />
                                </Table>
                                <br />
                                <Form>
                                    <Form.Group widths='equal'>
                                        <Form.Input width={5} label='จำนวนรายการ' placeholder='จำนวนรายการ' value={this.state.n1 == undefined ? '' : Utility.numberFormat(this.state.n1)} className='text-right' />
                                        <Form.Input width={6} label='น้ำหนัก' placeholder='น้ำหนัก' value={this.state.weight1} className='text-right' />
                                        <Form.Field width={3}>
                                            <br />
                                            <Button type='button' onClick={this.handlerCalucate}>คำนวณ</Button>
                                        </Form.Field>
                                    </Form.Group>
                                </Form>
                            </div>
                        </Grid.Column>
                        <Grid.Column width={1}>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <Button color='grey' circular icon='angle double right' onClick={this.handlerAddAll}></Button>
                            <br />
                            <br />
                            <Button color='grey' circular icon='angle double left' onClick={this.handlerDeleteAll}></Button>
                        </Grid.Column>
                        <Grid.Column width={7}>
                            <div id='width_2'>
                                <Table
                                    rowsCount={items2.length}
                                    rowHeight={50}
                                    headerHeight={35}
                                    width={this.state.table_width2}
                                    height={400} >
                                    <Column
                                        fixed
                                        header={<Cell></Cell>}
                                        cell={
                                            <ItemsCellIcon data={items} onClickItem={this.handlerDeleteItem} icon='angle left' />
                                        }
                                        width={70}
                                    />
                                    <Column
                                        header={<Cell>เลขที่ใบขายฝาก</Cell>}
                                        cell={
                                            <ItemsCell position='top left' width={150} data={items2} field="number" />
                                        }
                                        width={150}
                                    />
                                    <Column
                                        header={<Cell>ชื่อลูกค้า</Cell>}
                                        cell={
                                            <ItemsCell position='top left' width={150} data={items2} field="customer_name" />
                                        }
                                        width={150}
                                    />
                                    <Column
                                        header={<Cell className="text-right">จน. เงินต้น</Cell>}
                                        cell={
                                            <ItemsCell position='top right' width={150} data={items2} field="amount_display" textAlign="text-right" />
                                        }
                                        width={150}
                                    />
                                    <Column
                                        header={<Cell className="text-right">รวม ดบ.รับ</Cell>}
                                        cell={
                                            <ItemsCell position='top right' width={150} data={items2} field="total_interest" textAlign="text-right" type='price' />
                                        }
                                        width={150}
                                    />
                                    <Column
                                        header={<Cell className="text-right">อัตรา ดบ.</Cell>}
                                        cell={
                                            <ItemsCell position='top right' width={150} data={items2} field="interest" textAlign="text-right" type='price' />
                                        }
                                        width={150}
                                    />
                                    <Column
                                        header={<Cell className="text-right">จน. เดือน</Cell>}
                                        cell={
                                            <ItemsCell position='top right' width={150} data={items2} field="month" textAlign="text-right" type='number' />
                                        }
                                        width={150}
                                    />

                                    <Column
                                        header={<Cell className="text-right">ดบ./เดือน</Cell>}
                                        cell={
                                            <ItemsCell position='top right' width={150} data={items2} field="interest_m" textAlign="text-right" />
                                        }
                                        width={150}
                                    />

                                    <Column
                                        header={<Cell>วันที่นำเข้า</Cell>}
                                        cell={
                                            <ItemsCell position='top left' width={150} data={items2} field="start_date_display" />
                                        }
                                        width={150}
                                    />
                                    <Column
                                        header={<Cell>วันครบกำหนด</Cell>}
                                        cell={
                                            <ItemsCell position='top left' width={150} data={items2} field="end_date_display" />
                                        }
                                        width={150}
                                    />
                                    {/* <Column
                                        header={<Cell>วันที่ไถ่คืน/วันที่คัดออก</Cell>}
                                        cell={
                                            <ItemsCell position='top left' width={150} data={items2} field="close_date_display" />
                                        }
                                        width={150}
                                    /> */}
                                    <Column
                                        header={<Cell>สถานะ</Cell>}
                                        cell={
                                            <ItemsCell position='top left' width={150} data={items2} field="status_title" />
                                        }
                                        width={150}
                                    />

                                    {/* <Column
                                        header={<Cell>สถานะยกเลิก</Cell>}
                                        cell={
                                            <ItemsCell position='top left' width={150} data={items2} field="enabled_title" />
                                        }
                                        width={150}
                                    /> */}
                                    <Column
                                        header={<Cell>สาขา</Cell>}
                                        cell={
                                            <ItemsCell position='top left' width={150} data={items2} field="branch_name" />
                                        }
                                        width={150}
                                    />
                                </Table>
                                <br />
                                <Table
                                    rowsCount={net_items2.length}
                                    rowHeight={30}
                                    headerHeight={35}
                                    width={this.state.table_width2}
                                    height={200}>
                                    <Column
                                        header={<Cell>%ทอง</Cell>}
                                        cell={
                                            <ItemsCell position='top left' width={100} data={net_items2} field="category_name" />
                                        }
                                        width={100}
                                    />
                                    <Column
                                        header={<Cell className="text-right">น้ำหนักทอง(กรัม)</Cell>}
                                        cell={
                                            <ItemsCell position='top right' width={190} data={net_items2} field="weight" textAlign="text-right" />
                                        }
                                        width={190}
                                    />
                                    <Column
                                        header={<Cell className="text-right">ราคาทองเฉลี่ยบาทละ</Cell>}
                                        cell={
                                            <ItemsCell position='top right' width={160} data={net_items2} field="average" textAlign="text-right" />
                                        }
                                        width={160}
                                    />
                                </Table>
                                <br />
                                <Form>
                                    <Form.Group widths='equal'>
                                        <Form.Input width={5} label='จำนวนรายการ' placeholder='จำนวนรายการ' value={this.state.n2 == undefined ? '' : Utility.numberFormat(this.state.n2)} className='text-right' />
                                        <Form.Input width={6} label='น้ำหนัก' placeholder='น้ำหนัก' value={this.state.weight2} className='text-right' />
                                        <Form.Field width={3}>
                                            <br />
                                            <Button onClick={this.handlerCalucate} >คำนวณ</Button>
                                        </Form.Field>

                                    </Form.Group>
                                </Form>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                {/* <right>
                    <Button id='btnLeaseOutItem' onClick={this.handlerOut} loader={this.state.loader} primary>คัดออก</Button>
                </right> */}
                {this.state.lease_out_modal ?
                    <LeaseOutModal
                        onClose={() => {
                            this.setState({
                                lease_out_modal: false,
                            });
                        }}
                        lease={this.state.lease}
                        lease_product={this.state.lease_product}
                    />
                    : null}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth
    })
}
export default connect(mapStateToProps)(LeaseOut)