/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Header,
    Button,
    Modal,
    Dimmer,
    Loader,
    Table
} from 'semantic-ui-react';
import 'react-dates/lib/css/_datepicker.css';
import Utility from '../../Utility';
import Settings from '../../Settings';

import { connect } from 'react-redux'

class SafeStatementModal extends Component {
    constructor(props) {
        super(props);
        this.setting = props.setting
        this.state = {
            branch: this.props.auth.branch.id,
            loading: true,
            safe_bill: [],
            safe_lease: [],
            safe_all: [],
            balance_bill: 0,
            balance_lease: 0,
            balance_all: 0,
            date_before: null,
        }
    }
    componentDidMount() {
        let date_before = new Date()
        date_before.setDate(date_before.getDate() - 1)
        date_before = Utility.formatDate2(date_before)

        this.setState({
            loading: true,
            safe_bill: [],
            safe_lease: [],
            safe_all: [],
            balance_bill: 0,
            balance_lease: 0,
            balance_all: 0,
            date_before,
        })

        const safes = Utility.getFetch(Settings.baseReportUrl + '/safe-logs/?branch=' + this.props.auth.branch.id);
        Promise.all([safes]).then((values) => {
            const data_branch = (values[0].data || []).find(item => item.id === this.props.auth.branch.id)
            if (data_branch) {
                this.setState({
                    safe_bill: data_branch.safe_bill,
                    safe_lease: data_branch.safe_lease,
                    safe_all: data_branch.safe_all,
                    balance_bill: data_branch.safe_bill.reduce((sum, item) => sum + (item.safe.last_balance), 0),
                    balance_lease: data_branch.safe_lease.reduce((sum, item) => sum + (item.safe.last_balance), 0),
                    balance_all: data_branch.safe_all.reduce((sum, item) => sum + (item.safe.last_balance), 0),
                })
            }
            this.setState({
                loading: false,
            });
        });
    }

    render() {
        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace',
        };
        const textRight = {
            'text-align': 'right',
        }

        const textLeft = {
            'text-align': 'left',
        }
        const textCenter = {
            'text-align': 'center',
        }

        return (

            <Modal id='ModalLedgerBalance' size="fullscreen" open={true} >
                <Button id='btnClose' circular icon='close' basic floated='right' name='' onClick={(this.props.onClose)} />

                <Modal.Header>
                    <Header as='h4'>
                        <Header.Content>
                            ประวัติเซฟ ณ {Utility.formatDateTime(new Date())} น.
                        </Header.Content>
                    </Header>
                </Modal.Header>
                <Modal.Content className='scrolling2 '>
                    <div >
                        <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <Table basic id='table-to-xls' style={divStyle}>
                            {
                                this.state.safe_bill.map((s, i) => <Table.Body key={i}>
                                    <Table.Row><Table.Cell colSpan='7'></Table.Cell></Table.Row>
                                    <Table.Row>
                                        <Table.Cell colSpan='7' style={textCenter}>เซฟ : ซื้อขาย {this.state.safe_bill.length > 1 && s.safe.name}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.HeaderCell style={textCenter}>วันที่/เวลา</Table.HeaderCell>
                                        <Table.HeaderCell style={textLeft}>ประเภทเงิน</Table.HeaderCell>
                                        <Table.HeaderCell style={textCenter}>อ้างอิง</Table.HeaderCell>
                                        <Table.HeaderCell style={textRight}>ฝาก</Table.HeaderCell>
                                        <Table.HeaderCell style={textRight}>ถอน</Table.HeaderCell>
                                        <Table.HeaderCell style={textRight}>คงเหลือ</Table.HeaderCell>
                                        <Table.HeaderCell style={textCenter}>ผู้ทำรายการ</Table.HeaderCell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell ><div className='text-center'>{Utility.formatDate(this.state.date_before)} 23:59</div></Table.Cell>
                                        <Table.Cell ><div className='text-left'>ยกมา</div></Table.Cell>
                                        <Table.Cell ><div className='text-center'>-</div></Table.Cell>
                                        <Table.Cell ><div className='text-right'>-</div></Table.Cell>
                                        <Table.Cell ><div className='text-right'>-</div></Table.Cell>
                                        <Table.Cell ><div className='text-right'>{Utility.priceFormat(s.safe.initial_balance)}</div></Table.Cell>
                                        <Table.Cell ><div className='text-center'>-</div></Table.Cell>
                                    </Table.Row>
                                    {
                                        s.transaction.map((record, j) => <Table.Row key={j}>
                                            <Table.Cell ><div className='text-center'>{Utility.formatDate(record.record_date)} {Utility.formatTime(record.record_date)}</div></Table.Cell>
                                            <Table.Cell ><div className='text-left'>{record.action_description}</div></Table.Cell>
                                            <Table.Cell ><div className='text-center'>{record.ref}</div></Table.Cell>
                                            <Table.Cell ><div className='text-right'>{record.kind === 'IN' ? Utility.priceFormat(record.amount) : '-'}</div></Table.Cell>
                                            <Table.Cell ><div className='text-right'>{record.kind === 'EX' ? Utility.priceFormat(record.amount) : '-'}</div></Table.Cell>
                                            <Table.Cell ><div className='text-right'>{Utility.priceFormat(record.after)}</div></Table.Cell>
                                            <Table.Cell ><div className='text-center'>{record.user}</div></Table.Cell>
                                        </Table.Row>)
                                    }
                                </Table.Body>)
                            }
                            {
                                this.state.safe_lease.map((s, i) => <Table.Body key={i}>
                                    <Table.Row><Table.Cell colSpan='7'></Table.Cell></Table.Row>
                                    <Table.Row>
                                        <Table.Cell colSpan='7' style={textCenter}>เซฟ : ขายฝาก {this.state.safe_lease.length > 1 && s.safe.name}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.HeaderCell style={textCenter}>วันที่/เวลา</Table.HeaderCell>
                                        <Table.HeaderCell style={textLeft}>ประเภทเงิน</Table.HeaderCell>
                                        <Table.HeaderCell style={textCenter}>อ้างอิง</Table.HeaderCell>
                                        <Table.HeaderCell style={textRight}>ฝาก</Table.HeaderCell>
                                        <Table.HeaderCell style={textRight}>ถอน</Table.HeaderCell>
                                        <Table.HeaderCell style={textRight}>คงเหลือ</Table.HeaderCell>
                                        <Table.HeaderCell style={textCenter}>ผู้ทำรายการ</Table.HeaderCell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell ><div className='text-center'>{Utility.formatDate(this.state.date_before)} 23:59</div></Table.Cell>
                                        <Table.Cell ><div className='text-left'>ยกมา</div></Table.Cell>
                                        <Table.Cell ><div className='text-center'>-</div></Table.Cell>
                                        <Table.Cell ><div className='text-right'>-</div></Table.Cell>
                                        <Table.Cell ><div className='text-right'>-</div></Table.Cell>
                                        <Table.Cell ><div className='text-right'>{Utility.priceFormat(s.safe.initial_balance)}</div></Table.Cell>
                                        <Table.Cell ><div className='text-center'>-</div></Table.Cell>
                                    </Table.Row>
                                    {
                                        s.transaction.map((record, j) => <Table.Row key={j}>
                                            <Table.Cell ><div className='text-center'>{Utility.formatDate(record.record_date)} {Utility.formatTime(record.record_date)}</div></Table.Cell>
                                            <Table.Cell ><div className='text-left'>{record.action_description}</div></Table.Cell>
                                            <Table.Cell ><div className='text-center'>{record.ref}</div></Table.Cell>
                                            <Table.Cell ><div className='text-right'>{record.kind === 'IN' ? Utility.priceFormat(record.amount) : '-'}</div></Table.Cell>
                                            <Table.Cell ><div className='text-right'>{record.kind === 'EX' ? Utility.priceFormat(record.amount) : '-'}</div></Table.Cell>
                                            <Table.Cell ><div className='text-right'>{Utility.priceFormat(record.after)}</div></Table.Cell>
                                            <Table.Cell ><div className='text-center'>{record.user}</div></Table.Cell>
                                        </Table.Row>)
                                    }
                                </Table.Body>)
                            }
                            {
                                this.state.safe_all.map((s, i) => <Table.Body key={i}>
                                    <Table.Row><Table.Cell colSpan='7'></Table.Cell></Table.Row>
                                    <Table.Row>
                                        <Table.Cell colSpan='7' style={textCenter}>เซฟ {this.state.safe_all.length > 1 && `(${s.safe.name})`}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.HeaderCell style={textCenter}>วันที่/เวลา</Table.HeaderCell>
                                        <Table.HeaderCell style={textLeft}>ประเภทเงิน</Table.HeaderCell>
                                        <Table.HeaderCell style={textCenter}>อ้างอิง</Table.HeaderCell>
                                        <Table.HeaderCell style={textRight}>ฝาก</Table.HeaderCell>
                                        <Table.HeaderCell style={textRight}>ถอน</Table.HeaderCell>
                                        <Table.HeaderCell style={textRight}>คงเหลือ</Table.HeaderCell>
                                        <Table.HeaderCell style={textCenter}>ผู้ทำรายการ</Table.HeaderCell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell ><div className='text-center'>{Utility.formatDate(this.state.date_before)} 23:59</div></Table.Cell>
                                        <Table.Cell ><div className='text-left'>ยกมา</div></Table.Cell>
                                        <Table.Cell ><div className='text-center'>-</div></Table.Cell>
                                        <Table.Cell ><div className='text-right'>-</div></Table.Cell>
                                        <Table.Cell ><div className='text-right'>-</div></Table.Cell>
                                        <Table.Cell ><div className='text-right'>{Utility.priceFormat(s.safe.initial_balance)}</div></Table.Cell>
                                        <Table.Cell ><div className='text-center'>-</div></Table.Cell>
                                    </Table.Row>
                                    {
                                        s.transaction.map((record, j) => <Table.Row key={j}>
                                            <Table.Cell ><div className='text-center'>{Utility.formatDate(record.record_date)} {Utility.formatTime(record.record_date)}</div></Table.Cell>
                                            <Table.Cell ><div className='text-left'>{record.action_description}</div></Table.Cell>
                                            <Table.Cell ><div className='text-center'>{record.ref}</div></Table.Cell>
                                            <Table.Cell ><div className='text-right'>{record.kind === 'IN' ? Utility.priceFormat(record.amount) : '-'}</div></Table.Cell>
                                            <Table.Cell ><div className='text-right'>{record.kind === 'EX' ? Utility.priceFormat(record.amount) : '-'}</div></Table.Cell>
                                            <Table.Cell ><div className='text-right'>{Utility.priceFormat(record.after)}</div></Table.Cell>
                                            <Table.Cell ><div className='text-center'>{record.user}</div></Table.Cell>
                                        </Table.Row>)
                                    }
                                </Table.Body>)
                            }
                            <Table.Body>
                                <Table.Row><Table.Cell colSpan='7'></Table.Cell></Table.Row>
                                <Table.Row>
                                    <Table.Cell colSpan='5'><div className='text-right'>เงินสดในเซฟทั้งหมด</div></Table.Cell>
                                    <Table.Cell ><div className='text-right'>{Utility.priceFormat(this.state.balance_all)}</div></Table.Cell>
                                    <Table.Cell></Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </div>

                </Modal.Content>
            </Modal >
        );
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
    })
}

export default connect(mapStateToProps)(SafeStatementModal)
