/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Loader, Dimmer, Dropdown, Modal, Table
} from 'semantic-ui-react';
/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'

class LeaseIncome extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: {
                start_date: moment(),
                end_date: moment(),
                branch: [],
                payment: [],
            },
            branch_option: [],
            items: []
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {
        let option = this.props.branches.map((b) => {
            return {
                text: b.name,
                value: b.id,
            }
        })
        this.setState({
            branch_option: option
        })
        var url_string = window.location.toString();
        var url = new URL(url_string);
        var temp_start = url.searchParams.get("start_date");
        var temp_end = url.searchParams.get("end_date");
        if (temp_start !== null && temp_end !== null) {
            this.setState({
                search: {
                    start_date: moment(temp_start, 'DD/MM/YYYY'),
                    end_date: moment(temp_end, 'DD/MM/YYYY')
                }
            })
        }
        let search = this.state.search
        if (localStorage.getItem("payment") != null)
            search['payment'] = JSON.parse(localStorage.getItem("payment"))
        this.setState({
            search: search
        })
    }

    resetForm(e) {

        this.setState({
            search: {
                branch: this.props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
                branch: [],
                payment: [],
            },
        })
        localStorage.setItem('payment', '[]')
    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.start_date = Utility.formatDate2(search.start_date)
        search.end_date = Utility.formatDate2(search.end_date)
        if (search.payment && search.payment.length === 0) {
            delete search["payment"]
        }
        let url = `${Settings.baseReportUrl}/report/lease-income/?export=1&q=${Buffer.from(JSON.stringify(search)).toString('base64')}`
        window.open(url, '_blank');
    }

    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        if (v.name == 'payment')
            localStorage.setItem('payment', JSON.stringify(search[v.name]))
        this.setState({
            search: search
        })
    }

    render() {
        let title = 'รายงานจำแนกรายรับขายฝาก';

        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>{title}</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >

                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field>
                                <label>สาขา <small style={{ 'color': 'red' }}> *เลือกได้มากกว่า 1</small></label>
                                <Dropdown
                                    placeholder='- ทั้งหมด -'
                                    multiple
                                    search
                                    selection
                                    options={this.state.branch_option}
                                    value={this.state.search.branch}
                                    name='branch'
                                    onChange={(e, { value }) => {
                                        this.setState({
                                            search: {
                                                ...this.state.search,
                                                branch: value,
                                            }
                                        })
                                    }}
                                />
                            </Form.Field>
                            <Form.Field >
                                <label>จากวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.start_date}
                                    selected={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        if (this.state.search.end_date < date) {
                                            this.handlerInput(null, {
                                                name: 'end_date',
                                                value: date
                                            });
                                        }
                                        this.handlerInput(null, {
                                            name: 'start_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Form.Field >
                                <label>ถึงวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.end_date}
                                    selected={this.state.search.end_date}
                                    minDate={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'end_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>ประเภทการชำระ<small style={{ 'color': 'red' }}> *เลือกได้มากกว่า 1</small></label>
                                <Dropdown multiple search selection
                                    placeholder='- ทั้งหมด -'
                                    options={[
                                        { key: 'CS', value: 'CS', text: 'เงินสด', },
                                        { key: 'QR', value: 'QR', text: 'เงินสด + โอน', },
                                        { key: 'CSQ', value: 'CSQ', text: 'โอน', },
                                        { key: 'TF', value: 'TF', text: 'ออนไลน์​', },
                                    ]}
                                    value={this.state.search.payment} name='payment' onChange={this.handlerInput} />
                            </Form.Field>
                            <Button>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}

export default connect(
    mapStateToProps,
)(LeaseIncome)
