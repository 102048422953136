/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import MsgInput from '../../Error/MsgInput'
import {
  Form,
  Message,
  Button, Modal, Input, Dimmer, Loader, Table, Header, Label, FormGroup, FormField, Checkbox, Popup, Icon
} from 'semantic-ui-react';
import SelectOption from '../../SelectOption'
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import moment from 'moment';
import { hotkeys } from 'react-keyboard-shortcuts'
import { connect } from 'react-redux'

class SellForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      type_form: 'all',
      product_code: '',
      product_id: '',
      product_type: '',
      product_type_id: '',
      product_name: '',
      amount: '',
      discount: '',
      type_sale: '',
      sell_weight_real_g: '',
      product_price: '',
      sell_price: '',
      // products: [],
      buy_categories_id: '',
      buy_product_id: '',
      buy_weight_real_g: '',
      buy_weight_real_b: '',
      buy_price: '',
      exchange: 0,
      msg_error: '',
      is_loading: false,
      record_date: moment(),
      category_id: '',
      category_name: '',
      category_code: '',
      product_options: this.props.formValue.product_options,
      max: 0,
      gold_bar_sell: '',
      gold_ornaments_buy: '',
      QRCODE: '',
      QRCODE2: '',
      fee: [],
      fee_price: 0,
      fee_min: 0,
      fee_max: 0,
      sell_price_temp: 0,
      type: 1,
      pd: [],
      is_submit_qrcode: false,
      gold_rec1: 0.92,
      gold_rec2: 0.945,
      round: 100,
      category: [],
      is_price_aag: true,
      is_aagold_disabled: true,
      product_cost: 0,
      stocks: [],
      staff_product_stock: 0,
      max_weight: 0,
    }
    this.handlerReset = this.handlerReset.bind(this);
    this.handlerSubmit = this.handlerSubmit.bind(this);
    this.handlerBuyWeightG = this.handlerBuyWeightG.bind(this);
    this.handlerBuyWeightB = this.handlerBuyWeightB.bind(this);
    this.handlerBuyPrice = this.handlerBuyPrice.bind(this);
    this.handlerExchange = this.handlerExchange.bind(this);
    this.handlerSellPrice = this.handlerSellPrice.bind(this)
    this.handlerChangeWeightReal = this.handlerChangeWeightReal.bind(this)
    this.handlerQRCODE = this.handlerQRCODE.bind(this)
    this.submitQRcode = this.submitQRcode.bind(this)
    this.handlerfee = this.handlerfee.bind(this)
    this.handleSeting = this.handleSeting.bind(this)
    this.handleBlockChange = this.handleBlockChange.bind(this)

  }

  handleResultSelect(e, value) {

    this.weightRealInput.focus()
    this.setState({
      product_name: value.result.title
    })
  }

  gold_price_rec(num, div) {
    const g = Math.ceil((this.state.gold_bar_buy * (num / div) % this.state.round))
    let value = g >= 50 ? Math.ceil((this.state.gold_bar_buy * (num / div) / this.state.round)) * this.state.round : Math.floor((this.state.gold_bar_buy * (num / div) / this.state.round)) * this.state.round
    return value
  }

  gold_price_bar(bath) {
    const cate = Utility.getObject(this.props.formValue.categories, 3);
    let total_weight = Math.ceil(cate['weight'] * bath * 100) / 100
    const block_cost = this.handleBlockChange(total_weight)
    let value = (this.state.gold_bar_buy * bath) - block_cost
    return value
  }

  componentDidMount() {
    if (this.props.kind == 'BU') {
      let staff_product_stock_list = []
      for (let i = 0; i < this.props.billItems.length; i++) {
        staff_product_stock_list.push(Number(this.props.billItems[i].staff_product_stock))
      }
      let stocks = [];
      for (let i = 0; i < this.props.stock.length; i++) {
        let items = this.props.stock[i]
        if (!staff_product_stock_list.includes(items.id)) {
          stocks.push({
            value: items.id,
            key: items.id,
            text: items.product_name,
            weight: items.weight,
            category: items.category,
          });
        }
      }
      this.setState({ stocks: stocks })
    }
    this.setState(this.props.gold_price)

    // let url = Settings.baseUrl + "/product_name/"
    // Utility.get(url, (s, d) => {
    //   this.setState({
    //     products: d
    //   })
    // })
    Utility.get(Settings.baseUrl + "/categories/", (s, d) => {
      this.setState({
        category: d
      })
    })
    if (this.props.kind == 'SE' || this.props.kind == 'XC') {
      if (this.inputCategory)
        this.inputCategory.focus()
    } else {
      if (this.categoryBuyInput)
        this.categoryBuyInput.focus()
    }

    // this.loadProduct()
    let system_setting = JSON.parse(localStorage.getItem('system_setting'))
    let fee = system_setting.filter(setting => setting.setting == 'FEE')
    let block = system_setting.filter(setting => setting.setting == 'BLOCK')
    let qouta = system_setting.filter(setting => setting.setting == 'QOUTA')
    this.setState({
      fee: JSON.parse(fee[0].value),
      block: JSON.parse(block[0].value),
      qouta: JSON.parse(qouta[0].value),
      can_sale: Number(JSON.parse(qouta[0].value)) - Number(this.props.used) - Number(this.props.used_this),
    })

  }

  handlerSellPrice(e, v) {
    const gold_bar_sell = Utility.removeCommas(this.state.gold_bar_sell)
    v.value = v.value.toString().replace(/,/g, '')
    if (isNaN(Number(v.value))) {
    } else {
      if (v.value.length === 0) {
        v.value = 0
      }
      const sell_price = Utility.parseFloat(v.value, this.state.sell_price)

      this.setState({
        sell_price
      })
      // const p = Utility.getObject(this.products, this.state.product_id);
      // this.setState({product_price:sell_price})
      this.setState({ fee_price: sell_price - this.state.product_price })
      // if(this.state.type === 1){
      //   this.setState({fee_price:0})
      // }
    }

  }

  setPriceSell(product, weightReal, amount, fee) {
    let productPrice = 0
    let sell_price = 0
    const gold_bar_sell = Utility.removeCommas(this.state.gold_bar_sell);

    if (product) {
      let productPrice = 0;
      let cat = Utility.getObject(this.state.category, product.category.id)
      if (product.type_sale == 1) {
        let b = (Number(weightReal) / Number(cat.weight)) * Number(cat.m_sell);
        productPrice = (b * Number(gold_bar_sell));
      } else {
        productPrice = product.price_tag;
      }
      const product_price = Math.round(productPrice)
      this.setState({
        product_price: product_price,
        sell_price: Math.ceil((product_price * amount) + Number(fee)),
        sell_price_temp: Math.ceil(product_price * amount)
      });
      if (this.props.kind == 'XC') {
        this.setState({
          buy_price: product_price,
        })
      }

    }

  }

  handlerChangeWeightReal(e, v) {
    v.value = v.value.toString().replace(/,/g, '')
    if (isNaN(Number(v.value))) {
    } else {
      if (v.value.length === 0) {
        v.value = 0
      }
      const sell_weight_real_g = Utility.parseFloat(v.value, this.state.sell_weight_real_g)
      // const p = Utility.getObject(this.products, this.state.product_id);
      if (this.state.product) {
        this.setState({ sell_weight_real_g: v.value })
        let fee = this.state.fee_price
        this.setPriceSell(this.state.product, sell_weight_real_g, 1, fee)
      }
    }

  }

  handlerReset(e, d) {
    e.preventDefault();
    this.setState({
      product_code: '',
      product_id: '',
      product: null,
      product_type: '',
      amount: '',
      discount: '',
      sell_weight_real_g: '',
      product_price: '',
      sell_price: '',
      product_name: '',
      buy_categories_id: '',
      buy_product_id: '',
      buy_weight_real_g: '',
      buy_weight_real_b: '',
      buy_price: '',
      exchange: 0,
      is_submit_qrcode: false,
    })
  }

  handlerSubmit(e, d) {
    e.preventDefault();
    for (let i = 0; i < this.props.billItems.length; i++) {
      if (this.props.billItems[i].qrcode === this.state.QRCODE.trim()) {
        alert('ได้ทำการเลือกสินค้านี้แล้ว')
        this.setState({
          category_id: '',
          category_name: '',
          product_type_id: '',
          product_type: '',
          product_id: '',
          product: null,
          product_name: '',
          sell_weight_real_g: '',
          sell_price: '',
          product_price: '',
          fee_price: '',
          QRCODE: '',
        })
        return;
      }
      if (this.props.kind == "SE" && (this.props.billItems[i].category_name != this.state.category_name)) {
        alert('ลงรายการได้เฉพาะกลุ่มสินค้าเดียวกันเท่านั้น')
        this.setState({
          category_id: '',
          category_name: '',
          product_type_id: '',
          product_type: '',
          product_id: '',
          product: null,
          product_name: '',
          sell_weight_real_g: '',
          sell_price: '',
          product_price: '',
          fee_price: '',
          QRCODE: '',
        })
        return;
      } else {
        if (this.props.kind == "SE" && this.props.billItems[i].type_sale != this.state.type_sale) {
          alert('ลงรายการได้เฉพาะกลุ่มสินค้าเดียวกันเท่านั้น')
          this.setState({
            category_id: '',
            category_name: '',
            product_type_id: '',
            product_type: '',
            product_id: '',
            product: null,
            product_name: '',
            sell_weight_real_g: '',
            sell_price: '',
            product_price: '',
            fee_price: '',
            QRCODE: '',
          })
          return;
        }
      }
    }
    let msg_error = {}

    if (this.state.max < this.state.amount) {
      alert('สินค้าในสต็อกไม่เพียงพอ');
      return;
    }
    // const p = Utility.getObject(this.products, this.state.product_id);
    if (this.props.kind === 'SE' && (this.state.QRCODE === undefined || this.state.QRCODE === null || this.state.QRCODE === '')) {
      alert('กรุณาเพิ่มสินค้า')
    }

    if (this.props.kind !== 'SE' && (this.state.staff_product_stock == '' || this.state.staff_product_stock == 0)) {
      msg_error['staff_product_stock'] = ['เลือกสินค้า']
    }

    if (this.props.kind !== 'SE' && this.state.buy_weight_real_g == '') {
      msg_error['buy_weight_real_g'] = ['ต้องไม่เป็นค่าว่าง']
    }

    if ((this.props.kind === 'SE' || this.props.kind === 'XC') && (this.state.sell_weight_real_g <= 0 || this.state.sell_weight_real_g == '') && this.state.type === 0) {
      msg_error['sell_weight_real_g'] = ['กรุณากรอก น.น.ชั่ง(ก.)']
    }

    if (this.props.kind !== 'SE' && this.state.buy_weight_real_b == '') {
      msg_error['buy_weight_real_b'] = ['ต้องไม่เป็นค่าว่าง']
    }

    if (this.props.kind !== 'SE' && this.state.product_name == '') {
      msg_error['product_name'] = ['ต้องไม่เป็นค่าว่าง']
    }

    if (this.props.kind !== 'BU' && this.state.product_id == '') {
      msg_error['product_id'] = ['เลือกสินค้า']
    }

    if (this.props.kind !== 'BU' && (this.state.sell_price == '0' || this.state.sell_price == '')) {
      msg_error['sell_price'] = ['ต้องไม่เป็นค่าว่าง']
    }

    if (this.state.type_sale == 2) {
      if (this.props.kind !== 'BU' && (Number(this.state.sell_price) < Number(this.state.product_cost))) {
        msg_error['sell_price'] = ['ต้องไม่น้อยกว่าต้นทุน']
      }
    }

    if ((this.props.auth.role != 'A' && this.props.auth.role != 'M' && this.props.auth.role != 'S') && this.props.kind !== 'BU' && (this.state.sell_price < Math.ceil(this.state.product_price))) {
      msg_error['sell_price'] = ['ต้องไม่น้อยกว่าราคาขายจริง']
    }
    let max_buy = 0
    if (this.state.buy_categories_id == 1) {
      max_buy = parseFloat(this.state.gold_bar_buy) * (parseFloat(this.state.buy_weight_real_b) * 0.9)
    } else if (this.state.buy_categories_id == 3 || this.state.buy_categories_id == 2) {
      max_buy = (parseFloat(this.state.gold_bar_buy) - 200) * parseFloat(this.state.buy_weight_real_b)
    }
    // let min_cost = parseFloat(this.state.sell_price) - max_buy
    // min_cost = min_cost < 0 ? 0 : min_cost
    // if ((this.state.exchange < min_cost || this.state.exchange < 0) && this.props.kind === 'XC') {
    //   msg_error['changecost'] = 'กรุณากรอกเงินเปลี่ยน > ' + min_cost.toFixed(0)
    // }
    if (this.state.fee_price < this.state.fee_min) {
      msg_error['fee'] = ['กรุณากรอกค่ากำเหน็จให้ถูกต้อง']
    }

    if (Object.keys(msg_error).length > 0) {
      this.setState({ msg_error: msg_error })
      return;
    }

    const code_change = (new Date()).getTime()

    let sell = ''
    let buy = ''
    if (this.state.sell_price != '') {
      // const p = Utility.getObject(this.products, this.state.product_id);
      sell = {
        product_code: this.state.product_code,
        product_id: this.state.product_id,
        product_type: this.state.product_type,
        amount: 1,
        discount: this.state.discount,
        sell_weight_real_g: Number(this.state.sell_weight_real_g).toFixed(3),
        product_price: this.state.product_price,
        sell_price: this.state.sell_price,
        gold_price: this.state.gold_bar_sell,
        exchange: this.state.exchange,
        id_change: code_change,
        qrcode: this.state.QRCODE2,
        fee: this.state.type_sale == 2 ? 0 : this.state.fee_price,
        product: this.state.product,
        type_sale: this.state.type_sale,
      }
      // this.state.pd.push(p.code)
    }

    if (this.state.buy_price != '') {
      // const p = Utility.getObject(this.products, this.state.buy_product_id);
      let name = '';
      if (this.state.product) {
        name = this.state.product.name;
      }
      const cate = Utility.getObject(this.props.formValue.categories, this.state.buy_categories_id);
      buy = {
        category_id: this.state.buy_categories_id,
        category_name: cate.name,
        buy_product_id: this.state.buy_product_id,
        buy_weight_real_g: Number(this.state.buy_weight_real_g).toFixed(3),
        buy_weight_real_b: this.state.buy_weight_real_b,
        buy_price: this.state.buy_price,
        buy_name: this.state.product_name,
        gold_price: this.state.gold_bar_sell,
        exchange: this.state.exchange,
        id_change: code_change,
        is_price_aag: this.state.is_price_aag,
        staff_product_stock: this.state.staff_product_stock,
      }
      // this.addProductName(this.state.product_name)
    }

    this.props.onSubmit(e, {
      sell: sell,
      buy: buy,
    });

    this.props.onClose()
    this.handlerReset(e);
  }

  setDiff() {
    setTimeout(() => {
      if (this.state.sell_price != '' && this.state.buy_price) {
        this.setState({
          exchange: (Number(this.state.sell_price) - Number(this.state.buy_price)).toFixed(0)
        });
      } else {
        if (this.state.buy_price == 0) {
          this.setState({
            exchange: (Number(this.state.sell_price) - Number(this.state.buy_price)).toFixed(0)
          });
        } else {
          this.setState({
            exchange: 0
          });
        }
      }
    }, 400);
  }

  setDiffExchange() {
    setTimeout(() => {
      if (this.state.sell_price != '' && this.state.buy_price) {
        let diff = (Number(this.state.sell_price) - Number(this.state.exchange)).toFixed(0)
        if (diff < 0) {
          this.setState({
            buy_price: '0'
          });
        } else {
          this.setState({
            buy_price: diff
          });
        }

      } else {
        if (this.state.buy_price == 0) {
          let diff = (Number(this.state.sell_price) - Number(this.state.exchange)).toFixed(0)
          if (diff < 0) {
            this.setState({
              buy_price: '0'
            });
          } else {
            this.setState({
              buy_price: diff
            });
          }
        }
      }
    }, 400);
  }

  handleBlockChange(weight_g) {
    weight_g = parseFloat(weight_g)
    let block = this.state.block
    let value = 0

    for (let i in block) {
      let data = block[i]
      if (data['weight'] <= weight_g && weight_g < data['weight_max']) {
        value = data['fee']
        return value
      }
    }
    return value
  }

  handlerBuyWeightB(e, d) {
    if (isNaN(Number(d.value))) {
    } else {
      if (d.value.length === 0) {
        d.value = 0
      }
      if (d.value.length > 0) {
        let str = d.value.split('.')
        str[0] = str[0].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        str[1] = str[1] == undefined ? '' : '.' + str[1]
        if (str.length != 1) {
          if (str[1].length > 4) {
            return;
          }
        }
      }
      e.preventDefault();

      let buy_weight_real_b = Utility.parseFloat(d.value, this.state.buy_weight_real_b)
      const cate = Utility.getObject(this.props.formValue.categories, this.state.buy_categories_id);
      let buy_price = 0
      if (cate != null) {
        if (buy_weight_real_b > (this.state.max_weight / cate.weight).toFixed(3)) {
          buy_weight_real_b = (this.state.max_weight / cate.weight)
        }
        let b = buy_weight_real_b * Number(cate.m_buy);
        this.setState({
          buy_weight_real_b: buy_weight_real_b,
          buy_weight_real_g: (Number(buy_weight_real_b * cate.weight)).toFixed(3),
        })
        if (this.props.kind == 'BU') {
          let gold_rec = this.state.gold_rec2
          if (!this.state.is_price_aag) {
            gold_rec = this.state.gold_rec1
          }
          let buy_weight_real_g = Number((Number(buy_weight_real_b * cate.weight)).toFixed(3))
          // let buy_price = (b * Number(Utility.removeCommas(this.state.gold_bar_buy))) + (Number(cate.discount_buy) * b);
          const g = Math.ceil((this.state.gold_bar_buy * (gold_rec / (15.200 / buy_weight_real_g)) % this.state.round))
          const buy_price_1_bath = Math.ceil((this.state.gold_bar_buy * (gold_rec / (15.200 / 15.200)) / this.state.round)) * this.state.round
          const block_cost = this.handleBlockChange((Number(buy_weight_real_b * cate.weight)).toFixed(3))
          if (cate['code'] != '965') {
            buy_price = g >= 50 ? Math.ceil((this.state.gold_bar_buy * (gold_rec / (15.200 / buy_weight_real_g)) / this.state.round)) * this.state.round : Math.floor((this.state.gold_bar_buy * (gold_rec / (15.200 / buy_weight_real_g)) / this.state.round)) * this.state.round
            buy_price = buy_price < 0 ? 0 : buy_weight_real_b >= 1 ? buy_price_1_bath * buy_weight_real_b : buy_price
          } else {
            buy_price = (this.state.gold_bar_buy * buy_weight_real_b) - block_cost
          }

          this.setState({
            buy_price: buy_price.toFixed(0),
            buy_price_compare: buy_price.toFixed(0),
            block_cost: block_cost,
          })

        }
        // this.setDiff();
      }
    }

  }

  handlerBuyWeightG(e, d) {
    d.value = d.value.toString().replace(/,/g, '')
    if (isNaN(Number(d.value))) {
    } else {
      if (d.value.length === 0) {
        d.value = 0
      }
      if (d.value.length > 0) {
        let str = d.value.split('.')
        str[0] = str[0].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        str[1] = str[1] == undefined ? '' : '.' + str[1]
        if (str.length != 1) {
          if (str[1].length > 4) {
            return;
          }
        }
      }
      if (d.value > this.state.max_weight) {
        d.value = this.state.max_weight
      }
      e.preventDefault();
      let buy_price = 0
      const buy_weight_real_g = Utility.parseFloat(d.value, this.state.buy_weight_real_g)
      const cate = Utility.getObject(this.props.formValue.categories, this.state.buy_categories_id);
      const block_cost = this.handleBlockChange(d.value)
      if (cate != null) {
        let b = buy_weight_real_g / Number(cate.weight) * Number(cate.m_buy);
        this.setState({
          buy_weight_real_g: d.value,
          buy_weight_real_b: (buy_weight_real_g / cate.weight).toFixed(3),
        })
        if (this.props.kind == 'BU') {
          let gold_rec = this.state.gold_rec2
          if (!this.state.is_price_aag) {
            gold_rec = this.state.gold_rec1
          }
          let weight_bath = Number((buy_weight_real_g / cate.weight).toFixed(3))
          // let buy_price = (b * Number(Utility.removeCommas(this.state.gold_bar_buy))) + Number(cate.discount_buy);
          const buy_price_1_bath = Math.ceil((this.state.gold_bar_buy * (gold_rec / (15.200 / 15.200)) / this.state.round)) * this.state.round
          const g = Math.ceil((this.state.gold_bar_buy * (gold_rec / (15.2 / d.value)) % this.state.round))
          if (cate['code'] != '965') {
            buy_price = g >= 50 ? Math.ceil((this.state.gold_bar_buy * (gold_rec / (15.2 / d.value)) / this.state.round)) * this.state.round : Math.floor((this.state.gold_bar_buy * (gold_rec / (15.2 / d.value)) / this.state.round)) * this.state.round
            buy_price = buy_price < 0 ? 0 : weight_bath >= 1 ? buy_price_1_bath * weight_bath : buy_price
          } else {
            buy_price = (this.state.gold_bar_buy * weight_bath) - block_cost
          }

          this.setState({
            buy_price: buy_price.toFixed(0),
            buy_price_compare: buy_price.toFixed(0),
            block_cost: block_cost,
          })
        }
        // this.setDiff();
      }
    }

  }

  handlerBuyPrice(e, d) {
    d.value = d.value.toString().replace(/,/g, '')
    if (isNaN(Number(d.value))) {
    } else {
      if (d.value.length === 0) {
        d.value = 0
      }
      const cate = Utility.getObject(this.props.formValue.categories, this.state.buy_categories_id);
      if(!cate) return
      const block_cost = this.state.block_cost
      if (this.props.auth.role != 'A' && this.props.auth.role != 'M' && this.props.auth.role != 'S') {
        if (cate['code'] != '965') {
          if (Number(d.value) > Number(this.state.buy_price_compare)) {
            d.value = this.state.buy_price_compare
          }
        } else {
          if (Number(d.value) > Number(this.state.buy_price_compare)) {
            d.value = (Number(this.state.gold_bar_buy) * this.state.buy_weight_real_b) - block_cost
          }
        }
      } else {
        if (cate['code'] != '965') {
          if (Number(d.value) > Number(this.state.gold_bar_buy) * this.state.buy_weight_real_b) {
            d.value = Number(this.state.gold_bar_buy) * this.state.buy_weight_real_b
          }
        }
        else {
          if (Number(d.value) > (Number(this.state.gold_bar_buy) * this.state.buy_weight_real_b)) {
            d.value = (Number(this.state.gold_bar_buy) * this.state.buy_weight_real_b)
          }
        }
      }
      const buy_price = Utility.parseFloat(d.value, this.state.buy_price).toFixed(0)
      e.preventDefault();
      this.setState({ buy_price });
      // this.setDiff();
    }

  }

  handlerExchange(e, d) {
    let msg_error = {}
    let max_buy = 0
    if (this.state.buy_categories_id == 1) {
      max_buy = parseFloat(this.state.gold_bar_buy) * (parseFloat(this.state.buy_weight_real_b) * 0.9)
    } else if (this.state.buy_categories_id == 3 || this.state.buy_categories_id == 2) {
      max_buy = (parseFloat(this.state.gold_bar_buy) - 200) * parseFloat(this.state.buy_weight_real_b)
    }
    let min_cost = parseFloat(this.state.sell_price) - max_buy
    min_cost = min_cost < 0 ? 0 : min_cost
    d.value = d.value.toString().replace(/,/g, '')
    if (isNaN(Number(d.value))) {
    } else {
      if (d.value.length === 0) {
        d.value = 0
      }
      // if (d.value < min_cost) {
      //   msg_error['changecost'] = 'กรุณากรอกเงินเปลี่ยน > ' + min_cost.toFixed(0)
      // }
      const exchange = Utility.parseFloat(d.value, this.state.exchange).toFixed(0)
      e.preventDefault();
      this.setState({ exchange, msg_error });
      // this.setDiffExchange();
    }

  }

  handlerQRCODE(e, d) {
    if (this.state.is_submit_qrcode) {
      this.setState({
        category_id: '',
        category_name: '',
        product_type_id: '',
        product_type: '',
        product_id: '',
        product_name: '',
        sell_weight_real_g: '',
        sell_price: '',
        product_price: '',
        fee_price: '',
      })
    }
    this.setState({ QRCODE: d.value });
  }

  submitQRcode() {
    if (this.state.can_sale <= 0 && !this.props.is_super) {
      alert('ไม่เหลือสิทธิ์สำหรับการซื้อสินค้า')
      // this.setState({ QRCODE: '' })
      // return
    }
    let data = []
    data['code'] = this.state.QRCODE.trim()
    data['stock_type'] = 'new'
    data['branch'] = this.props.auth.branch.id
    this.setState({ is_submit_qrcode: true })

    let qrstring = Utility.jsonToQueryString(data);
    var qrcode = Utility.getFetch(Settings.baseUrl + '/qrcode/?' + qrstring);
    Promise.all([qrcode]).then((values) => {
      if (values[0].detail === undefined) {

        this.setState({
          category_id: values[0].stock_product.product.category.id,
          category_name: values[0].stock_product.product.category.name,
          category_code: values[0].stock_product.product.category.code,
          product_type_id: values[0].stock_product.product.kind.id,
          product_type: values[0].stock_product.product.kind.name,
          product_id: values[0].stock_product.product.id,
          product_name: values[0].stock_product.product.name,
          product_name_sell: values[0].stock_product.product.name,
          sell_weight_real_g: values[0].stock_product.product.weight,
          QRCODE2: values[0].qrcode,
          product: values[0].stock_product.product,
          type_sale: values[0].stock_product.product.type_sale,
          product_cost: Number(values[0].invoice_item.cost) + Number(values[0].invoice_item.fee),
        })
        if (this.props.kind == 'XC') {
          this.setState({
            buy_categories_id: values[0].stock_product.product.category.id,
          })
        }
        // this.setState({product_price:this.state.gold_bar_sell})
        this.setState({
          fee_min: values[0].fee,
          fee_price: values[0].fee,
        })
        this.setState({
          kind_code: values[0].stock_product.product.kind.code,
        })
        if (this.props.kind == 'XC') {
          this.setState({
            exchange: values[0].fee,
            kind_code: values[0].stock_product.product.kind.code,
          })
        }

        const sell_weight_real_g = Utility.parseFloat(values[0].stock_product.product.weight, this.state.sell_weight_real_g)
        // const p = Utility.getObject(this.products, this.state.product_id);
        if (this.state.product) {

          this.setState({ sell_weight_real_g: sell_weight_real_g })
          this.setPriceSell(this.state.product, sell_weight_real_g, 1, this.state.fee_price)
        }
        this.weightRealGInput.focus()
      } else {
        alert(values[0].detail)
        this.setState({
          category_id: '',
          category_name: '',
          product_type_id: '',
          product_type: '',
          product_id: '',
          product_name: '',
          sell_weight_real_g: '',
          sell_price: '',
          product_price: '',
          fee_price: '',
          QRCODE: '',
        })


        const sell_weight_real_g = Utility.parseFloat('', this.state.sell_weight_real_g)
        // const p = Utility.getObject(this.products, this.state.product_id);
        if (this.state.product) {
          this.setState({ sell_weight_real_g: sell_weight_real_g })
          this.setPriceSell(this.state.product, sell_weight_real_g, 1, this.state.fee_price)
        }
      }

    })
  }

  handlerfee(e, v) {
    v.value = v.value.toString().replace(/,/g, '')
    if (isNaN(Number(v.value))) {
    } else {
      if (v.value.length === 0) {
        v.value = 0
      }
      const fee_price = Utility.parseFloat(v.value, this.state.fee_price)
      // const p = Utility.getObject(this.products, this.state.product_id);
      if (this.state.product) {
        this.setState({ fee_price: fee_price })
        this.setPriceSell(this.state.product, this.state.sell_weight_real_g, 1, fee_price)
      }
    }
  }

  // addProductName(name) {
  //   let url = Settings.baseUrl + "/product_name/"
  //   Utility.post(url, { name: name }, (s, d) => {
  //   })
  // }

  // loadProduct() {
  //   const { branch } = this.props.auth
  //   const products = Utility.getFetch(Settings.baseUrl + '/stock_product/?is_enabled=1&branch=' + branch.id);
  //   Promise.all([products]).then((values) => {
  //     let product_options = []
  //     this.products = []

  //     for (let i in values[0]) {
  //       values[0][i].product.amount = values[0][i].amount
  //       this.products.push(values[0][i].product)

  //       product_options.push({
  //         key: values[0][i].product.id,
  //         value: values[0][i].product.id,
  //         text: values[0][i].product.code + " " + values[0][i].product.name
  //       });
  //     }
  //     this.setState({ product_options: product_options })
  //   });
  // }

  handleSeting(e, v) {
    this.setState({
      is_price_aag: v.checked,
    });
    let gold_rec = this.state.gold_rec2
    if (!v.checked) {
      gold_rec = this.state.gold_rec1
    }
    if (this.state.buy_weight_real_g != '') {
      // const weight_real_g = Utility.parseFloat(d.value, this.state.buy_weight_real_g)
      const cate = Utility.getObject(this.props.formValue.categories, this.state.buy_categories_id);
      let weight_real_g = Number(this.state.buy_weight_real_g)
      // let weight_real_b = Number(this.state.buy_weight_real_b)
      let weight_bath = Number((weight_real_g / cate.weight).toFixed(3))
      // let buy_price = (b * Number(Utility.removeCommas(this.state.gold_bar_buy))) + Number(cate.discount_buy);
      const buy_price_1_bath = Math.ceil((this.state.gold_bar_buy * (gold_rec / (15.200 / 15.200)) / this.state.round)) * this.state.round

      const g = Math.ceil((this.state.gold_bar_buy * (gold_rec / (15.2 / weight_real_g)) % this.state.round))
      let buy_price = g >= 50 ? Math.ceil((this.state.gold_bar_buy * (gold_rec / (15.2 / weight_real_g)) / this.state.round)) * this.state.round : Math.floor((this.state.gold_bar_buy * (gold_rec / (15.2 / weight_real_g)) / this.state.round)) * this.state.round
      buy_price = buy_price < 0 ? 0 : weight_bath >= 1 ? weight_bath * buy_price_1_bath : buy_price
      this.setState({
        buy_price: buy_price.toFixed(0),
        buy_price_compare: buy_price.toFixed(0),
      })
    }



  }

  render() {
    const { auth } = this.props
    let info_text = this.state.buy_weight_real_b != '' ? 'สามารถแก้ไขได้ไม่เกิน ' + Number(this.state.gold_bar_buy) * this.state.buy_weight_real_b : 'กรุณาระบุ น.น.ซื้อ (บ.) หรือ น.น.ซื้อ (ก.)'
    return (
      <Modal open={this.props.open} /*dimmer='blurring'*/>
        <Button id="btnCloseProductSelect" circular icon='close' basic floated='right' name='' onClick={this.props.onClose} />
        <Modal.Header id='headerModalSelectProduct' as='h3'>เลือกรายการสินค้า</Modal.Header>
        <Modal.Content id='contentModalSelectProduct' >
          <Dimmer active={this.state.is_loading} inverted>
            <Loader inverted content='Loading' />
          </Dimmer>
          <Modal.Description>

            <div>
              <div className={this.props.kind == 'BU' ? 'hidden' : ''}>
                <Message id='selectForSell'
                  color='green'
                  attached
                  size='tiny'
                  header='ขายทอง'
                />
                <Form className='attached fluid segment green' color='red' size='small' onSubmit={(e) => {
                  this.handlerSubmit(e)
                }}>
                  <FormGroup>
                    <FormField width={8}>
                      <label>* QR Code </label>
                      <Form.Input
                        autoFocus
                        placeholder='Qrcode'
                        value={this.state.QRCODE}
                        onChange={this.handlerQRCODE}
                        onKeyPress={(e) => {

                          if (e.key == 'Enter') {
                            this.submitQRcode()
                            // this.weightRealGInput.focus()
                            e.preventDefault()
                          }
                        }}
                        className='text-right' />
                    </FormField>
                  </FormGroup>
                  <Form.Group>
                    <Form.Field width={4} >
                      <label>*%ทอง </label>
                      <Input
                        placeholder='%ทอง'
                        value={this.state.category_name}
                        readOnly
                        className='text-right' />
                      {/* <SelectOption  selection options={this.props.formValue.state.categories} name='category_id'
                        autoFocus
                        onChange={this.handlerChangeCategory} value={this.state.category_id} onRef={(input) => { this.inputCategory = input }} /> */}
                    </Form.Field>
                    <Form.Field width={4} >
                      <label>ประเภทสินค้า </label>
                      <Input
                        placeholder='ประเภทสินค้า'
                        value={this.state.product_type}
                        readOnly
                        className='text-right' />
                      {/* <SelectOption label='ประเภทสินค้า' selection options={this.props.product_types_option} name='product_type'
                        onRef={(input) => { this.inputType = input; console.log('inputType', input) }}
                        onChange={this.handlerChangeProductType} value={this.state.product_type} /> */}
                    </Form.Field>

                    <Form.Field width={6} >
                      <label>*สินค้า</label>
                      <Input
                        placeholder='สินค้า'
                        value={this.state.product_name_sell}
                        readOnly
                        className='text-right' />
                      {/* <SelectOption label='สินค้า' selection options={this.state.product_options}
                        name='product_id'
                        onRef={(c) => { this.inputProduct = c }}
                        onChange={this.handlerProductChange} value={this.state.product_id} /> */}
                    </Form.Field>

                    {/* <Form.Field error={this.state.msg_error.amount != null} width={4} >
                      <label>*จำนวน {this.state.max<=0?<span className='red'>*ไม่คงเหลือ</span> : <span  className='green'> คงเหลือ {Utility.numberFormat(this.state.max)}ชิ้น</span>}<MsgInput text={this.state.msg_error.amount} /></label>
                      <Input
                        placeholder=''
                        ref={(input) => { this.amountInput = input; }}
                        value={this.state.amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                        onChange={this.handlerAmount}
                        min='0'
                        max={this.state.max}
                        // type='number'
                        onKeyPress={(e) => {

                          if (e.key == 'Enter') {
                            this.weightRealGInput.focus()
                            e.preventDefault()
                          }
                        }}
                        className='text-right' />
                    </Form.Field> */}

                  </Form.Group>
                  <Form.Group>
                    <Form.Field error={this.state.msg_error.sell_weight_real_g != null} width={5} >
                      <label>*น.น.ชั่ง(ก.) <MsgInput text={this.state.msg_error.sell_weight_real_g} /></label>
                      <Input
                        placeholder='น.น.ชั่ง(ก.)'
                        maxLength={7}
                        value={this.state.focus_weight ? this.state.sell_weight_real_g.toString().replace(/,/g, '') : this.state.sell_weight_real_g.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                        // value={
                        //   this.state.sell_weight_real_g==''?'':
                        //   this.state.sell_weight_real_g.toString().split('.')[1]==undefined?
                        //   parseFloat(this.state.sell_weight_real_g.toString().split('.')[0]).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                        //   :parseFloat(this.state.sell_weight_real_g.toString().split('.')[0]).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')+'.'+this.state.sell_weight_real_g.toString().split('.')[1]
                        // }
                        // type='number'
                        ref={(c) => {
                          this.weightRealGInput = c
                        }}
                        // onFocus={(e) => { e.target.select() }}
                        onChange={this.handlerChangeWeightReal}
                        onKeyPress={(e) => {
                          if (e.key == 'Enter') {
                            this.inputFee.focus()
                            e.preventDefault()
                          }
                        }}
                        onBlur={() => {
                          setTimeout(() => {
                            if (this.state.focus_weight) {
                              this.setState({
                                focus_weight: false,
                              });
                            }
                          }, 0);
                        }}
                        onFocus={(e) => {
                          e.target.select()
                          if (!this.state.focus_weight) {
                            this.setState({
                              focus_weight: true,
                            });
                          }
                        }}
                        className='text-right' />

                    </Form.Field>
                    <Form.Input label='*ราคา/ชิ้น' placeholder='ราคา/ชิ้น' readOnly width={5} value={this.state.product_price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} className='text-right' />
                    <Form.Field error={this.state.msg_error.fee != null} width={6}>
                      <label>*ค่ากำเหน็จ <MsgInput text={this.state.msg_error.fee} /></label>
                      <Input
                        maxLength={8}
                        placeholder='ค่ากำเหน็จ'
                        id='inputFee'
                        // type='number'
                        ref={(c) => {
                          this.inputFee = c
                        }}
                        readOnly={this.state.type_sale == 2}
                        // readOnly = {this.state.type === 0 ? false : true}
                        width={5}
                        // value={this.state.fee_price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                        value={
                          this.state.type_sale == 2 ? 0 :
                            this.state.focus_fee ? this.state.fee_price.toString().replace(/,/g, '')
                              : this.state.fee_price.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                        // min={this.props.auth.role == 'A'?0:(this.state.product_price * this.state.amount)}
                        // onFocus={(e) => { e.target.select() }}
                        onChange={this.handlerfee}
                        // onChange={this.handlerSellPrice}
                        // ref={(c) => {
                        //   this.totalSellInput = c
                        // }}
                        onKeyPress={(e) => {
                          if (e.key == 'Enter') {
                            this.totalSellInput.focus()
                            e.preventDefault()
                          }
                        }}

                        onBlur={() => {
                          setTimeout(() => {
                            if (this.state.focus_fee) {
                              this.setState({
                                focus_fee: false,
                              });
                            }
                          }, 0);
                        }}
                        onFocus={() => {
                          if (!this.state.focus_fee) {
                            this.setState({
                              focus_fee: true,
                            });
                          }
                        }}

                        className='text-right' />
                    </Form.Field>

                    <Form.Field
                      error={this.state.msg_error.sell_price != null}
                      width={4} >
                      <label>*ราคาขาย <MsgInput text={this.state.msg_error.sell_price} /></label>
                      <Input
                        maxLength={8}
                        id='inputPrice'
                        placeholder='ราคาขาย'
                        // type='number'
                        width={5}
                        // value={this.state.sell_price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                        value={this.state.focus_sell ? this.state.sell_price.toString().replace(/,/g, '') : this.state.sell_price.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                        min={this.props.auth.role == 'A' || this.props.auth.role == 'M' || this.props.auth.role == 'S' ? 0 : (this.state.product_price * this.state.amount)}
                        onChange={this.handlerSellPrice}
                        ref={(c) => {
                          this.totalSellInput = c
                        }}
                        // onKeyPress={(e) => {
                        //   if (e.key == 'Enter' && this.props.kind == 'XC') {
                        //     this.categoryBuyInput.focus()
                        //     e.preventDefault()
                        //   }
                        // }}

                        onBlur={() => {
                          setTimeout(() => {
                            if (this.state.focus_sell) {
                              this.setState({
                                focus_sell: false,
                              });
                            }
                          }, 0);
                        }}
                        onFocus={(e) => {
                          if (!this.state.focus_sell) {
                            this.setState({
                              focus_sell: true,
                            });
                          }
                          e.target.select()
                        }}
                        className='text-right' />
                    </Form.Field>
                  </Form.Group>
                  <div className='hidden' >
                    <Button type='submit' />
                  </div>
                </Form>
              </div>
              <br />
              <div className={this.props.kind == 'SE' ? 'hidden' : ''}>
                <Message
                  color='red'
                  attached
                  header='ซื้อทอง'
                  size='tiny'
                />
                <Form className='attached fluid segment red' color='red' size='small' onSubmit={(e) => {
                  this.handlerSubmit(e)
                }}>
                  <Form.Group>
                    <Form.Field width={16} error={this.state.msg_error.staff_product_stock != null}>
                      <label>*สินค้า <MsgInput text={this.state.msg_error.staff_product_stock} /></label>
                      <SelectOption
                        search selection width={4} options={this.state.stocks}
                        onRef={(c) => { }}
                        onChange={(e, d) => {
                          let item = Utility.getObjectByValue(this.state.stocks, d.value)
                          // let is_aagold_disabled = true
                          // if (d.value != 3 && d.value != 0) { // 3 = ทองแท่ง
                          //   is_aagold_disabled = false
                          // }
                          this.setState({
                            staff_product_stock: d.value,
                            buy_categories_id: item.category,
                            product_name: item.text,
                            max_weight: Number(item.weight),
                          });
                          let buy_price = 0
                          const buy_weight_real_g = Number(item.weight)
                          const cate = Utility.getObject(this.props.formValue.categories, item.category);
                          const block_cost = this.handleBlockChange(Number(item.weight))
                          if (cate != null) {
                            let b = buy_weight_real_g / Number(cate.weight) * Number(cate.m_buy);
                            this.setState({
                              buy_weight_real_g: Number(item.weight),
                              buy_weight_real_b: (buy_weight_real_g / cate.weight).toFixed(3),
                            })
                            if (this.props.kind == 'BU') {
                              let gold_rec = this.state.gold_rec2
                              if (!this.state.is_price_aag) {
                                gold_rec = this.state.gold_rec1
                              }
                              let weight_bath = Number((buy_weight_real_g / cate.weight).toFixed(3))
                              // let buy_price = (b * Number(Utility.removeCommas(this.state.gold_bar_buy))) + Number(cate.discount_buy);
                              const buy_price_1_bath = Math.ceil((this.state.gold_bar_buy * (gold_rec / (15.200 / 15.200)) / this.state.round)) * this.state.round
                              const g = Math.ceil((this.state.gold_bar_buy * (gold_rec / (15.2 / Number(item.weight))) % this.state.round))
                              if (cate['code'] != '965') {
                                buy_price = g >= 50
                                  ? Math.ceil((this.state.gold_bar_buy * (gold_rec / (15.2 / Number(item.weight))) / this.state.round)) * this.state.round
                                  : Math.floor((this.state.gold_bar_buy * (gold_rec / (15.2 / Number(item.weight))) / this.state.round)) * this.state.round
                                buy_price = buy_price < 0 ? 0 : weight_bath >= 1 ? buy_price_1_bath * weight_bath : buy_price
                              } else {
                                buy_price = (this.state.gold_bar_buy * weight_bath) - block_cost
                              }

                              this.setState({
                                buy_price: buy_price.toFixed(0),
                                buy_price_compare: buy_price.toFixed(0),
                                block_cost: block_cost,
                              })
                            }
                          }
                        }}
                        value={this.state.staff_product_stock}
                        defaultValue={this.state.staff_product_stock}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group >
                    <Form.Field width={3} id="percengold" error={this.state.msg_error.buy_categories_id != null}>
                      <label>*%ทอง <MsgInput text={this.state.msg_error.buy_categories_id} /></label>
                      <Input value={this.state.buy_categories_id == ''
                        ? '' : Utility.getObjectByValue(this.props.formValue.state.categories, this.state.buy_categories_id).text}
                        readOnly
                        className='text-right'
                        placeholder='%ทอง'
                      />
                    </Form.Field>
                    <Form.Field id="ProductName" width={4} error={this.state.msg_error.product_name != null}>
                      <label>*ชื่อสินค้า <MsgInput text={this.state.msg_error.product_name} /></label>

                      <Input
                        fluid list='products'
                        placeholder='ชื่อสินค้า'
                        readOnly
                        maxLength={100}
                        value={this.state.product_name}
                        onChange={(e, v) => this.setState({ product_name: v.value })}
                        ref={(input) => { this.productNameInput = input; }}
                        onKeyPress={(e) => {
                          if (e.key == 'Enter') {
                            this.weightRealInput.focus()
                            e.preventDefault()
                          }
                        }}
                      />
                      {/* <datalist id='products'>
                        {this.state.products.map((v, i) => <option value={v.name} key={i} />)}
                      </datalist> */}
                    </Form.Field>
                    <Form.Field id="weight-g" width={4} error={this.state.msg_error.buy_weight_real_g != null}>
                      <label>*น.น.ซื้อ (ก.) <MsgInput text={this.state.msg_error.buy_weight_real_g} /></label>
                      <Input placeholder='น.น.ซื้อ (ก.)'
                        maxLength={7}
                        ref={(c) => {
                          this.weightRealInput = c
                        }}
                        onKeyPress={(e) => {

                          if (e.key == 'Enter') {
                            this.buyPriceInput.focus()
                            e.preventDefault()
                          }
                        }}
                        // type='number'
                        // value={
                        //   this.state.buy_weight_real_g==''?'':
                        //   this.state.buy_weight_real_g.toString().split('.')[1]==undefined?
                        //   parseFloat(this.state.buy_weight_real_g.toString().split('.')[0]).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                        //   :parseFloat(this.state.buy_weight_real_g.toString().split('.')[0]).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')+'.'+this.state.buy_weight_real_g.toString().split('.')[1]
                        // }
                        value={this.state.focus_buy_weight ? this.state.buy_weight_real_g.toString().replace(/,/g, '') : this.state.buy_weight_real_g.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={this.handlerBuyWeightG}
                        onBlur={() => {
                          setTimeout(() => {
                            if (this.state.focus_buy_weight) {
                              this.setState({
                                focus_buy_weight: false,
                              });
                            }
                          }, 0);
                        }}
                        onFocus={() => {
                          if (!this.state.focus_buy_weight) {
                            this.setState({
                              focus_buy_weight: true,
                            });
                          }
                        }}
                        className='text-right' />
                    </Form.Field>
                    <Form.Field id="weight-b" width={4} error={this.state.msg_error.buy_weight_real_b != null}>
                      <label>*น.น.ซื้อ (บ.) <MsgInput text={this.state.msg_error.buy_weight_real_b} /></label>
                      <Input
                        placeholder='น.น.ซื้อ (บ.)'
                        maxLength={7}
                        width={3}
                        onKeyPress={(e) => {

                          if (e.key == 'Enter') {
                            this.buyPriceInput.focus()
                            e.preventDefault()
                          }
                        }}
                        // type='number'
                        onBlur={() => {
                          setTimeout(() => {
                            if (this.state.focus_buy_bath) {
                              this.setState({
                                focus_buy_bath: false,
                              });
                            }
                          }, 0);
                        }}
                        onFocus={() => {
                          if (!this.state.focus_buy_bath) {
                            this.setState({
                              focus_buy_bath: true,
                            });
                          }
                        }}
                        value={this.state.buy_weight_real_b == '' || this.state.buy_weight_real_b == null ? '' :
                          this.state.focus_buy_bath ? this.state.buy_weight_real_b.toString().replace(/,/g, '') : this.state.buy_weight_real_b.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                        // value={this.state.buy_weight_real_b}
                        onChange={this.handlerBuyWeightB}
                        className='text-right' />
                    </Form.Field>
                    <Form.Field id="money" width={4}>
                      <label>*เป็นเงิน</label>
                      <FormGroup>
                        <Input
                          readOnly={this.props.kind == 'XC'}
                          // value={this.state.buy_price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                          placeholder='เป็นเงิน'
                          value={this.state.focus_buy_price ? this.state.buy_price.toString().replace(/,/g, '') : this.state.buy_price.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                          ref={(e) => { this.buyPriceInput = e }}
                          // type='number'
                          onKeyPress={(e) => {

                            if (e.key == 'Enter' && this.props.kind == 'XC') {
                              this.changePriceInput.focus()
                              e.preventDefault()
                            }
                          }}
                          onBlur={() => {
                            setTimeout(() => {
                              if (this.state.focus_buy_price) {
                                this.setState({
                                  focus_buy_price: false,
                                });
                              }
                            }, 0);
                          }}
                          onFocus={(e) => {
                            e.target.select()
                            if (!this.state.focus_buy_price) {
                              this.setState({
                                focus_buy_price: true,
                              });
                            }
                          }}
                          onChange={this.handlerBuyPrice} className='text-right' />
                        <Popup
                          trigger={<Icon name='warning' bordered={true} color='grey' style={{ margin: 'auto', marginLeft: '5px', width: '32px', borderRadius: '25px' }} />}
                          content={info_text}
                          size='large'
                        />
                      </FormGroup>
                    </Form.Field>

                  </Form.Group>
                  <Form.Group>
                    <Form.Input label='ราคาทองวันนี้' placeholder='' width={4} value={this.state.gold_bar_sell.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} className='text-right' readOnly />
                    <Form.Input label='ราคาซื้อคืนทองรูปพรรณ' placeholder='' width={4} value={this.state.focus_gold_bar_sell ? this.state.gold_ornaments_buy : this.state.gold_ornaments_buy.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} className='text-right' readOnly />
                    {this.props.kind == 'XC' ?
                      <Form.Input label='เป็นเงินเปลี่ยน' placeholder='' width={4} readOnly
                        value={(this.state.exchange - this.state.fee_price).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                        className='text-right' />
                      : ''}
                    {this.props.kind == 'XC' ?
                      <Form.Field width={4} error={this.state.msg_error.changecost != null}>
                        <label>*เงินที่ได้รับ <MsgInput text={this.state.msg_error.changecost} /></label>
                        <Input
                          placeholder='เป็นเงินเปลี่ยน'
                          // readOnly={this.state.buy_price == 0}
                          // onFocus={(e) => { e.target.select() }}
                          ref={(c) => {
                            this.changePriceInput = c
                          }}
                          maxLength={10}
                          className={'text-right'}
                          // type='number'
                          onChange={this.handlerExchange}
                          onBlur={() => {
                            setTimeout(() => {
                              if (this.state.focus_exchange) {
                                this.setState({
                                  focus_exchange: false,
                                });
                              }
                            }, 0);
                          }}
                          onFocus={(e) => {
                            e.target.select()
                            if (!this.state.focus_exchange) {
                              this.setState({
                                focus_exchange: true,
                              });
                            }
                          }}
                          // value={this.state.exchange.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} 
                          value={this.state.focus_exchange ? this.state.exchange.toString().replace(/,/g, '') : this.state.exchange.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                        />
                      </Form.Field> : ''}
                    <Form.Field>
                      <Checkbox
                        // toggle
                        style={{ marginTop: "35%" }}
                        label={<label>ทองเอเอ </label>}
                        onChange={this.handleSeting}
                        checked={this.state.is_price_aag}
                        disabled={this.state.is_aagold_disabled}
                        readOnly
                      />
                    </Form.Field>
                  </Form.Group>
                  <div className='hidden' >
                    <Button type='submit' />
                  </div>
                </Form>
              </div>
              {(this.props.kind == 'SE' && !this.props.is_super) ?
                <span style={{ color: 'red' }}>ในรอบเดือนนี้ลูกค้ามีสิทธิ์ซื้อทองคงเหลือ {Utility.weightFormat(Number(this.state.qouta) - Number(this.props.used) - Number(this.props.used_this))}ก. ซื้อไปแล้ว {Utility.weightFormat(this.props.used)}ก. ลงรายการในบิลนี้ {Utility.weightFormat(this.props.used_this)}ก.</span> : null
              }
              <Table celled size='small'>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell><div className='text-center'>ราคาซื้อทองคำแท่ง</div></Table.HeaderCell>
                    <Table.HeaderCell><div className='text-center'>ราคาขายทองคำแท่ง</div></Table.HeaderCell>
                    <Table.HeaderCell><div className='text-center'>ราคาซื้อคืนทองรูปพรรณ</div></Table.HeaderCell>
                    <Table.HeaderCell><div className='text-center'>ราคาขายทองรูปพรรณ</div></Table.HeaderCell>
                    <Table.HeaderCell ><div className='text-center'>วันที่</div></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>

                  <Table.Row>
                    <Table.Cell><div className='text-center'>{Utility.priceFormat(this.state.gold_bar_buy)}</div></Table.Cell>
                    <Table.Cell><div className='text-center'>{Utility.priceFormat(this.state.gold_bar_sell)}</div></Table.Cell>
                    <Table.Cell><div className='text-center'>{Utility.priceFormat(this.state.gold_ornaments_buy)}</div></Table.Cell>
                    <Table.Cell><div className='text-center'>{Utility.priceFormat(this.state.gold_ornaments_sell)}</div></Table.Cell>
                    <Table.Cell><div className='text-center'>{Utility.formatDate(this.state.record_date)} {Utility.formatTime(this.state.record_date)}</div></Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              {this.props.kind == 'BU' || this.props.kind == 'XC' ?
                <div>
                  <b>ราคาซื้อทองเอเอ</b>
                  <Table celled size='small'>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell><div className='text-center'>ราคาทอง</div></Table.HeaderCell>
                        <Table.HeaderCell><div className='text-center'>2 บาท</div></Table.HeaderCell>
                        <Table.HeaderCell><div className='text-center'>1 บาท</div></Table.HeaderCell>
                        <Table.HeaderCell><div className='text-center'>2 สลึง</div></Table.HeaderCell>
                        <Table.HeaderCell><div className='text-center'>1 สลึง</div></Table.HeaderCell>
                        <Table.HeaderCell><div className='text-center'>1/2 สลึง</div></Table.HeaderCell>
                        <Table.HeaderCell><div className='text-center'>กรัม</div></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>

                      <Table.Row>
                        <Table.Cell><div className='text-center'>{Utility.priceFormat(this.state.gold_bar_buy)}</div></Table.Cell>
                        <Table.Cell><div className='text-center'>{Utility.priceFormat(this.gold_price_rec(this.state.gold_rec2, 1) * 2)}</div></Table.Cell>
                        <Table.Cell><div className='text-center'>{Utility.priceFormat(this.gold_price_rec(this.state.gold_rec2, 1))}</div></Table.Cell>
                        <Table.Cell><div className='text-center'>{Utility.priceFormat(this.gold_price_rec(this.state.gold_rec2, 2))}</div></Table.Cell>
                        <Table.Cell><div className='text-center'>{Utility.priceFormat(this.gold_price_rec(this.state.gold_rec2, 4))}</div></Table.Cell>
                        <Table.Cell><div className='text-center'>{Utility.priceFormat(this.gold_price_rec(this.state.gold_rec2, 8))}</div></Table.Cell>
                        <Table.Cell><div className='text-center'>{Utility.priceFormat(this.gold_price_rec(this.state.gold_rec2, 15.2))}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div> : null}
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button id="confirmProduct" type='submit' size='small' disabled={this.state.fee_price < 0} primary onClick={this.handlerSubmit} >ลงรายการ</Button>
        </Modal.Actions>
      </Modal>

    );
  }

  hot_keys = {
    'esc': {
      priority: 4,
      handler: this.props.onClose
    },
    'enter': {
      priority: 4,
      handler: (e) => {
        //e.preventDefault()
      }
    }
  }
}


const mapStateToProps = state => {
  return ({
    auth: state.auth,
  })
}
export default connect(
  mapStateToProps,
)(hotkeys(SellForm))
