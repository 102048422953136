/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Loader, Dimmer, Modal, Table,
} from 'semantic-ui-react';

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'

class PrintPreview extends Component {
    constructor(props) {
        super(props);


        this.state = {
            search: {
                end_date: moment()
            },
            items: [],
            at: null,
            sum: null,
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {
        var url_string = window.location.toString();
        var url = new URL(url_string);
        var temp_end = url.searchParams.get("end_date");
        if (temp_end) {
            this.setState({
                search: {
                    end_date: moment(temp_end, 'DD/MM/YYYY')
                }
            })
        }
    }

    resetForm(e) {

        this.setState({
            search: {
                end_date: moment(),
            }
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.end_date = Utility.formatDate2(search.end_date)
        let qrstring = Utility.jsonToQueryString(search)

        this.setState({
            loading: true,
            items: [],
            at: null,
            summary: null,
        })

        var safe = Utility.getFetch(Settings.baseReportUrl + '/cash-overview/?' + qrstring);
        Promise.all([safe]).then((values) => {
            this.setState({
                loading: false,
                items: this.setFieldValue(values[0].data),
                at: values[0].at,
            });
        });
    }

    setFieldValue(items) {
        for (let i in items) {
            items[i].cashier_in = items[i].before_cashier + items[i].cashier_sum.total_cash_in
            items[i].cashier_ex = items[i].cashier_sum.total_cash_ex
            items[i].safe_in = items[i].before_safe + items[i].safe_sum.total_cash_in
            items[i].safe_ex = items[i].safe_sum.total_cash_ex
        }
        this.setState({
            summary: {
                cashier_in: items.reduce((sum, item) => sum + (item.cashier_in), 0),
                cashier_ex: items.reduce((sum, item) => sum + (item.cashier_ex), 0),
                safe_in: items.reduce((sum, item) => sum + (item.safe_in), 0),
                safe_ex: items.reduce((sum, item) => sum + (item.safe_ex), 0),
                company: items.reduce((sum, item) => sum + (item.company.returned - item.company.received), 0),
            }
        })
        return items
    }
    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
    }

    render() {
        let title = 'รายงานสรุปกระแสเงินสดสาขา ณ วันที่';
        let filename = 'safe-summary-' + Utility.formatDate3(this.state.search.end_date)

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace',
        };
        const textRight = {
            'text-align': 'right',
        }

        const textLeft = {
            'text-align': 'left',
        }
        const textCenter = {
            'text-align': 'center',
        }

        let items = this.state.items

        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>{title}</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >
                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field >
                                <label>ณ วันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.end_date}
                                    selected={this.state.search.end_date}
                                    maxDate={moment()}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'end_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>

                            <Button type='button' onClick={(e) => {
                                this.setState({
                                    open: true
                                })
                                this.handlerSubmit(e)
                            }}>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='9'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='9'>สถานะเงินสดสาขา ณ {this.state.at && ` ${Utility.formatDate(this.state.at)} ${Utility.formatTime(this.state.at)}`}</Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell rowSpan='2' style={textLeft}>สาขา</Table.HeaderCell>
                                            <Table.HeaderCell colSpan='3' style={textCenter} className='td_zone'>Cashier</Table.HeaderCell>
                                            <Table.HeaderCell colSpan='3' style={textCenter} className='td_zone'>เซฟ</Table.HeaderCell>
                                            <Table.HeaderCell rowSpan='2' style={textRight}>รวมเงินสด</Table.HeaderCell>
                                            <Table.HeaderCell rowSpan='2' style={textRight}>ส่วนต่างบริษัท</Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell style={textRight}>ยอดเงินรับ + ยกมา</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ยอดเงินจ่าย</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight} className='td_zone'>คงเหลือ</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ยอดเงินรับ + ยกมา</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ยอดเงินจ่าย</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight} className='td_zone'>คงเหลือ</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {items.map((row, i) => <Table.Row key={i}>
                                            <Table.Cell style={textLeft}>{row.name}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(row.cashier_in)}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(row.cashier_ex)}</Table.Cell>
                                            <Table.Cell style={textRight} className='td_zone'>{Utility.priceFormat(row.cashier_in - row.cashier_ex)}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(row.safe_in)}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(row.safe_ex)}</Table.Cell>
                                            <Table.Cell style={textRight} className='td_zone'>{Utility.priceFormat(row.safe_in - row.safe_ex)}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat((row.cashier_in - row.cashier_ex) + (row.safe_in - row.safe_ex))}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(row.company.returned - row.company.received)}</Table.Cell>
                                        </Table.Row>)}
                                        {
                                            this.state.summary && <>
                                                <Table.Row >
                                                    <Table.Cell style={textLeft}>รวม</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(this.state.summary.cashier_in)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(this.state.summary.cashier_ex)}</Table.Cell>
                                                    <Table.Cell style={textRight} className='td_zone'>{Utility.priceFormat(this.state.summary.cashier_in - this.state.summary.cashier_ex)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(this.state.summary.safe_in)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(this.state.summary.safe_ex)}</Table.Cell>
                                                    <Table.Cell style={textRight} className='td_zone'>{Utility.priceFormat(this.state.summary.safe_in - this.state.summary.safe_ex)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat((this.state.summary.cashier_in - this.state.summary.cashier_ex) + (this.state.summary.safe_in - this.state.summary.safe_ex))}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(this.state.summary.company)}</Table.Cell>
                                                </Table.Row>
                                            </>
                                        }
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(() => {
                                mywindow.close();
                            }, 2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}
export default connect(
    mapStateToProps,
)(PrintPreview)