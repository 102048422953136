/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Settings from '../../Settings';
import Utility from '../../Utility';
import MsgInput from '../Error/MsgInput'
import LedgerCategoryForm from './LedgerCategoryForm'
import { connect } from 'react-redux'
import moment from 'moment';
import {
  Form,
  Input,
  Button,
  Icon,
  Modal, Dropdown,
  Grid,
  Header,
  TextArea,
  FormField
} from 'semantic-ui-react';
import InputSearchCustomer from '../Customer/InputSearchCustomer';
import NumberFormat from 'react-number-format';

class LedgerForm extends Component {

  constructor(props) {
    super(props);
    this.ledger_category_list = props.ledger_category.list

    this.state = {
      msg_error: {},
      ledger: props.ledger,
      card_code: '',
      payment_type: []

    }
    this.handlerModalInputChange = this.handlerModalInputChange.bind(this)
    this.handleSubmitledger = this.handleSubmitledger.bind(this)
    this.updateLedgerCategory = this.updateLedgerCategory.bind(this)
  }

  setLedgerCategoryOption(kind) {
    let ledger_category_options = []

    for (let i in this.ledger_category_list) {
      let d = this.ledger_category_list[i]
      if (d.action && !['A', 'S', 'M'].includes(this.props.auth.role)) {
        continue
      }

      if (d.kind != kind || ((d.is_standard == 1 && d.id != 12) && !d.action))
        continue
      ledger_category_options.push({
        value: d.id,
        key: d.id,
        text: d.title,
        action: d.action
      })
    }
    this.setState({ ledger_category_options: ledger_category_options })
  }

  componentDidMount() {
    this.setState({ st: true })
    const { auth } = this.props
    let payment_type = []
    Settings.payment_option.filter((p) => ['CC', 'CS', 'CD'].includes(p.value)).forEach((p) => {
      payment_type.push({
        key: p.key,
        value: p.value,
        text: p.text,
      })
    })
    this.setState({
      ledger: {
        card_service: '',
        transfer: '',
        check: '',
        card: '',
        cash: '',
        customer_id: 0,
        ledger_date: moment(),
        kind: 'IN',
        card_bank_card: '',
        bank_cards: [],
        safe_bill: 0,
        safe_lease: 0,
        safe_all: 0,
        show_safe: false,
      },
      payment_type: payment_type
    })
    Utility.get(Settings.baseUrl + "/banks/?is_enabled=1", (e, resJson) => {
      this.banks = resJson;
      let banks = []
      for (let i in resJson) {
        banks.push({
          value: resJson[i].id,
          key: resJson[i].id,
          text: resJson[i].name
        });
      }
      this.setState({
        banks: banks
      });
    })
    Utility.get(Settings.baseUrl + "/staffs/?is_enabled=1&branch=" + auth.branch.id, (e, resJson) => {
      this.staffs = resJson;
      let staffs = []
      for (let i in resJson) {
        staffs.push({
          value: resJson[i].id,
          key: resJson[i].id,
          text: resJson[i].name
        });
      }
      this.setState({
        st: false,
        staffs: staffs
      });
    })
    Utility.get(Settings.baseUrl + "/bank_cards/?is_enabled=1", (e, resJson) => {
      this.bank_cards = resJson;
      let bank_cards = []
      for (let i in resJson) {
        if (resJson[i].period !== 0) continue
        bank_cards.push({
          value: resJson[i].id,
          key: resJson[i].id,
          text: resJson[i].kind + " - " + resJson[i].bank.name
        });
      }
      this.setState({
        bank_cards: bank_cards
      });
    })

    Utility.get(Settings.baseUrl + "/bank_account/", (e, resJson) => {
      this.bank_accounts = resJson;

      let bank_accounts = []
      for (let i in this.bank_accounts) {
        bank_accounts.push({
          value: this.bank_accounts[i].id,
          key: this.bank_accounts[i].id,
          text: this.bank_accounts[i].bank.name + ' ' + this.bank_accounts[i].number + ' (' + this.bank_accounts[i].name + ')',
          content: <Header as='h4' content={this.bank_accounts[i].bank.name} subheader={this.bank_accounts[i].number + ' (' + this.bank_accounts[i].name + ')'} />
        });
      }
      this.setState({
        bank_accounts: bank_accounts
      });
    })

    Utility.get(Settings.baseUrl + "/ledger_category/?is_enabled=1", (status, data) => {
      this.ledger_category_list = data
      let ledger = this.state.ledger
      this.setLedgerCategoryOption(ledger.kind)
    })
    Utility.get(Settings.baseUrl + "/safe-summary/?branch=" + auth.branch.id, (status, d) => {
      if (status) {
        const data_branch = (d.data || []).find(item => item.id === auth.branch.id)
        if (data_branch) {
          const cashier_bill = data_branch.cashier_bill.reduce((sum, item) => sum + (item.total_cash_in - item.total_cash_ex), 0)
          const cashier_lease = data_branch.cashier_lease.reduce((sum, item) => sum + (item.total_cash_in - item.total_cash_ex), 0)
          const cashier_saving = data_branch.cashier_saving.reduce((sum, item) => sum + (item.total_cash_in - item.total_cash_ex), 0)
          const cashier_all = data_branch.cashier_all.reduce((sum, item) => sum + (item.total_cash_in - item.total_cash_ex), 0)
          this.setState({
            safe_bill: data_branch.safe_bill.reduce((sum, item) => sum + (item.last_balance), 0),
            safe_lease: data_branch.safe_lease.reduce((sum, item) => sum + (item.last_balance), 0),
            safe_all: data_branch.safe_all.reduce((sum, item) => sum + (item.last_balance), 0),
            cash: data_branch.before_cash + cashier_bill + cashier_lease + cashier_saving + cashier_all
          })
        }
      }
    })



    this.setLedgerCategoryOption(this.props.ledger.kind)
  }

  handleChangeKind(kind) {
    this.setLedgerCategoryOption(kind)
    this.setState({
      ledger: {
        card_service: '',
        transfer: '',
        check: '',
        card: '',
        cash: '',
        customer_id: 0,
        ledger_date: moment(),
        card_bank_card: '',
        kind: kind,
        object_number: '',
        description: '',
      },
      show_safe: false,
    })
    let payment_type = []
    if (kind == 'IN') {
      payment_type = Settings.payment_option.filter(p => !['TF', 'CH'].includes(p.value))
    } else {
      if (this.state.ledger.payment != 'CS') {
        this.state.ledger.payment = null
      }
      payment_type.push({
        key: Settings.payment_option[1].key,
        value: Settings.payment_option[1].value,
        text: Settings.payment_option[1].text,
      })

    }
    this.setState({ payment_type })
  }

  handlerModalInputChange(e, d) {
    let ledger = this.state.ledger;
    ledger[d.name] = d.value;

    if (d.name == 'card_fee' || d.name == 'card_service' || d.name == 'check' || d.name == 'transfer' || d.name == 'card' || d.name == 'cash') {
      d.value = d.value.toString().replace(/,/g, '')
      if (isNaN(parseFloat(d.value))) {
        ledger[d.name] = ''
      } else {
        let c = d.value
        c = c < 0 ? c * -1 : c
        ledger[d.name] = c;
      }
    }

    if (d.name == 'check_code' || d.name == 'card_code') {
      d.value = d.value.toString().replace(/,/g, '')
      if (isNaN(parseInt(d.value))) {
        ledger[d.name] = ''
      } else {
        ledger[d.name] = parseInt(d.value)
      }
    }

    if (d.name == 'card_bank_card') {
      let o = Utility.getObject(this.bank_cards, d.value)
      ledger.card_fee = o.fee;
    }
    if (d.name == 'ledger_category' && ledger.kind == 'IN') {
      let payment_type = []
      if (d.value == 12) {
        payment_type.push({
          key: Settings.payment_option[1].key,
          value: Settings.payment_option[1].value,
          text: Settings.payment_option[1].text,
        })
        if (this.state.ledger.payment != 'CS') {
          this.state.ledger.payment = null
        }
      }
      else {
        // payment_type = Settings.payment_option
        for (let i in Settings.payment_option) {
          if (!['CH', 'TF'].includes(Settings.payment_option[i].value)) {
            payment_type.push({
              key: Settings.payment_option[i].key,
              value: Settings.payment_option[i].value,
              text: Settings.payment_option[i].text,
            })
          }
        }
      }
      this.setState({ payment_type })
    }
    if (d.name === 'ledger_category') {
      this.state.ledger.cash = 0
      this.state.ledger.card = 0
      this.state.ledger.transfer = 0
      const option = d.options.find((o) => o.value === d.value)
      this.setState({ show_safe: option && Boolean(option.action) })
      if (option && Boolean(option.action)) {
        let payment_type = [
          {
            key: Settings.payment_option[1].key,
            value: Settings.payment_option[1].value,
            text: Settings.payment_option[1].text,
          }
        ]
        this.setState({ payment_type })
        this.state.ledger.payment = 'CS'
      }
    }
    // if (d.name == 'card_service') {
    //   if (d.value.split('.').length != 1)
    //     if (d.value.split('.')[1].length > 2) {
    //       return;
    //     }
    // }
    if (d.name == 'card' || d.name == 'card_bank_card') {
      ledger.card_service = Number(ledger.card_fee) * Number(ledger.card) / 100
    }
    if (d.name == 'card' || d.name == 'card_bank_card' || d.name == 'cash') {
      let total = Number(ledger.card) + Number(ledger.card_service) + Number(ledger.cash)
      this.setState({
        total: total
      })
    }

    this.setState({
      ledger: ledger
    })
  }

  updateLedgerCategory(ledger_category = null) {
    Utility.get(Settings.baseUrl + "/ledger_category/?is_enabled=1", (status, data) => {
      this.ledger_category_list = data
      let ledger = this.state.ledger
      this.setLedgerCategoryOption(ledger.kind)

      if (ledger_category != null) {
        if (ledger_category.kind === ledger.kind)
          ledger.ledger_category = ledger_category.id
      }
      if (ledger.kind == 'IN') {
        let payment_type = []
        if (ledger_category.id != 12) {
          for (let i in Settings.payment_option) {
            if (!['CH', 'TF'].includes(Settings.payment_option[i].value)) {
              payment_type.push({
                key: Settings.payment_option[i].key,
                value: Settings.payment_option[i].value,
                text: Settings.payment_option[i].text,
              })
            }
          }
          this.setState({ payment_type })
        }
      }
      this.setState({ ledger_category_open: false, ledger: ledger })
    })
  }

  handleSubmitledger(e) {
    e.preventDefault()

    const { branch } = this.props.auth

    let msg_error = {}
    let data = this.state.ledger
    data.card_code = this.state.card_code

    if (data.ledger_category == null) {
      msg_error['ledger_category'] = ['*กรุณาเลือก รายการ']
    }

    if (data.payment == null) {

      msg_error['payment'] = ['*กรุณาเลือก ประเภทการชำระ']
    }

    if (data.staff == null) {

      msg_error['staff'] = ['*กรุณาเลือก พนักงาน']
    }

    if (data.description == null || data.description == '') {
      msg_error['description'] = ['*กรุณาเลือกระบุหมายเหตุ']
    }

    if ((data.payment == 'CS' || data.payment == 'CC') && (data.cash == null || data.cash == '')) {
      msg_error['cash'] = ['*กรุณาระบุยอดเงิน']
    }

    if ((data.payment == 'CD' || data.payment == 'CC') && (data.card == null || data.card == '')) {
      msg_error['card'] = ['*กรุณาระบุยอดเงิน']
    }
    if ((data.payment == 'CD' || data.payment == 'CC') && data.card.toString().split('.')[0].length > 15) {
      msg_error['card'] = ['*ไม่สามารถกรอกจำนวนเงินเกิน 15 หลักได้']
    }
    if (data.payment == 'CH' && (data.check == null || data.check == '')) {
      msg_error['check'] = ['*กรุณาระบุยอดเงิน']
    }

    if (data.payment == 'TF' && (data.transfer == null || data.transfer == '')) {
      msg_error['transfer'] = ['*กรุณาระบุยอดเงิน']
    }

    if ((data.payment == 'CD' || data.payment == 'CC') && (data.card_code == null || !data.card_code.toString().trim().length || data.card_code == '' || !(/(^[0-9]+(-[0-9]+)+$)|(^\d*$)/.test(data.card_code)))) {
      msg_error['card_code'] = ['*กรุณาระบุรหัสบัตรเครดิต']
    }

    if ((data.payment == 'CD' || data.payment == 'CC') && data.card_code.length != 16) {
      msg_error['card_code'] = ['*กรอกรหัสบัตรผิดรูปแบบ']
    }

    if ((data.payment == 'CD' || data.payment == 'CC') && (data.card_bank_card == null || data.card_bank_card == '')) {
      msg_error['card_bank_card'] = ['*กรุณาระบุชนิดบัตรเครดิต']
    }
    if ((data.payment == 'CD' || data.payment == 'CC') && (data.card_fee == null || data.card_fee == '')) {
      msg_error['card_fee'] = ['*กรุณาระบุ%หักค่าธรรมเนียมบัตรเครดิต']
    }
    // if ((data.payment == 'CD' || data.payment == 'CC') && (data.card_service == null || data.card_service == '')) {
    //   msg_error['card_service'] = ['*กรุณาระบุค่าธรรมเนียมบัตรเครดิต']
    // }
    if ((data.payment == 'CS' || data.payment == 'CC') && data.cash.toString().split('.')[0].length > 15) {
      msg_error['cash'] = ['*ไม่สามารถกรอกจำนวนเงินเกิน 15 หลักได้']
    }
    if ((data.payment == 'CD' || data.payment == 'CC') && data.card_fee > 100) {
      msg_error['card_fee'] = ['*ไม่สามารถกรอกจำนวนเกิน 100%']
    }
    if ((data.payment == 'CD' || data.payment == 'CC') && data.card_service.toString().split('.')[0].length > 15) {
      msg_error['card_service'] = ['*ไม่สามารถกรอกจำนวนเงินเกิน 15 หลักได้']
    }

    if (Object.keys(msg_error).length > 0) {
      this.setState({
        msg_error: msg_error
      })
      return;
    }

    if (data.payment == 'CS')
      data.total = data.cash
    else if (data.payment == 'CD')
      data.total = data.card
    else if (data.payment == 'CC')
      data.total = parseFloat(data.card) + parseFloat(data.cash)
    else if (data.payment == 'CH')
      data.total = data.check
    else if (data.payment == 'TF')
      data.total = data.transfer

    if (this.state.ledger.staff == null)
      this.state.ledger.staff = ''
    if (this.state.ledger.card_bank_card == null)
      this.state.ledger.card_bank_card = ''

    if (this.props.action == 'EDIT') {
      let url = Settings.baseUrl + "/ledger/" + this.state.ledger.id + "/"
      let data = this.state.ledger

      data.branch = data.branch.id;
      this.setState({
        loadingSubmitledger: true
      })
      Utility.patch(url, data, (s, d) => {
        if (s) {
          this.props.onSave()
        } else {
          alert('ผิดพลาด กรุณาลองอีกครั้ง')
        }
      });
    } else {

      let url = Settings.baseUrl + "/ledger/";
      let data = Utility.cloneObjectJson(this.state.ledger)
      data.ledger_date = Utility.formatDate2(data.ledger_date)
      data.branch = branch.id
      this.setState({
        loadingSubmitledger: true
      })
      Utility.post(url, data, (s, d) => {
        if (s) {
          this.props.onSave(d)
        } else {
          msg_error = d
          if (d.description !== undefined) {
            if (d.description[0] === "Ensure this field has no more than 255 characters.") {
              msg_error.description = ["*ไม่สามารถกรอกหมายเหตุ ได้มากกว่า 255 ตัวอักษร"]
            }
          }
          this.setState({
            loadingSubmitledger: false,
            msg_error: msg_error
          })
        }
      });
    }
  }

  render() {

    return (<div>

      <Modal size='tiny' open={true} /*dimmer='blurring'*/>
        <Button id='closeIn-Out' circular icon='close' basic floated='right' name='' onClick={this.props.onClose} />
        <Modal.Header>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Icon name='sticky note' color={this.state.ledger.kind == 'IN' ? 'green' : 'red'} />รายละเอียด{this.state.ledger.kind == 'IN' ? 'รายรับ' : 'รายจ่าย'}
              </Grid.Column>
              <Grid.Column>

              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Header>
        <Modal.Content className="scrolling">
          {this.state.ledger_category_open && <LedgerCategoryForm onSave={this.updateLedgerCategory} onClose={() => this.setState({ ledger_category_open: false })} />}
          <center>
            <Button.Group size='small'>
              <Button id='income' onClick={() => this.handleChangeKind('IN')} color={this.state.ledger.kind == 'IN' ? 'green' : ''}>รายรับ</Button>
              <Button.Or />
              <Button id='outcome' onClick={() => this.handleChangeKind('EX')} color={this.state.ledger.kind == 'EX' ? 'red' : ''}>รายจ่าย</Button>
            </Button.Group>
          </center>
          <br />
          <Form size='small'>
            <Form.Field width={16} error={this.state.msg_error.ledger_category != null}>
              <label>*รายการ <MsgInput text={this.state.msg_error.ledger_category} /></label>
              {this.props.auth.role == 'A' || this.props.auth.role == 'M' || this.props.auth.role == 'S' ?
                <div style={{ display: 'inline-flex', width: '100%' }}>
                  <Dropdown id='menuIn-Out' width={16} placeholder='รายการ' fluid search selection name="ledger_category" value={this.state.ledger.ledger_category}
                    options={this.state.ledger_category_options}
                    onChange={this.handlerModalInputChange}
                  />
                  <Button id='add-order' onClick={() => this.setState({ ledger_category_open: true })} icon='plus' />
                </div>
                : <div style={{ display: 'inline-flex', width: '100%' }}>
                  <Dropdown id='menuIn-Out' width={16} placeholder='รายการ' fluid search selection name="ledger_category" value={this.state.ledger.ledger_category}
                    options={this.state.ledger_category_options}
                    onChange={this.handlerModalInputChange}
                  />
                </div>}
            </Form.Field>
            <Form.Field width={16} error={this.state.msg_error.payment != null}>
              <label>*ประเภทการชำระ <MsgInput text={this.state.msg_error.payment} /></label>
              <Dropdown id='typepayment' label='ประเภทการชำระ' placeholder='ประเภทการชำระ' search selection name="payment" value={this.state.ledger.payment}
                options={this.state.payment_type}
                onChange={this.handlerModalInputChange} />
            </Form.Field >
            <InputSearchCustomer label='ชื่อลูกค้า' placeholder='ชื่อลูกค้า' onChange={this.handlerModalInputChange} name="customer" value={this.state.ledger.customer} />
            <Form.Field width={16} error={this.state.msg_error.staff != null}>
              <label>*พนักงาน <MsgInput text={this.state.msg_error.staff} /></label>
              <Dropdown id='staff' label='พนักงาน' placeholder='พนักงาน' search selection name="staff" value={this.state.ledger.staff}
                options={this.state.staffs}
                onChange={this.handlerModalInputChange}
                loading={this.state.st} />
            </Form.Field>
            <Form.Input label='เลขที่อ้างอิง' placeholder='เลขที่อ้างอิง' search selection name="object_number" value={this.state.ledger.object_number}
              onChange={this.handlerModalInputChange} maxLength={20} />
            {this.state.ledger.payment != 'CD' && this.state.ledger.payment != 'CC' ? '' : <Form.Field error={this.state.msg_error.card_code != null}><label>*รหัสบัตรเครดิต <MsgInput text={this.state.msg_error.card_code} /></label>
              <NumberFormat value={this.state.card_code} format="#### #### #### ####"
                onValueChange={(v) => {
                  this.setState({ card_code: v.value });
                }}
                disabled={this.state.ledger.payment != 'CD' && this.state.ledger.payment != 'CC'}
                placeholder='รหัสบัตรเครดิต'
              />
            </Form.Field>
            }


            {['CD', 'CC', 'CDQ', 'CCQ'].includes(this.state.ledger.payment) &&
              <Form.Field error={this.state.msg_error.card_bank_card != null}>
                <label>*ชนิดบัตรเครดิต <MsgInput text={this.state.msg_error.card_bank_card} /></label>
                <Form.Dropdown
                  id='Typecredit'
                  placeholder='ชนิดบัตรเครดิต' search selection
                  name="card_bank_card"
                  value={this.state.ledger.card_bank_card}
                  options={this.state.bank_cards}
                  onChange={this.handlerModalInputChange}
                />
              </Form.Field>}
            {['QR', 'CSQ', 'CDQ', 'CCQ'].includes(this.state.ledger.payment) && <Form.Dropdown label='บัญชีธนาคาร' placeholder='บัญชีธนาคาร' search selection name="transfer_bank_account" value={this.state.ledger.transfer_bank_account}
              options={this.state.bank_accounts}
              onChange={this.handlerModalInputChange} />}
            {(this.state.ledger.payment == 'CH') && <Form.Dropdown label='ธนาคาร' placeholder='ธนาคาร' search selection name="check_bank" value={this.state.ledger.check_bank}
              options={this.state.banks}
              onChange={this.handlerModalInputChange} />}
            {(this.state.ledger.payment == 'CH') && <Form.Input label='เลขที่เช็ค'
              //  type='number' 
              placeholder='เลขที่เช็ค' search selection name="check_code" value={this.state.ledger.check_code}
              maxLength={10}
              onChange={this.handlerModalInputChange} />}



            {this.state.ledger.payment == 'CH' && <Form.Field width={16} error={this.state.msg_error.check != null}>
              <label>*จำนวนเงินชำระ(เช็ค) <MsgInput text={this.state.msg_error.check} /></label>
              <Input placeholder='จำนวนเงินชำระ' search selection name="check" value={this.state.ledger.check.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                // type='number'
                min={0}
                maxLength={12}
                onChange={this.handlerModalInputChange} />
            </Form.Field>}

            {['QR', 'CSQ', 'CDQ', 'CCQ'].includes(this.state.ledger.payment) && <Form.Field width={16} error={this.state.msg_error.transfer != null}>
              <label>*จำนวนเงินชำระ(โอน) <MsgInput text={this.state.msg_error.transfer} /></label>
              <Input placeholder='จำนวนเงินชำระ' search selection name="transfer" value={this.state.ledger.transfer.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                // type='number'  
                min={0}
                onChange={this.handlerModalInputChange} />
            </Form.Field>}

            {['CD', 'CC', 'CDQ', 'CCQ'].includes(this.state.ledger.payment) && <Form.Field width={16} error={this.state.msg_error.card != null}>
              <label>*จำนวนเงินชำระ(บัตร) <MsgInput text={this.state.msg_error.card} /></label>
              <Input id='inputcreditIn-Out' placeholder='จำนวนเงินชำระ' search selection name="card"
                onKeyPress={(e) => {
                  if (e.key == 'Enter') {
                    e.preventDefault()
                    if (this.state.ledger.payment == 'CD')
                      this.textInput.focus()
                    else
                      this.textInputcash.focus()
                  }
                }}
                maxLength={12}
                onBlur={() => {
                  setTimeout(() => {
                    if (this.state.focus_card) {
                      this.setState({
                        focus_card: false,
                      });
                    }
                  }, 0);
                }}
                onFocus={(e) => {
                  e.target.select()
                  if (!this.state.focus_card) {
                    this.setState({
                      focus_card: true,
                    });
                  }
                }}
                disabled={this.state.ledger.card_bank_card == ''}
                value={this.state.ledger.card == '' || this.state.ledger.card == undefined ? '' : this.state.focus_card ? this.state.ledger.card.toString().replace(/,/g, '') : this.state.ledger.card.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}

                // value={
                //   this.state.ledger.card==''|| this.state.ledger.card==undefined?'':
                //   this.state.ledger.card.toString().split('.')[1]==undefined?
                //   parseFloat(this.state.ledger.card.toString().split('.')[0]).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                //   :parseFloat(this.state.ledger.card.toString().split('.')[0]).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')+'.'+this.state.ledger.card.split('.')[1]
                // }
                // type='number'  
                min={0}
                onChange={(e, d) => {
                  d.value = d.value.toString().replace(/,/g, '')
                  if (isNaN(Number(d.value))) {
                  } else {
                    if (d.value.split('.').length != 1)
                      if (d.value.split('.')[1].length > 2) {
                        return;
                      }
                    this.handlerModalInputChange(e, d)
                  }
                }} />
            </Form.Field>}

            {['CS', 'CC', 'CSQ', 'CCQ'].includes(this.state.ledger.payment) && <Form.Field width={16} error={this.state.msg_error.cash != null}>
              <label>*จำนวนเงินชำระ(เงินสด) <MsgInput text={this.state.msg_error.cash} /></label>
              <Input id='inputcashIn-Out' placeholder='จำนวนเงินชำระ' search selection name="cash"
                ref={(input) => { this.textInputcash = input; }}
                onBlur={() => {
                  setTimeout(() => {
                    if (this.state.focus_cash) {
                      this.setState({
                        focus_cash: false,
                      });
                    }
                  }, 0);
                }}
                onFocus={(e) => {
                  e.target.select()
                  if (!this.state.focus_cash) {
                    this.setState({
                      focus_cash: true,
                    });
                  }
                }}
                maxLength={12}
                onKeyPress={(e) => {
                  if (e.key == 'Enter') {
                    e.preventDefault()
                    this.textInput.focus()
                  }
                }}
                value={this.state.ledger.cash == '' || this.state.ledger.cash == undefined ? '' : this.state.focus_cash ? this.state.ledger.cash.toString().replace(/,/g, '') : this.state.ledger.cash.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}


                // value={
                //   this.state.ledger.cash==''?'':
                //   this.state.ledger.cash.toString().split('.')[1]==undefined?
                //   parseFloat(this.state.ledger.cash.toString().split('.')[0]).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                //   :parseFloat(this.state.ledger.cash.toString().split('.')[0]).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')+'.'+ this.state.ledger.cash.split('.')[1]
                // }
                // type='number'  
                min={0}
                onChange={(e, d) => {
                  d.value = d.value.toString().replace(/,/g, '')
                  if (isNaN(Number(d.value))) {
                  } else {
                    if (d.value.split('.').length != 1)
                      if (d.value.split('.')[1].length > 2) {
                        return;
                      }
                    this.handlerModalInputChange(e, d)
                  }
                }} />
              {this.state.show_safe && <div>
                <label style={{ color: this.state.cash > 0 ? 'green' : this.state.cash < 0 ? 'red' : 'inherit' }}>Cashier: {Utility.priceFormat(this.state.cash)}</label><br />
                <label style={{ color: this.state.safe_all > 0 ? 'green' : this.state.safe_all < 0 ? 'red' : 'inherit' }}>เซฟ: {Utility.priceFormat(this.state.safe_all)}</label><br />
                {this.state.safe_bill !== 0 && <><label style={{ color: this.state.safe_bill > 0 ? 'green' : this.state.safe_bill < 0 ? 'red' : 'inherit' }}>เซฟสาขา - ซื้อขาย: {Utility.priceFormat(this.state.safe_bill)}</label><br /></>}
                {this.state.safe_lease !== 0 && <><label style={{ color: this.state.safe_lease > 0 ? 'green' : this.state.safe_lease < 0 ? 'red' : 'inherit' }}>เซฟสาขา - ขายฝาก: {Utility.priceFormat(this.state.safe_lease)}</label></>}
              </div>}
            </Form.Field>}


            {['CD', 'CC', 'CDQ', 'CCQ'].includes(this.state.ledger.payment) &&
              <Form.Field >
                <label>ค่าธรรมเนียม {this.state.ledger.card_bank_card != '' ? Utility.priceFormat(this.state.ledger.card_fee) + "%" : ''}</label>
                <Input
                  id='service'
                  readOnly={true}
                  className='text-right'
                  disabled={this.state.ledger.card_bank_card == ''}
                  value={Utility.priceFormat(this.state.ledger.card_service)}
                />
              </Form.Field>
            }
            {['CD', 'CC', 'CSQ', 'CDQ', 'CCQ'].includes(this.state.ledger.payment) &&
              <Form.Field>
                <label>ยอดจ่ายรวม</label>
                <Input
                  id='total'
                  readOnly
                  className='text-right'
                  value={Utility.priceFormat(this.state.total)} />
              </Form.Field>

            }

            <Form.Field error={this.state.msg_error.description != null}>

              <label>*หมายเหตุ <MsgInput text={this.state.msg_error.description} /></label>
              <TextArea
                id='ex'
                ref={(input) => { this.textInput = input; }}
                placeholder='หมายเหตุ'
                search selection
                name="description"
                maxLength={255}
                value={this.state.ledger.description}
                onChange={this.handlerModalInputChange} />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button id='confirmIn-Out' size='small' primary loading={this.state.loadingSubmitledger} onClick={this.handleSubmitledger}>
            บันทึก
          </Button>

        </Modal.Actions>
      </Modal>
    </div>)
  }
}

const mapStateToProps = state => {
  return ({
    auth: state.auth,
    branches: state.branches,
    ledger_category: state.ledger_category
  })
}

export default connect(
  mapStateToProps
)(LedgerForm)