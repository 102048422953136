/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from "react";
import {
  Form,
  Header,
  Button,
  Grid,
  Modal,
  Icon,
  Dimmer,
  Loader,
  Input,
} from 'semantic-ui-react';
import { hotkeys } from 'react-keyboard-shortcuts'
import moment from 'moment';
import 'react-dates/lib/css/_datepicker.css';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import BillInfo from './BillInfo';
import BillItem from './BillItem';
import PaymentModal from '../../Payment/PaymentModal';
import ProductSelect from './ProductSelect';
import InvoicePrintPreview from '../InvoicePrintPreview'
import dateFormat from 'dateformat';
import { connect } from 'react-redux';
import QrcodeModal from './QrcodeModal';
import MsgInput from '../../Error/MsgInput'
import AccountDetail from '../../Savings/AccountDetail'
import { get_noti } from '../../../actions';
import ExchangeSelect from './ExchangeSelect'
import InvoiceOldPrintPreview from '../InvoiceOldPrintPreview'

class Bill extends Component {
  constructor(props) {
    super(props);
    this.code_change_number = {};
    this.state = {
      bill_number: "",
      productCode: "",
      productName: "",
      productType: "",
      productPrice: "",
      tray: "",
      amount: "",
      sellWeight: "",
      realWeight: "",
      unitPrice: "",
      sellPrice: "",
      fee: "",
      score: "",
      billNumber: "",
      portCode: "",
      status: "",
      products: [],
      prices: {},
      weights: [],
      staffs: [],
      billItems: [],
      billSummary: {},
      billNo: "",
      customers: [],
      customerCode: "",
      customerName: "",
      customerPhone: "",
      customerSuper: false,
      bill_date: moment(),
      saleList: "",
      approver: "",
      loader: false,
      bill_saved: false,
      customer_id: "",
      bill_id: "",
      bill_staff: [],
      billItemsNoGroup: [],
      description: "",
      loading_bill_detail: false,
      gold_price_value: '',
      gold_price_period: [],
      msg_error: {},
      used: 0,
      stock: [],
    };

    this.resetBillForm = this.resetBillForm.bind(this);
    this.submitAddOrder = this.submitAddOrder.bind(this);
    this.amountChangeHandler = this.amountChangeHandler.bind(this);
    this.sellPriceChangeHandler = this.sellPriceChangeHandler.bind(this);
    this.feeChangeHandler = this.feeChangeHandler.bind(this);
    this.customerChangeHandler = this.customerChangeHandler.bind(this);
    this.GetQueue = this.GetQueue.bind(this)

    this.selectSaleHandler = this
      .selectSaleHandler
      .bind(this);
    this.selectApproverHandler = this
      .selectApproverHandler
      .bind(this);
    this.usedChange = this
      .usedChange
      .bind(this);
    this.setStock = this
      .setStock
      .bind(this);
    this.submitBillHandler = this
      .submitBillHandler
      .bind(this);
    this.submitBillHandlerV3 = this
      .submitBillHandlerV3
      .bind(this);
    this.saveBill = this
      .saveBill
      .bind(this);
    this.resetBill = this
      .resetBill
      .bind(this)
    this.deleteItem = this
      .deleteItem
      .bind(this)
    this.onEditChange = this
      .onEditChange
      .bind(this)
    this.handlerVoidBill = this.handlerVoidBill.bind(this)
    this.handlerUpdateStock = this.handlerUpdateStock.bind(this)
    this.handlerSaveDescription = this.handlerSaveDescription.bind(this)
    this.goldPriceChangeHandler = this.goldPriceChangeHandler.bind(this)
    this.submitChangeGoldPrice = this.submitChangeGoldPrice.bind(this)
    this.resetChangeGoldPriceForm = this.resetChangeGoldPriceForm.bind(this)
  }

  deleteItem(e, i) {
    let billItems = this.state.billItems;

    var id_change = billItems[i].id_change;

    // item1
    billItems.map((item, i) => {
      if (item.id_change == id_change) {
        billItems.splice(i, 1);
      }
    });

    // item2
    billItems.map((item, i) => {
      if (item.id_change == id_change) {
        billItems.splice(i, 1);
      }
    });

    this.setBillItem(billItems);
  }

  resetBillForm(value) {
    this.setState({
      productCode: "",
      productName: "",
      productType: "",
      productPrice: "",
      tray: "",
      amount: "",
      sellWeight: "",
      realWeight: "",
      unitPrice: "",
      sellPrice: "",
      fee: "",
      score: "",
      billNumber: "",
      portCode: "",
      status: "",
      bill_id: "",
      loader: false,
    });
  }

  resetBill(e) {
    e.preventDefault();
    this.resetBillForm();

    const summary = {
      itemCount: 0,
      total_buy_weight: 0,
      total_sell_weight: 0,
      net_price: 0,
      total_sell_price: 0,
      total_buy_price: 0,
    };
    this.setState({
      billItems: [],
      customer_id: 1,
      billSummary: summary,
      bill_saved: false,
    });

    this.genBillNumber();
  }

  getProductByCode(id) {
    let categories = this.categories.filter((o) => {
      return o.id == id;
    });
    return categories[0];
  }

  submitAddOrder(e, data) {
    if (this.state.bill_saved) {
      alert("บิลนี้ถูกบันทึกเรียบร้อยแล้ว กรุณาเริ่มรายการใหม่");
    }

    let billItems = this.state.billItemsNoGroup;
    if (data.sell != "") {
      // let p = Utility.getObject(this.products_list, data.sell.product_id);
      billItems.push({
        type_sale: data.sell.type_sale,
        type_title: "ขาย",
        type: "SE",
        id: billItems.length + 1,
        amount: data.sell.amount,
        sell_price: data.sell.sell_price,
        sell_weight: data.sell.product.weight,
        sell_weight_real: data.sell.sell_weight_real_g,
        buy_price: 0,
        buy_weight: 0,
        gold_price: data.sell.gold_price,
        product_code: data.sell.product.code,
        product_id: data.sell.product.id,
        product_name: data.sell.product.name,
        product_type: data.sell.product.kind.name,
        category_name: data.sell.product.category.name,
        category_id: data.sell.product.category.id,
        id_change: data.sell.id_change,
        exchange: data.sell.exchange,
        cost: data.sell.product_price,
        sales_force: data.sell.fee
          ? data.sell.sell_price - data.sell.product_price * data.sell.amount
          : 0,
        qrcode: data.sell.qrcode,
        fee: data.sell.fee,
        exchange_diff: data.sell.exchange_diff,
        total_pay: data.sell.total_pay
      })
    }

    if (data.buy != "") {
      billItems.push({
        type_title: "ซื้อ",
        type: "BU",
        id: billItems.length + 1,
        amount: 1,
        sell_price: 0,
        sell_weight: 0,
        sell_weight_real: 0,
        buy_price: data.buy.buy_price,
        buy_weight: data.buy.buy_weight_real_g,
        gold_price: data.buy.gold_price,
        product_code: "",
        product_name: data.buy_name,
        product_type: "",
        category_name: data.buy.category_name,
        category_id: data.buy.category_id,
        id_change: data.buy.id_change,
        buy_name: data.buy.buy_name,
        exchange: data.buy.exchange,
        sales_force: 0,
        cost: 0,
        qrcode: data.buy.qrcode,
        is_price_aag: data.buy.is_price_aag,
        exchange_diff: data.buy.exchange_diff,
        total_pay: data.buy.total_pay,
        staff_product_stock: data.buy.staff_product_stock,
      })
    }

    this.setBillItem(billItems);
  }

  setBillItem(billItems) {
    let total_sell_price = 0;
    let total_buy_price = 0;
    let total_sell_weight = 0;
    let total_buy_weight = 0;
    let total_exchange = 0
    let total_exchange1 = 0
    let total_exchange_price = 0
    let product_group = {}
    let total_fee = 0
    let ex_diff = 0
    let it = []

    for (let i = 0; i < billItems.length; i++) {
      if (billItems[i].sell_price != "") {
        total_sell_weight += Number(billItems[i].sell_weight_real);
        if (this.props.kind == 'XC') {
          total_sell_price += Number(billItems[i].sell_price) - Number(billItems[i].fee)
        } else {
          total_sell_price += Number(billItems[i].sell_price * billItems[i].amount);
        }
      }
      if (billItems[i].buy_price != "") {
        total_buy_price += Number(billItems[i].buy_price);
        total_buy_weight += Number(billItems[i].buy_weight);
      }

      const _exchange = parseFloat(billItems[i].exchange)
      if (billItems[i].type == 'BU' && _exchange > 0) {
        total_exchange += _exchange
      }

      if (this.props.kind == 'XC') {
        ex_diff = Number(billItems[i].exchange_diff)
      }

      if (this.props.kind == 'XC' && billItems[i].type == 'SE') {
        billItems[i].exchange_price = parseFloat(billItems[i].sales_force) + parseFloat(billItems[i].exchange)
        total_exchange_price += billItems[i].exchange - billItems[i].sales_force
        total_fee += Number(billItems[i].fee)
        total_exchange1 = Number(billItems[i].exchange)
      }

      if (this.props.kind == 'XC' && billItems[i].type == 'BU') {
        billItems[i].exchange = 0
      }


      // group
      const key = this.props.kind + billItems[i].product_code + billItems[i].sell_price;
      if (
        billItems[i].type == "BU" ||
        this.props.kind == "XC" ||
        product_group[key] == null
      ) {
        if (billItems[i].type == "BU" || this.props.kind == "XC")
          product_group[billItems[i].id] = billItems[i];
        else {
          billItems[i].amount = parseInt(billItems[i].amount);
          product_group[key] = JSON.parse(JSON.stringify(billItems[i]));
        }
      } else {
        product_group[key].amount += parseInt(billItems[i].amount);
      }
    }

    let _billItems = []
    _billItems['total_amount'] = 0

    for (let k in product_group) {

      _billItems.push(product_group[k])
      _billItems['total_amount'] += product_group[k].amount
    }

    // let total_pay = Number(total_fee)+Number(total_exchange)+Number(ex_diff)
    let total_ex = this.state.bill_number == '' ? total_exchange : total_exchange1

    let d = parseFloat(total_buy_weight).toFixed(3) - parseFloat(total_sell_weight).toFixed(3)
    let s = Math.abs(parseFloat(d).toFixed(3))
    let weight_diff = Math.ceil((s) * 10) / 10;
    weight_diff = d < 0 ? (weight_diff * -1) : (weight_diff)

    const summary = {
      itemCount: _billItems.total_amount,
      total_buy_weight: Utility.weightFormat(total_buy_weight),
      total_sell_weight: Utility.weightFormat(total_sell_weight),
      // net_price:
      //   this.props.kind == "XC" ? total_exchange : total_sell_price - total_buy_price,
      // net_price:
      //   this.props.kind == "XC" ?  total_pay : total_sell_price - total_buy_price,
      net_price:
        this.props.kind == "XC" ? (total_exchange1 + total_fee) + ex_diff : total_sell_price - total_buy_price,
      total_sell_price: Utility.priceFormat(total_sell_price),
      total_buy_price: Utility.priceFormat(total_buy_price),
      total_exchange_price: this.props.kind == "XC" ? Utility.priceFormat(total_exchange) : Utility.priceFormat(total_exchange_price),
      total_fee: Utility.priceFormat(total_fee),
      total_exchange: this.props.kind == "XC" ? Utility.priceFormat(total_exchange1) : Utility.priceFormat(total_exchange),
      exchange_diff: Utility.priceFormat(ex_diff),
      sell_price_summary: Utility.priceFormat(Number(total_sell_price) + Number(total_fee)),
      received: Utility.priceFormat(total_exchange1 + total_fee),
      // received: Utility.priceFormat(pay_summary),
      weight_diff: weight_diff,
    }

    this.setState({
      billItems: _billItems,
      billItemsNoGroup: billItems,
      billSummary: summary,
    });
  }

  customerChangeHandler(value) {
    this.setState({
      customer_id: value.id,
      customerName: value.name,
      customerCode: value.code,
      customerPhone: value.phone,
      customerSuper: value.is_super || false,
    });
  }

  selectSaleHandler(value) {
    this.setState({ saleList: value });
  }

  selectApproverHandler(value) {
    this.setState({ approver: value });
  }

  usedChange(value) {
    this.setState({ used: value });
  }

  setStock(value) {
    this.setState({ stock: value })
  }

  saveBill(e) {
    e.preventDefault();

    const { branch } = this.props.auth;
    let net_price = Utility.removeCommas(this.state.billSummary.net_price);
    if (this.state.customer_id == "") {
      alert("กรุณาเลือกลูกค้า (พนักงาน)");
    } else if (this.state.billItems.length == 0) {
      alert("กรุณาเพิ่มรายการสินค้า");
      this.setState({ open: true });
    } else if (this.state.description.length > 255) {
      alert("ความยาวหมายเหตุต้องไม่เกิน 255 ตัวอักษร");
      // this.setState({ open: true })
    } else if (this.state.saleList == "") {
      alert("กรุณาเลือกพนักงานขาย");
    } else if (this.state.approver == "") {
      alert("กรุณาเลือกผู้รับรอง");
    } else if (net_price <= 0) {
      let data_savings = {
        savings: false,
      }
      this.submitBillHandlerV3({
        cash: -1 * net_price,
        total: -1 * net_price,
        change: 0,
        date: moment(),
        ledger_date: Utility.formatDate2(moment()),
        description: "",
        bank: "",
        card_code: "",
        card_fee: 0,
        bankcard: "",
        card_service: 0,
        card_period: 0,
        card_start: "",
        card_contract_number: 0,
        card_bank_card: "",
        check_code: "",
        check_total: 0,
        card_total: 0,
        payment: "CS",
        branch: branch.id,
        check_date: Utility.formatDate2(moment()),

      }, data_savings);
    } else {
      this.setState({ modal_open: true });
    }
  }

  async submitBillHandler(ledger, data_savings) {
    const { branch } = this.props.auth;
    let _billItems = this.state.billItemsNoGroup;
    let total_sell = 0;
    let total_buy = 0;
    let diff = 0;
    for (let i = 0; i < _billItems.length; i++) {
      total_sell += Number(_billItems[i].sell_price);
      total_buy += Number(_billItems[i].buy_price);
    }
    let kind_bill = "SE";
    ledger["ledger_category"] = 1;
    ledger["kind"] = "IN";
    if (this.props.kind == "BU") {
      if (!window.confirm("ยืนยันบันทึกและจ่ายเงิน")) return;
      kind_bill = "BU";
      ledger["kind"] = "EX";
      ledger["ledger_category"] = 2;
    } else if (this.props.kind == "XC") {
      kind_bill = "XC";
      ledger["kind"] = "IN";
      ledger["ledger_category"] = 3;
    }
    diff = total_sell - total_buy;

    let staffs = "";
    let saleList = this.state.saleList;
    if (saleList.length > 0) {
      staffs = JSON.stringify(saleList);
    }

    let total = this.state.billSummary.net_price;
    let total_exchange = this.props.kind == 'XC' ? this.state.billSummary.total_exchange : this.state.billSummary.net_price;
    let bill = {
      bill_number: this.state.bill_number,
      branch: branch.id,
      customer: this.state.customer_id,
      sell: total_sell,
      buy: total_buy,
      discount: 0,
      amount: _billItems.length,
      description: this.state.description == null ? "" : this.state.description,
      kind: kind_bill,
      total: total,
      bill_date: dateFormat(this.state.bill_date, "yyyy-mm-dd HH:MM:ss"),
      weight_buy: Utility.removeCommas(this.state.billSummary.total_buy_weight),
      // exchange: total_buy > 0 && total_sell > 0 ? this.props.kind == 'XC' ? Utility.removeCommas(total_exchange) : total : 0,
      exchange: this.props.kind == 'XC' ? Utility.removeCommas(total_exchange) : 0,
      staffs: staffs,
      approver: this.state.approver,
      gold_price: this.state.gold_price.id,
      payment: ledger.payment,
      exchange_diff: _billItems[0].exchange_diff,
      weight_diff: this.props.kind == 'XC' ? this.state.billSummary.weight_diff : 0,
    };

    this.setState({ loader: true });
    if (this.is_submit) return;
    this.is_submit = true;
    let can_submit_saving = data_savings.savings == true ? false : true

    if (data_savings.savings && (this.props.kind == "SE" || this.props.kind == "XC")) {

      const req = await Utility.getAsync(Settings.baseUrl + "/saving/" + data_savings.savings_id + '/')
      if (parseInt(req.data.balance) >= parseInt(data_savings.amount)) {
        can_submit_saving = true
      } else {
        let msg_error = ''
        if (can_submit_saving == false) {
          msg_error = 'ยอดเงินคงเหลือปัจจุบันเป็น ' + req.data.balance + ' กรุณาทำรายการใหม่อีกครั้ง เนื่องจากยอดเงินออมทองไม่เพียงพอ'
        }
        alert(msg_error)
        this.is_submit = false
        return {
          'error': msg_error
        }
      }

    }

    //check item in bill is available
    let qrcode_for_check = []
    let can_update_stock = (this.props.kind == "SE" || this.props.kind == "XC") ? false : true
    if (this.props.kind == "SE" || this.props.kind == "XC") {
      for (let i = 0; i < _billItems.length; i++) {
        qrcode_for_check.push(_billItems[i].qrcode)
      }

      const req = await Utility.getAsync(Settings.baseUrl + "/bill/check_available/" + '?qrcode=' + qrcode_for_check)

      if (!req.data.error) {
        can_update_stock = true
      } else {
        let msg_error = req.data.error
        alert(msg_error)
        this.is_submit = false
        return {
          'error': msg_error
        }
      }
    }

    if (can_submit_saving && can_update_stock) {
      Utility.post(Settings.baseUrl + "/bills/", bill, async (s, d) => {
        if (s) {
          this.setState({ bill_id: d.id });

          let bill_id = d.id;
          let bill_number = d.bill_number;
          for (let i = 0; i < _billItems.length; i++) {
            await this.submitItem(_billItems[i], bill_id, bill_number, this.props.kind == 'XC' ? total_exchange : total, kind_bill);
          }

          Utility.get(Settings.baseUrl + "/staff/bill/" + bill_id + "/stock_update/", (s, d2) => {
            this.setState({
              loader: false,
              modal_open: false,
              bill_saved: true,
              bill_number: d.bill_number,
            });
            if (!s) {
              alert(d2.error);
            } else {
              if (saleList.length > 0) {
                ledger["staff"] = saleList[0];
              }
              ledger["object_id"] = d.id;
              ledger["object_number"] = d.bill_number;
              delete ledger["date"];
              if (data_savings.savings && (this.props.kind == "SE" || this.props.kind == "XC")) {
                this.submitsaving(data_savings);
              }
              setTimeout(() => {
                this.submitledger(ledger);
              }, 50)
            }
          });
        } else {
          alert("ผิดพลาดกรุณาลองอีกครั้ง");
        }
      });
    }
    return { 'error': '' }

  }
  async submitBillHandlerV3(ledger, data_savings, point) {
    if (this.is_submit) return;
    this.is_submit = true;
    const { branch } = this.props.auth;
    let _billItems = this.state.billItemsNoGroup;
    let total_sell = 0;
    let total_buy = 0;
    let diff = 0;
    for (let i = 0; i < _billItems.length; i++) {
      total_sell += Number(_billItems[i].sell_price);
      total_buy += Number(_billItems[i].buy_price);
    }
    let kind_bill = "SE";
    ledger["ledger_category"] = 1;
    ledger["kind"] = "IN";
    if (this.props.kind == "BU") {
      if (!window.confirm("ยืนยันบันทึกและจ่ายเงิน")) return;
      kind_bill = "BU";
      ledger["kind"] = "EX";
      ledger["ledger_category"] = 2;
    } else if (this.props.kind == "XC") {
      kind_bill = "XC";
      ledger["kind"] = "IN";
      ledger["ledger_category"] = 3;
    }
    diff = total_sell - total_buy;

    let staffs = "";
    let saleList = this.state.saleList;
    ledger["staff"] = saleList[0];
    if (saleList.length > 0) {
      staffs = JSON.stringify(saleList);
    }

    let total = this.state.billSummary.net_price;
    let total_exchange = this.props.kind == 'XC' ? this.state.billSummary.total_exchange : this.state.billSummary.net_price;
    let bill = {
      bill_number: this.state.bill_number,
      branch: branch.id,
      customer: this.state.customer_id,
      sell: total_sell,
      buy: total_buy,
      discount: 0,
      amount: _billItems.length,
      description: this.state.description == null ? "" : this.state.description,
      kind: kind_bill,
      total: total,
      bill_date: dateFormat(this.state.bill_date, "yyyy-mm-dd HH:MM:ss"),
      weight_buy: Utility.removeCommas(this.state.billSummary.total_buy_weight),
      // exchange: total_buy > 0 && total_sell > 0 ? this.props.kind == 'XC' ? Utility.removeCommas(total_exchange) : total : 0,
      exchange: this.props.kind == 'XC' ? Utility.removeCommas(total_exchange) : 0,
      staffs: staffs,
      approver: this.state.approver,
      gold_price: this.state.gold_price.id,
      payment: ledger.payment,
      exchange_diff: _billItems[0].exchange_diff,
      weight_diff: this.props.kind == 'XC' ? this.state.billSummary.weight_diff : 0,
    };
    // this.setState({ loader: true });
    
    let can_submit_saving = data_savings.savings == true ? false : true
    let qrcode_for_check = []
    let databillItems = []
    if (this.props.kind == "SE" || this.props.kind == "XC") {
        for (let i = 0; i < _billItems.length; i++) {
          qrcode_for_check.push(_billItems[i].qrcode)
        }
    }
    for (let i = 0; i < _billItems.length; i++) {
      databillItems.push(_billItems[i])
    }
    console.log('ledger ===>' ,ledger);
    let postdata = {
      data_savings: data_savings,
      data_kind:this.props.kind,
      data_bills:bill,
      data_billItems : databillItems,
      qrcode_for_check:qrcode_for_check,
      data_ledger:ledger,
      approver:this.state.approver,
    }
    let can_update_stock = (this.props.kind == "SE" || this.props.kind == "XC") ? false : true
    this.setState({ loader: true });
    const req = await Utility.postJson(Settings.baseUrlV3 + "/poschecker/",postdata,async (s, d) => {
      if(d.status == "400"){
        let msg_error = d.message
        alert(msg_error)
      }else{
        this.GetQueue(d.data.qid,'Create')
        sessionStorage.setItem("QueuePosCreate", d.data.qid);
      }
      
    } )

  }
  GetQueue(id,mode) {
    setTimeout(() => {
        let url = Settings.baseUrlV3 + "/queue/"+id+"/"
        Utility.get(url, (e, res) => {
            if(res.result) {
                if(res.result.status == 0) {
                    if(mode == 'Create'){
                    this.GetQueue(id,mode)
                    }
                }else{
                    if(res.result.status == 1){
                        // this.props.onCreate(res.result.massage)
                        if(mode == 'Create'){
                            alert('บันทึกบิลและชำระเงินสำเร็จ!');
                            sessionStorage.removeItem("QueuePosCreate");
                            if(res.result.saving){
                              this.setState({ saving: res.result.saving, modal_saving: true })
                            }
                            this.is_submit = false
                            this.setState({
                              loader: false,
                              modal_open: false,
                              bill_saved: true,
                              bill_number: res.result.massage.number,
                              bill_id: res.result.massage.id,
                            });
                        } else {
                        }
                    }else{
                      if(mode == 'Create'){
                        alert('ชำระเงิน ผิดพลาดกรุณาลองอีกครั้ง');
                        sessionStorage.removeItem("QueuePosCreate");
                        this.setState({
                          loader: false,
                          modal_open: false,
                          bill_saved: true,
                          bill_number: '',
                        });
                        }
                    }
                }
            } else {
              this.setState({
                loader: false,
                modal_open: false,
                bill_saved: true,
                bill_number: '',
              });
            }
        })
        
    }, 1000);
}

  async submitsaving(data) {
    const { branch } = this.props.auth;
    let dt = {
      kind: this.props.kind == "SE" ? 1 : 2,
      object_id: this.state.bill_id,
      object_number: this.state.bill_number,
      branch: branch.id,
      staff: this.state.saleList[0],
      amount: Number(data.amount),
      remark: "",
    };
    let url = Settings.baseUrl + "/saving/" + data.savings_id + "/payment/";
    Utility.post(url, dt, (s, d) => {
      if (s) {
        let dt = {
          savings_item: d.saving_item,
          object_id: this.state.bill_id,
          kind: this.props.kind == "SE" ? 1 : 2,
        }
        this.submitsavinglog(dt, d.savings)
      } else {
        alert("ชำระเงิน ผิดพลาดกรุณาลองอีกครั้ง");
      }
    });
  }

  async submitledger(data) {
    if (data.total < 0) {
      data.total = data.total * -1;
    }
    delete data["date"];
    data["customer"] = this.state.customer_id;
    let url = Settings.baseUrl + "/ledger/";
    Utility.post(url, data, (s, d) => {
      if (s) {
        alert("บันทึกบิลและชำระเงินสำเร็จ");
        this.props.getNoti(this.props.auth.branch)
      } else alert("ชำระเงิน ผิดพลาดกรุณาลองอีกครั้ง");
    });
  }

  async submitsavinglog(data, saving) {

    let url = Settings.baseUrl + "/saving_payment/";
    Utility.post(url, data, (s, d) => {
      if (s) {
        if (saving != undefined) {
          this.setState({ saving: saving, modal_saving: true })
        }
      } else alert("ชำระเงิน ผิดพลาดกรุณาลองอีกครั้ง");
    });
  }

  async submitItem(d, bill_id, bill_number, diff) {
    let num = 1;
    if (this.code_change_number[d.id_change] == null) {
      this.code_change_number[d.id_change] =
        Object.keys(this.code_change_number).length + 1;
      num = this.code_change_number[d.id_change];
    } else {
      num = this.code_change_number[d.id_change];
    }
    let formData = {
      bill: bill_id,
      category: d.category_id,
      weight: d.type == "BU" ? d.buy_weight : d.sell_weight_real,
      amount: d.amount,
      sell: d.sell_price || 0,
      buy: d.buy_price,
      gold_price: Utility.removeCommas(d.gold_price),
      code_change: bill_number + "-" + num,
      // exchange: Utility.removeCommas(diff),
      exchange: d.exchange,
      kind: d.type,
      cost: d.cost,
      product_name: d.buy_name ? d.buy_name : "",
      qrcode: d.qrcode,
      detail: d.detail,
      fee: d.fee,
      is_price_aag: d.type != "BU" ? true : d.is_price_aag,
      exchange_diff: d.exchange_diff,
    };
    if (d.staff_product_stock) formData['staff_product_stock'] = d.staff_product_stock

    if (d.product_id) formData["product"] = d.product_id;


    const endpoint = Settings.baseUrl + "/bill_items/";
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var _formData = new FormData();
    for (let k in formData) {
      _formData.append(k, formData[k]);
    }
    let options = {
      method: "POST",
      mode: "cors",
      headers: headers,
      body: Utility.jsonToQueryString(formData),
    };

    const rep = await Utility.postAsync(endpoint, formData);
    return rep.data;
  }

  async submitSale(staff_id, bill_id) {
    let formData = {
      bill: bill_id,
      staff: staff_id,
    };

    const endpoint = Settings.baseUrl + "/bill_staff/";
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    let options = {
      method: "POST",
      mode: "cors",
      headers: headers,
      body: Utility.jsonToQueryString(formData),
    };
    const response = await fetch(endpoint, options).catch((e) => {
    });
    return await response.json();
  }

  amountChangeHandler(value) {
    this.setState({
      amount: Number(value),
      sellPrice: Number(value) * this.state.productPrice,
    });
  }

  sellPriceChangeHandler(value) {
    this.setState({ sellPrice: Number(value) });
  }

  feeChangeHandler(value) {
    this.setState({ fee: Number(value) });
  }

  loadGoldPrice(bill_date) {
    let d = new Date(bill_date);
    let q = dateFormat(d, "yyyy-mm-dd HH:MM")
    let url = Settings.baseUrl + "/gold_price/?datetime=" + q + '&&is_period=true';
    this.setState({ is_loading: true })
    let gold_price_period = []
    Utility.get(url, (s, res) => {
      let text = ''

      for (let i in res) {
        if (this.props.kind == 'BU') {
          text = Utility.priceFormat(res[i].gold_bar_sell) + ' (' + 'ปัจจุบัน ' + Utility.formatTimeSecond(res[i].record_date) + ' น.' + ') '
        } else {
          text = Utility.priceFormat(res[i].gold_bar_buy) + ' (' + 'ปัจจุบัน ' + Utility.formatTimeSecond(res[i].record_date) + ' น.' + ') '
        }
        gold_price_period.push({ key: res[i].id, value: res[i].id, text: text })
      }

      if (s && res.length > 0) {
        this.setState({
          gold_price: res[0],
          gold_price_period: gold_price_period,
          gold_price_value: gold_price_period.length != 0 ? gold_price_period[0].value : 0,
          is_loading: false,
        })
      }
    });
  }

  componentDidMount() {
    const { branch } = this.props.auth;
    this.loadGoldPrice(this.state.bill_date);

    // Utility.get(Settings.baseUrl + "/products_data/", (e, resJson) => {
    //   // this.products_list = resJson;
    //   let products = [];
    //   for (let i in resJson) {
    //     products.push({
    //       value: resJson[i].id,
    //       key: resJson[i].id,
    //       text: resJson[i].name + " " + resJson[i].code,
    //       content: (
    //         <Header as="h4" content={resJson[i].name} subheader={resJson[i].code} />
    //       ),
    //     });
    //   }

    //   this.setState({ product_options: products });
    // });

    Utility.get(Settings.baseUrl + "/categories/", (e, resJson) => {
      this.categories = resJson;
      let categories = [];
      for (let i in resJson) {
        categories.push({
          value: resJson[i].id,
          key: resJson[i].id,
          text: resJson[i].name,
        });
      }
      this.setState({ categories: categories });
    });

    Utility.get(Settings.baseUrl + "/product_types/", (e, resJson) => {
      this.product_types = resJson;
      let product_types = [];
      for (let i in resJson) {
        product_types.push({
          value: resJson[i].id,
          key: resJson[i].id,
          text: resJson[i].name,
        });
      }
      this.setState({ product_types_option: product_types });
    });
    this.setState({ st: true });
    this.setState({ ap: true });
    Utility.get(
      Settings.baseUrl + "/staffs/?is_enabled=1&branch=" + branch.id,
      (e, resJson) => {
        this.staffs = resJson;
        let staffs = [];

        for (let i in resJson) {
          staffs.push({ value: resJson[i].id, key: resJson[i].id, text: resJson[i].name });
        }
        this.setState({ st: false });
        this.setState({ staffs: staffs });
      }
    );

    Utility.get(Settings.baseUrl + "/banks/?is_enabled=1", (e, resJson) => {
      this.banks = resJson;
      let banks = [];
      for (let i in resJson) {
        banks.push({ value: resJson[i].id, key: resJson[i].id, text: resJson[i].name });
      }
      this.setState({ banks: banks });
    });

    Utility.get(Settings.baseUrl + "/bank_cards/?is_enabled=1", (e, resJson) => {
      this.bank_cards = resJson;
      let bank_cards = [];
      for (let i in resJson) {
        bank_cards.push({
          value: resJson[i].id,
          key: resJson[i].id,
          text: resJson[i].kind + " " + resJson[i].bank.name,
        });
      }
      this.setState({ bank_cards: bank_cards });
    });

    Utility.get(
      Settings.baseUrl + "/approver/?is_enabled=1",
      (e, resJson) => {
        this.approvers = resJson;
        let approvers = [];

        for (let i in resJson) {
          approvers.push({ value: resJson[i].id, key: resJson[i].id, text: resJson[i].name });
        }
        this.setState({ ap: false });
        this.setState({ approvers: approvers });
      }
    );

    if (this.props.action == "edit") {
      let bill = this.props.bill;
      //bill.bill_date = dateFormat(bill.bill_date, "dd/mm/yyyy HH:MM")
      bill.customer_id = bill.customer.id
      bill.bill_id = bill.id
      this.setState(bill)
      this.loadBillDetail()
    }

    setTimeout(() => {
      let width_table = document.getElementById("width_table");
      if (width_table) this.setState({ width_table: width_table.clientWidth });
    }, 800);
    this.is_submit = false
    let QCreate = sessionStorage.getItem("QueuePosCreate");
    if (QCreate != null){
      this.setState({ loader: true });
        this.GetQueue(QCreate,'Create')
    }
    
  }

  loadBillDetail() {
    let bill_id =
      this.props.bill.id == undefined || this.props.action != "edit"
        ? this.state.bill_id
        : this.props.bill.id;
    let url = Settings.baseUrl + "/bill/" + bill_id + "/items/";
    this.setState({ loading_bill_detail: true });
    Utility.get(url, (e, res) => {
      this.bill_item = res;
      this.setItemBill(res);
      this.setState({ description: res[0].bill.description, loading_bill_detail: false });
    });

    url = Settings.baseUrl + "/bill_approver/" + bill_id + "/";
    this.setState({ loading_bill_detail: true });
    Utility.get(url, (e, res) => {
      if (res.approver) {
        this.setState({ approver: res.approver })
      }
    });

    url = Settings.baseUrl + "/bill_staff/?bill_id=" + bill_id;
    Utility.get(url, (e, res) => {
      let bill_staff = [];
      for (let i in res) {
        bill_staff.push(res[i].staff.id);
      }
      this.setState({
        saleList: bill_staff,
      });
    });
  }

  setItemBill(items) {
    let billItems = [];

    for (let i in items) {
      let data = items[i];

      let code_change = data.code_change;
      if (data.kind == "SE") {
        let p = data.product;
        billItems.push({
          type_title: "ขาย",
          type: "SE",
          id: billItems.length + 1,
          status_stock_display: data.status_stock_display,
          amount: data.amount,
          sell_price: data.sell,
          sell_weight: p.weight,
          sell_weight_real: data.weight,
          buy_price: 0,
          buy_weight: 0,
          gold_price: data.gold_price,
          product_code: p.code,
          product_id: p.id,
          product_name: p.name,
          product_type: p.kind.name,
          category_name: p.category.name,
          category_id: p.category.id,
          code_change: code_change,
          exchange: data.exchange,
          cost: data.cost,
          sales_force: data.fee,
          exchange_diff: data.bill.exchange_diff,
          fee: data.fee,
        })
      } else if (data.kind == "BU") {
        billItems.push({
          type_title: "ซื้อ",
          type: "BU",
          id: billItems.length + 1,
          status_stock_display: data.status_stock_display,
          amount: 1,
          sell_price: 0,
          sell_weight: 0,
          sell_weight_real: 0,
          buy_price: data.buy,
          buy_weight: data.weight,
          gold_price: data.gold_price,
          product_code: "",
          product_name: "",
          product_type: "",
          category_name: data.category.name,
          category_id: data.category.id,
          code_change: code_change,
          buy_name: data.product_name,
          exchange: data.exchange,
          sales_force: 0,
          cost: 0,
          qrcode: data.qrcode,
          exchange_diff: data.bill.exchange_diff,
          fee: data.fee,
        });
      }
    }

    this.setBillItem(billItems);
  }

  handlerUpdateStock(data) {
    if (window.confirm("ยืนยันอัพเดทสต็อก")) {
      Utility.get(Settings.baseUrl + "/staff/bill/" + data.id + "/stock_update/", (s, d2) => {
        if (!s) {
          alert(d2.error);
        } else {
          alert("อัพเดทสต็อกสำเร็จ");
          this.componentDidMount();
        }
      });
    }
  }

  async handlerVoidBill(data) {
    const { branch } = this.props.auth;

    if (this.state.is_void == 1) {
      alert("ถูกลบแล้ว");
      return;
    }

    let description = prompt("กรุณาระบุหมายเหตุ");
    if (description == null || description == "") {
      return;
    }
    const data_ = { description: description };

    if (description.length > 255) {
      alert("ความยาวหมายเหตุต้องไม่เกิน 255 ตัวอักษร");
      return;
    }
    if (!window.confirm('ยืนยันยกเลิกบิล!'))
      return
    if (this.state.description != "") {
      description = this.state.description + " " + description;
    }
    const _data = {
      description: description,
      customer: this.state.customer_id
    };

    let urlput = Settings.baseUrl + "/bills/" + this.state.id + "/";
    let urlget = Settings.baseUrl + "/bill/" + this.state.id + "/void/";

    const bill_res = await Utility.postAsync(urlget, data_);
    if (bill_res.status_code == 403) {
      alert('สิทธิ์ superadmin/admin/manager เท่านั้นที่สามารถยกเลิกบิลได้')
    } else if (bill_res.status_code == 200) {
      if (bill_res.data.status == false) {
        alert(bill_res.data.error);
        this.componentDidMount();
      } else {
        const bill_res = await Utility.patchAsync(urlput, _data);
        alert("ยกเลิกบิลสำเร็จ");
        this.props.getNoti(this.props.auth.branch)
        this.componentDidMount();
        this.setState({
          is_void: 1,
        });
      }
    } else if (bill_res.data.error) {
      alert(bill_res.data.error);
    }
  }

  onEditChange(value) {
    let _billItems = this.state.billItems;

    for (let b in _billItems) {
      _billItems[b].exchange = value;
    }

    const summary = this.state.billSummary;
    summary.net_price = value;
    this.setState({ billItems: _billItems, billSummary: summary });
  }

  async handlerSaveDescription() {
    if (this.state.description.length > 255) {
      alert("ความยาวหมายเหตุต้องไม่เกิน 255 ตัวอักษร");
      return;
    }
    const _data = {
      description: this.state.description,
      customer: this.state.customer_id
    }

    let urlput = Settings.baseUrl + "/bills/" + this.state.bill_id + "/";

    const bill_res = await Utility.patchAsync(urlput, _data);

    if (bill_res.status_code == 200) {
      this.loadBillDetail()
      alert('บันทึกข้อมูลสำเร็จ!')
    } else {
      if (bill_res.data.error) {
        alert(bill_res.data.error);
      } else
        alert("เกิดข้อผิดพลาด");
    }
  }

  resetChangeGoldPriceForm() {
    this.setState({
      username: '',
      password: '',
      msg_error: {},
    })
  }

  goldPriceChangeHandler(value) {
    if (value == this.state.gold_price_value) {
      return;
    }

    this.setState({
      // gold_price_value: value,
      // gold_price_value:this.state.gold_price_value,
      gold_price_modal: true,
      gold_price_change_id: value
    })

  }

  async submitChangeGoldPrice() {
    let data = {
      branch: this.props.auth.branch.id,
      gold_price: this.state.gold_price_change_id,
      kind: 1,// POS_CHANGE_GOLD_PRICE
      username: this.state.username,
      password: this.state.password,
      user: this.props.auth.user.id,
    }

    let msg_error = []
    if (this.state.username == '' || this.state.username == null) {
      msg_error.username = '*กรุณากรอก username'
    }
    if (this.state.password == '' || this.state.password == null) {
      msg_error.password = '*กรุณากรอก password'
    }
    if (Object.keys(msg_error).length > 0) {
      this.setState({ msg_error: msg_error })
      return;
    }

    Utility.post(Settings.baseUrl + "/pos_change_gold_price/", data, async (s, d) => {

      if (s) {
        alert('เปลี่ยนราคาทองสำเร็จ')
        this.resetChangeGoldPriceForm()
        this.setState({
          gold_price_modal: false,
          gold_price: d,
          gold_price_value: this.state.gold_price_change_id,
        })
      } else {
        this.resetChangeGoldPriceForm()
        alert(d.error)
      }
    });
  }

  render() {
    const { role } = this.props.auth;

    let color = "green";
    let title = "";
    let button = "";
    switch (this.props.kind) {
      case "SE":
        color = "green";
        title = "ขายทอง (พนักงาน)";
        button = "บันทึกและชำระเงิน";
        break;
      case "XC":
        color = "yellow";
        title = "เปลี่ยนทอง (พนักงาน)";
        button = "บันทึกและชำระเงิน";
        break;
      case "BU":
        color = "red";
        title = "ซื้อทอง (พนักงาน)";
        button = "บันทึกและจ่ายเงิน";
        break;
    }

    return (
      <Modal size="large" open={true} /*dimmer='blurring'*/>
        <Button
          id="btnClose"
          circular
          icon="close"
          basic
          floated="right"
          name=""
          onClick={this.props.onClose}
        />
        <Modal.Header id="headerModalPOS">
          <Header as="h4">
            <Icon name="file alternate" color={color} /> {title}{" "}
            {this.state.is_void ? <b>(ถูกยกเลิกแล้ว)</b> : ""}
            {this.state.bill_number != "" && (
              <span>
                {" "}
                [<b>บิลเลขที่</b> {this.state.bill_number}]
              </span>
            )}
          </Header>
        </Modal.Header>
        <Modal.Content id="contentModalPOS">
          {this.state.modal_open && (
            <PaymentModal
              total={this.state.billSummary.net_price}
              customer={this.state.customer_id}
              onClose={() => this.setState({ modal_open: false })}
              onSubmit={this.submitBillHandlerV3}
              loading={this.state.loader}
            />
          )}
          <Dimmer active={this.state.is_loading||this.state.loader} inverted>
            <Loader inverted content="Loading" />
          </Dimmer>
          <div>
            <Grid>
              <Grid.Row>
                <Grid.Column width={4}>
                  <BillInfo
                    gold_price_period={this.state.gold_price_period}
                    gold_price_value={this.state.gold_price_value}
                    onGoldPriceChange={this.goldPriceChangeHandler}
                    bill_number={this.state.bill_number}
                    bill_date={this.state.bill_date}
                    customer_id={this.state.customer_id}
                    onBillDateChange={(date) => {
                      this.setState({ bill_date: date });
                      this.loadGoldPrice(date);
                    }}
                    kind={this.props.kind}
                    onCustomerChange={this.customerChangeHandler}
                    loading={this.state.st || this.state.ap}
                    staffs={this.state.staffs}
                    approvers={this.state.approvers}
                    saleList={this.state.saleList}
                    approver={this.state.approver}
                    description={this.state.description}
                    onSelectSale={this.selectSaleHandler}
                    onSelectApprover={this.selectApproverHandler}
                    usedChange={this.usedChange}
                    setStock={this.setStock}
                    is_void={this.state.is_void}
                    role={role}
                    action={this.props.action}
                    is_disable={this.state.bill_number !== ""}
                    ondescription={(text) => {
                      this.setState({ description: text });
                    }}
                    billItems={this.state.billItems}
                  />
                </Grid.Column>
                <Grid.Column width={12}>
                  <div id="width_table">
                    <Form size="small">
                      <Form.Group>
                        <Form.Field width={6}>
                          <Header floated="left" as="h3">
                            รายการสินค้า
                          </Header>
                        </Form.Field>
                        <Form.Field id="addstock" width={16}>
                          <Button
                            id="btnAddProduct"
                            content={this.props.kind == "XC" ? "ลงรายการสินค้า (F1)" : "เพิ่มรายการสินค้า (F1)"}
                            onClick={(e) => {
                              if (this.state.customer_id == "") {
                                alert("กรุณาเลือกลูกค้า(พนักงาน)");
                              } else {
                                this.setState({ open: true });
                              }
                            }}
                            disabled={this.state.bill_number !== "" || (this.props.kind == 'XC' && this.state.billItems.length > 0)}
                            floated="right"
                            icon="plus"
                            labelPosition="left"
                            type="button"
                            color="green"
                          />
                        </Form.Field>
                      </Form.Group>
                    </Form>

                    {this.state.open ?
                      this.props.kind == "XC" ?
                        <ExchangeSelect
                          used={this.state.used}
                          stock={this.state.stock}
                          used_this={this.state.billSummary.total_sell_weight ? this.state.billSummary.total_sell_weight : 0}
                          onClose={() => {
                            this.setState({ open: false });
                          }}
                          open={true}
                          gold_price={this.state.gold_price}
                          formValue={this}
                          // products={this.props.products}
                          onSubmit={this.submitAddOrder}
                          billItems={this.state.billItemsNoGroup}
                          is_super={this.state.customerSuper}
                        /> :
                        <ProductSelect
                          stock={this.state.stock}
                          used_this={this.state.billSummary.total_sell_weight ? this.state.billSummary.total_sell_weight : 0}
                          customer={this.state.customerName}
                          used={this.state.used}
                          product_types_option={this.state.product_types_option}
                          onClose={() => {
                            this.setState({ open: false });
                          }}
                          gold_price={this.state.gold_price}
                          kind={this.props.kind}
                          open={true}
                          formValue={this}
                          // products_list={this.products_list}
                          onReset={this.resetBillForm}
                          onNameChange={this.nameChangeHandler}
                          onAmountChange={this.amountChangeHandler}
                          onSellPriceChange={this.sellPriceChangeHandler}
                          onFeeChange={this.feeChangeHandler}
                          billItems={this.state.billItemsNoGroup}
                          onSubmit={this.submitAddOrder}
                          is_super={this.state.customerSuper}
                        // products={this.props.products}
                        />
                      : (
                        ""
                      )}

                    <Modal open={this.state.gold_price_modal} size='tiny' /*dimmer='blurring'*/  >
                      <Button
                        id='btnCloseBank'
                        circular
                        icon='close'
                        basic
                        floated='right'
                        name=''
                        onClick={() => {
                          this.setState({ gold_price_modal: false })
                          this.resetChangeGoldPriceForm()
                        }} />
                      <Modal.Header>ยืนยันตัวตน</Modal.Header>
                      <Modal.Content>
                        <Modal.Description>
                          <Form size='small' autocomplete="off">
                            <Form.Field error={this.state.msg_error.username != null}>
                              <label>*username <MsgInput text={this.state.msg_error.username} /></label>
                              <Input id='username' onChange={(e) => this.setState({ username: e.target.value })} value={this.state.username} maxLength={255} />
                            </Form.Field>
                            <Form.Field error={this.state.msg_error.password != null}>
                              <label>*password <MsgInput text={this.state.msg_error.password} /></label>
                              <Input id='hidePassword' type='text' onChange={(e) => this.setState({ password: e.target.value })}
                                value={this.state.password} maxLength={255}
                              />
                            </Form.Field>
                          </Form>
                        </Modal.Description>
                      </Modal.Content>
                      <Modal.Actions>
                        <Button id='btnSaveBank' size='small' primary onClick={this.submitChangeGoldPrice}
                          className={this.state.button_class}>บันทึก</Button>
                        <Button id='btnCancelBank' size='small' onClick={(e) => { e.preventDefault(); this.setState({ gold_price_modal: false }); this.resetChangeGoldPriceForm() }}>ยกเลิก</Button>
                      </Modal.Actions>
                    </Modal>

                    <div>
                      <BillItem
                        is_delete={this.state.bill_number == ""}
                        billItems={this.state.billItems}
                        onDelete={this.deleteItem}
                        action={this.props.kind}
                        summary={this.state.billSummary}
                        width={this.state.width_table}
                        delete_button={true}
                        is_loading={this.state.loading_bill_detail}
                      />
                    </div>
                    <div>
                      <Form className='attached fluid' size='small' style={{ 'font-size': '13px' }}>
                        <Form.Group>
                          <Form.Input
                            label="จำนวนรายการ"
                            placeholder=""
                            className="text-right"
                            width={3}
                            value={this.state.billSummary.itemCount}
                            readOnly
                          />{" "}
                          {this.props.kind == "BU" || this.props.kind == "XC" ? (
                            ""
                          ) :
                            <Form.Input
                              label="ยอดรวมราคาขาย"
                              placeholder=""
                              className="text-right"
                              width={3}
                              value={this.state.billSummary.total_sell_price}
                              readOnly />
                          }
                          {this.props.kind == 'BU' || this.props.kind == "XC"
                            ? ""
                            : <Form.Input
                              label='รวมน้ำหนักขาย'
                              placeholder=''
                              className='text-right'
                              width={3}
                              value={this.state.billSummary.total_sell_weight}
                              readOnly />
                          }

                          {this.props.kind == 'SE' || this.props.kind == "XC"
                            ? ""
                            : <Form.Input
                              label='ยอดรวมราคาซื้อ'
                              placeholder=''
                              className='text-right'
                              width={3}
                              value={this.state.billSummary.total_buy_price}
                              readOnly />
                          }
                          {this.props.kind == 'SE' || this.props.kind == "XC"
                            ? ""
                            : <Form.Input
                              label='รวมน้ำหนักซื้อ'
                              placeholder=''
                              className='text-right'
                              width={3}
                              value={this.state.billSummary.total_buy_weight}
                              readOnly />
                          }
                          {/* {this.props.kind != 'XC'
                            ? ""
                            : <Form.Input
                              label='ค่าเปลี่ยน'
                              placeholder=''
                              className='text-right'
                              width={3}
                              value={this.state.billSummary.total_exchange_price}
                              readOnly />
                          } */}
                          {this.state.kind == 'SE' || this.state.kind == 'BU'
                            ? <Form.Input readOnly tabIndex={-1} width={2} transparent={true} />
                            : ''}
                          {this.props.kind == "XC" ? (
                            ""
                          ) : (
                              <Form.Input
                                label="ยอดรวมทั้งสิ้น"
                                placeholder=""
                                className="text-right"
                                width={3}
                                value={Utility.priceFormat(
                                  this.state.billSummary.net_price
                                )}
                                readOnly
                              />
                            )}
                        </Form.Group>
                        {this.props.kind == "XC" &&
                          <Form>
                            <Form.Group>
                              <Form.Input
                                label="ขายน้ำหนักรวม"
                                placeholder=""
                                className="text-right"
                                width={3}
                                value={
                                  this.state.billSummary.total_sell_weight
                                }
                                readOnly
                              />
                              <Form.Input
                                label="ราคารวมขาย"
                                placeholder=""
                                className="text-right"
                                width={3}
                                value={
                                  this.state.billSummary.total_sell_price
                                }
                                readOnly
                              />
                              <Form.Input
                                label="ค่ากำเหน็จรวม"
                                placeholder=""
                                className="text-right"
                                width={4}
                                value={
                                  this.state.billSummary.total_fee
                                }
                                readOnly
                              />
                              <Form.Input
                                label="สรุปราคาขาย"
                                placeholder=""
                                className="text-right"
                                width={3}
                                value={
                                  this.state.billSummary.sell_price_summary
                                }
                                readOnly
                              />
                              <Form.Input
                                label="เป็นเงินเปลี่ยน"
                                placeholder=""
                                className="text-right"
                                width={3}
                                value={
                                  this.state.billSummary.total_exchange
                                }
                                readOnly
                              />
                            </Form.Group>
                            <Form.Group>
                              <Form.Input
                                label="ซื้อน้ำหนักรวม"
                                placeholder=""
                                className="text-right"
                                width={3}
                                value={
                                  this.state.billSummary.total_buy_weight
                                }
                                readOnly
                              />
                              <Form.Input
                                label="ราคารวมซื้อ"
                                placeholder=""
                                className="text-right"
                                width={3}
                                value={
                                  this.state.billSummary.total_buy_price
                                }
                                readOnly
                              />
                              <Form.Field width={4}>
                                <label style={{ display: "flex" }}>เป็นเงินส่วนต่าง<p style={{ color: this.state.billSummary.weight_diff == undefined ? "black" : Math.abs(this.state.billSummary.weight_diff) > 0.3 ? "red" : "green", marginLeft: "2px" }}>({this.state.billSummary.weight_diff == undefined ? "" : Number(this.state.billSummary.weight_diff) <= 0 ? this.state.billSummary.weight_diff : "+" + this.state.billSummary.weight_diff} กรัม)</p></label>
                                <Input

                                  // label={this.state.billSummary.weight_diff != undefined ? "เป็นเงินส่วนต่าง("+this.state.billSummary.weight_diff+" กรัม)":"เป็นเงินส่วนต่าง( กรัม)"}
                                  placeholder=""
                                  className="text-right"
                                  width={4}
                                  value={
                                    this.state.billSummary.exchange_diff
                                  }
                                  readOnly
                                />
                              </Form.Field>
                              <Form.Input
                                label="เงินที่ได้รับ"
                                placeholder=""
                                className="text-right"
                                width={3}
                                value={this.state.billSummary.received}
                                readOnly
                              />
                              <Form.Input
                                label="สรุปยอดที่ต้องชำระ"
                                placeholder=""
                                className="text-right"
                                width={3}
                                value={Utility.priceFormat(
                                  this.state.billSummary.net_price
                                )}
                                readOnly
                              />
                            </Form.Group>
                          </Form>
                        }
                      </Form>
                    </div>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {(this.state.modal_saving) && (
              <AccountDetail
                items={this.state.saving}
                onClose={() => {
                  this.setState({ modal_saving: false });
                }}
                open={true}
              />
            )}
            {this.state.modal_open_inv ? (
              <InvoicePrintPreview
                onClose={() => {
                  this.setState({ modal_open_inv: false });
                }}
                bill_id={this.state.bill_id}
              />
            ) : (
                ""
              )}
            {this.state.modal_open_inv_old ? (
              <InvoiceOldPrintPreview
                onClose={() => {
                  this.setState({ modal_open_inv_old: false });
                }}
                bill_id={this.state.bill_id}
              />
            ) : (
                ""
              )}
            {this.state.open_print_qr ? (
              <QrcodeModal
                bill_id={this.state.bill_id}
                onClose={() => {
                  this.setState({ open_print_qr: false });
                }}
              // bill_id={this.state.bill_id}
              />
            ) : (
                ""
              )}
          </div>
        </Modal.Content>
        <Modal.Actions>
          {this.props.action == "edit" && (role == "A" || role == "M" || role == "S") ? (
            <Button
              color="red"
              size="small"
              icon="cancel"
              floated="left"
              disabled={this.state.is_void == 1}
              labelPosition="left"
              id="cancel-this"
              onClick={(e) => {
                this.handlerVoidBill(this.props.bill);
              }}
              content="ยกเลิกรายการนี้"
            />
          ) : (
              ""
            )}
          {this.props.action == "edit" && this.props.bill.status_stock == "N" ? (
            <Button
              color="yellow"
              size="small"
              icon="lightning"
              floated="left"
              disabled={this.state.is_void == 1}
              labelPosition="left"
              onClick={(e) => {
                this.handlerUpdateStock(this.props.bill);
              }}
              content="อัพเดทสต็อก"
            />
          ) : (
              ""
            )}

          <Button
            id="btnRefesh"
            size="small"
            icon="redo"
            labelPosition="left"
            onClick={() => {
              this.props.onReset(this.props.kind);
            }}
            content="เริ่มใหม่ (F5)"
          />

          {this.props.kind !== "SE" && (
            <Button
              id="qrcode"
              primary
              size="small"
              icon="qrcode"
              disabled={this.state.bill_number == "" || this.state.is_void == 1}
              onClick={(e) => {
                e.preventDefault();
                this.setState({ open_print_qr: true });
              }}
              content="พิมพ์ qrcode"
            />
          )}

          {this.props.kind == "SE" || this.props.kind == "XC" ? (
            <Button
              id="btnPrintVat"
              size="small"
              primary
              icon="print"
              labelPosition="left"
              disabled={this.state.bill_number == "" || this.state.is_void == 1}
              onClick={() => {
                if (this.state.bill_id) {
                  this.setState({ modal_open_inv: true });
                  // var tax = Utility.getFetch(
                  //   Settings.baseUrl + "/bill/" + this.state.bill_id + "/tax/"
                  // );
                  // Promise.all([tax]).then((values) => {
                  //   if (values[0].status) {
                  //     this.setState({ modal_open_inv: true });
                  //   } else {
                  //     alert(
                  //       "กรุณาแจ้ง manager /admin / superadmin ให้ตั้งค่าเลขที่เริ่มต้นใบกำกับภาษีของสาขา" +
                  //       values[0].branch +
                  //       "ให้ถูกต้อง"
                  //     );
                  //   }
                  // });
                }
              }}
              content=" พิมพ์ VAT (F8)"
            />
          ) : ""}
          {this.props.kind == "BU" || this.props.kind == "XC" ? (
            <Button
              size="small"
              primary
              icon="print"
              labelPosition="left"
              disabled={this.state.bill_number == "" || this.state.is_void == 1}
              onClick={() => {
                if (this.state.bill_id) {
                  this.setState({ modal_open_inv_old: true });
                }
              }}
              content=" พิมพ์ใบรับซื้อทองเก่า"
            />
          ) : ""}
          {this.props.action == "add" && this.state.bill_number == "" ? (
            <Button
              id="btnSaveandCheck"
              size="small"
              primary
              icon="payment"
              labelPosition="left"
              onClick={this.saveBill}
              disabled={this.state.bill_number !== ""}
              content={button + " (F2)"}
            />
          ) : (
              <Button
                id="btnSaveDescription"
                size="small"
                primary
                icon="save"
                labelPosition="left"
                onClick={this.handlerSaveDescription}
                disabled={
                  this.state.is_void == 1 || (role != "A" && role != "M" && role != "S")
                }
                content="บันทึก"
              />
            )}
        </Modal.Actions>
      </Modal>
    );
  }

  hot_keys = {
    f2: {
      priority: 3,
      handler: (event) => {
        event.preventDefault();
        if (this.state.bill_number == "") this.saveBill(event);
      },
    },
    enter: {
      priority: 3,
      handler: (event) => {
        event.preventDefault();
        if (this.state.bill_number == "" && !this.state.gold_price_modal) {
          this.saveBill(event);
        } else {
          this.submitChangeGoldPrice();
        }
      },
    },
    f1: {
      priority: 3,
      handler: (event) => {
        event.preventDefault();
        this.setState({ open: true });
      },
    },
    f5: {
      priority: 3,
      handler: (event) => {
        event.preventDefault();
        this.props.onReset(this.props.kind);
      },
    },
    f8: {
      priority: 3,
      handler: (event) => {
        event.preventDefault();
        if (this.state.bill_id) this.setState({ modal_open_inv: true });
      },
    },
    esc: {
      priority: 3,
      handler: (event) => {
        event.preventDefault();
        this.props.onClose(event);
      },
    },
  };
}

const mapDispatchToProps = (dispatch) => ({
  getNoti: branch => get_noti(dispatch, branch)
})

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(hotkeys(Bill));
