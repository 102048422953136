/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Loader, Dimmer, Modal, Table, Radio,
    Item
} from 'semantic-ui-react';

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'

class PrintPreview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: {
                branch: props.auth.branch == undefined ? '' : props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
                kind: 'ALL',
            },
            items: [],
            summary: null,
            overview: null,
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {

        var branches = Utility.getFetch(Settings.baseReportUrl + '/branches/?is_enabled=1');
        Promise.all([branches]).then((values) => {
            this.branches = values[0];
            let branches = []
            for (let i in this.branches) {
                branches.push({
                    value: this.branches[i].id,
                    key: this.branches[i].id,
                    text: this.branches[i].name
                });
            }

            this.setState({
                loader_active: false,
                branches: branches
            });
        });
        var url_string = window.location.toString();
        var url = new URL(url_string);
        var temp_start = url.searchParams.get("start_date");
        var temp_end = url.searchParams.get("end_date");
        if (temp_start !== null && temp_end !== null) {
            this.setState({
                search: {
                    start_date: moment(temp_start, 'DD/MM/YYYY'),
                    end_date: moment(temp_end, 'DD/MM/YYYY')
                }
            })
        }
    }

    resetForm(e) {

        this.setState({
            search: {
                branch: this.props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
                kind: 'ALL',
            }
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.start_date = Utility.formatDate2(search.start_date)
        search.end_date = Utility.formatDate2(search.end_date)
        let qrstring = Utility.jsonToQueryString(search)

        let date_before = new Date(search.start_date)
        date_before.setDate(date_before.getDate() - 1)
        date_before = Utility.formatDate2(date_before)

        this.setState({
            loading: true,
            items: [],
            summary: null,
            overview: null,
        })

        const safes = Utility.getFetch(Settings.baseReportUrl + '/cash-statement/?' + qrstring);
        const overview = Utility.getFetch(Settings.baseReportUrl + '/cash-overview/?' + qrstring);
        Promise.all([safes, overview]).then((values) => {
            const _items = values[0].data
            const _overview = (values[1].data || []).find(o => o.id === search.branch)
            let _in = 0
            let _ex = 0
            for (let i in _items) {
                _items[i].type_pay = Utility.getObjectByValue(Settings.payment_option, _items[i].payment).text
                if (_items[i].kind === 'IN') {
                    _in += Number(_items[i].cash)
                } else if (_items[i].kind === 'EX') {
                    _ex += Number(_items[i].cash)
                }
            }

            this.setState({
                loading: false,
                items: _items,
                summary: {
                    in: _in,
                    ex: _ex,
                },
                overview: ['ALL'].includes(search.kind) ? _overview : null,
            });
        });
    }

    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
    }

    render() {
        let title = 'รายงานธุรกรรมเงินสด';
        let filename = 'cash-statement-' + Utility.formatDate3(this.state.search.start_date) + '-' + Utility.formatDate3(this.state.search.end_date)

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace',
        };
        const textRight = {
            'text-align': 'right',
        }

        const textLeft = {
            'text-align': 'left',
        }
        const textCenter = {
            'text-align': 'center',
        }
        const textU = {
            'text-decoration': 'underline'
        }

        let branch_name = ''
        const { branches } = this.props
        if (this.state.search.branch) {
            let b = Utility.getObject(branches, this.state.search.branch)
            if (b)
                branch_name = b.name
        }

        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>{title}</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >

                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field>
                                <label>สาขา</label>
                                <DropdownBranch fluid size='small' selection onChange={this.handlerInput} name="branch" value={this.state.search.branch} />
                            </Form.Field>
                            <Form.Field >
                                <label>วันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.start_date}
                                    selected={this.state.search.start_date}
                                    maxDate={moment()}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        if (this.state.search.end_date < date) {
                                            this.handlerInput(null, {
                                                name: 'end_date',
                                                value: date
                                            });
                                        }
                                        this.handlerInput(null, {
                                            name: 'start_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>

                            <Form.Field >
                                <label>ถึงวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.end_date}
                                    selected={this.state.search.end_date}
                                    minDate={this.state.search.start_date}
                                    maxDate={moment()}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'end_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Form.Field
                                control={Radio}
                                label='ธุรกรรมทั้งหมด'
                                value='ALL'
                                checked={this.state.search.kind === 'ALL'}
                                onChange={(e, data) => {
                                    const search = this.state.search
                                    search.kind = data.value
                                    this.setState({ search });
                                }}
                            />
                            <Form.Field
                                control={Radio}
                                label='ธุรกรรมซื้อขาย'
                                value='BILL'
                                checked={this.state.search.kind === 'BILL'}
                                onChange={(e, data) => {
                                    const search = this.state.search
                                    search.kind = data.value
                                    this.setState({ search });
                                }}
                            />
                            <Form.Field
                                control={Radio}
                                label='ธุรกรรมขายฝาก'
                                value='LEASE'
                                checked={this.state.search.kind === 'LEASE'}
                                onChange={(e, data) => {
                                    const search = this.state.search
                                    search.kind = data.value
                                    this.setState({ search });
                                }}
                            />
                            <Form.Field
                                control={Radio}
                                label='ธุรกรรมออมทอง'
                                value='SAVING'
                                checked={this.state.search.kind === 'SAVING'}
                                onChange={(e, data) => {
                                    const search = this.state.search
                                    search.kind = data.value
                                    this.setState({ search });
                                }}
                            />
                            <Button type='button' onClick={(e) => {
                                this.setState({
                                    open: true
                                })
                                this.handlerSubmit(e)
                            }}>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='10'><center>{title} {this.state.search.kind === 'BILL' ? 'ซื้อขาย' : this.state.search.kind === 'LEASE' ? 'ขายฝาก' : this.state.search.kind === 'SAVING' ? 'ออมทอง' :'ทั้งหมด'}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='10'><center id='branch'>สาขา : {branch_name}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='10'><center id='date'>วันที่ : {Utility.formatDate(this.state.search.start_date)} ถึงวันที่ : {Utility.formatDate(this.state.search.end_date)} </center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell style={textCenter}>ลำดับ</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>วันที่/เวลา</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>เลขที่ใบสำคัญ</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>เลขที่อ้างอิง</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ประเภทบัญชี</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>รายการ</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>เงินสดรับ</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>เงินสดจ่าย</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ประเภทชำระ</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>หมวดหมู่</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    {this.state.overview && <>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell ><div className='text-center'>-</div></Table.Cell>
                                                <Table.Cell ><div className='text-center'></div></Table.Cell>
                                                <Table.Cell ><div className='text-left'>-</div></Table.Cell>
                                                <Table.Cell ><div className='text-left'>-</div></Table.Cell>
                                                <Table.Cell ><div className='text-center'>เซฟ</div></Table.Cell>
                                                <Table.Cell ><div className='text-left'>ยกยอด (ยอดคงเหลือเซฟ )</div></Table.Cell>
                                                <Table.Cell ><div className='text-right'>{Utility.priceFormat(this.state.overview.before_safe)}</div></Table.Cell>
                                                <Table.Cell ><div className='text-right'>-</div></Table.Cell>
                                                <Table.Cell ><div className='text-center'>{ }</div></Table.Cell>
                                                <Table.Cell ><div className='text-center'>{ }</div></Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell ><div className='text-center'>-</div></Table.Cell>
                                                <Table.Cell ><div className='text-center'></div></Table.Cell>
                                                <Table.Cell ><div className='text-left'>-</div></Table.Cell>
                                                <Table.Cell ><div className='text-left'>-</div></Table.Cell>
                                                <Table.Cell ><div className='text-center'>Cashier</div></Table.Cell>
                                                <Table.Cell ><div className='text-left'>ยกยอด (ยกยอดเงินสด Cashier)</div></Table.Cell>
                                                <Table.Cell ><div className='text-right'>{Utility.priceFormat(this.state.overview.before_cashier)}</div></Table.Cell>
                                                <Table.Cell ><div className='text-right'>-</div></Table.Cell>
                                                <Table.Cell ><div className='text-center'>{ }</div></Table.Cell>
                                                <Table.Cell ><div className='text-center'>{ }</div></Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </>}
                                    <Table.Body>
                                        {
                                            this.state.items.map((row, i) => <Table.Row key={i}>
                                                <Table.Cell ><div className='text-center'>{Utility.numberFormat(i + 1)}</div></Table.Cell>
                                                <Table.Cell ><div className='text-center'>{Utility.formatDate(row.record_date)} {Utility.formatTime(row.record_date)}</div></Table.Cell>
                                                <Table.Cell ><div className='text-left'>{row.number}</div></Table.Cell>
                                                <Table.Cell ><div className='text-left'>{row.object_number || '-'}</div></Table.Cell>
                                                <Table.Cell ><div className='text-center'>{row.is_safe ? 'เซฟ' : 'Cashier'}</div></Table.Cell>
                                                <Table.Cell ><div className='text-left'>{row.ledger_category__title}</div></Table.Cell>
                                                <Table.Cell ><div className='text-right'>{(row.kind === 'IN' && row.cash > 0) ? Utility.priceFormat(row.cash) : '-'}</div></Table.Cell>
                                                <Table.Cell ><div className='text-right'>{(row.kind === 'EX' && row.cash > 0) ? Utility.priceFormat(row.cash) : '-'}</div></Table.Cell>
                                                <Table.Cell ><div className='text-center'>{row.type_pay}</div></Table.Cell>
                                                <Table.Cell ><div className='text-center'>{row.safe_kind_display}</div></Table.Cell>
                                            </Table.Row>)
                                        }
                                    </Table.Body>
                                    {
                                        (this.state.summary && !this.state.overview) ? <Table.Body>
                                            <Table.Row><Table.Cell colSpan='10'></Table.Cell></Table.Row>
                                            <Table.Row>
                                                <Table.Cell colSpan='6'><div className='text-right'>รวมรายการ</div></Table.Cell>
                                                <Table.Cell ><div className='text-right'>{Utility.priceFormat(this.state.summary.in)}</div></Table.Cell>
                                                <Table.Cell ><div className='text-right'>{Utility.priceFormat(this.state.summary.ex)}</div></Table.Cell>
                                                <Table.Cell ></Table.Cell>
                                                <Table.Cell ></Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell colSpan='7'><div className='text-right'>คงเหลือ</div></Table.Cell>
                                                <Table.Cell ><div className='text-right'>{Utility.priceFormat(this.state.summary.in - this.state.summary.ex)}</div></Table.Cell>
                                                <Table.Cell ></Table.Cell>
                                                <Table.Cell ></Table.Cell>
                                            </Table.Row>
                                        </Table.Body> : null
                                    }
                                    {
                                        this.state.overview && <Table.Body>
                                            <Table.Row><Table.Cell colSpan='10'></Table.Cell></Table.Row>
                                            <Table.Row>
                                                <Table.Cell style={textRight}></Table.Cell>
                                                <Table.Cell style={textRight}>ยอดเงินรับ + ยกมา</Table.Cell>
                                                <Table.Cell style={textRight}>ยอดเงินจ่าย</Table.Cell>
                                                <Table.Cell style={textRight}>คงเหลือ</Table.Cell>
                                                <Table.Cell style={textRight}></Table.Cell>
                                                <Table.Cell style={textRight}></Table.Cell>
                                                <Table.Cell style={textRight}>ยอดเงินรับ</Table.Cell>
                                                <Table.Cell style={textRight}>ยอดเงินจ่าย</Table.Cell>
                                                <Table.Cell style={textRight}>คงเหลือ</Table.Cell>
                                                <Table.Cell ></Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell style={textRight}>ยอดรวม Cashier</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(this.state.overview.before_cashier + this.state.overview.cashier_sum.total_cash_in)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(this.state.overview.cashier_sum.total_cash_ex)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(this.state.overview.before_cashier + this.state.overview.cashier_sum.total_cash_in - this.state.overview.cashier_sum.total_cash_ex)}</Table.Cell>
                                                <Table.Cell style={textRight}></Table.Cell>
                                                <Table.Cell style={textRight}>รวมขายฝาก<sup>[1]</sup></Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(this.state.overview.cashier_lease.total_cash_in)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(this.state.overview.cashier_lease.total_cash_ex)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(this.state.overview.cashier_lease.total_cash_in - this.state.overview.cashier_lease.total_cash_ex)}</Table.Cell>
                                                <Table.Cell ></Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell style={textRight}>ยอดรวม เซฟ</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(this.state.overview.before_safe + this.state.overview.safe_sum.total_cash_in)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(this.state.overview.safe_sum.total_cash_ex)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(this.state.overview.before_safe + this.state.overview.safe_sum.total_cash_in - this.state.overview.safe_sum.total_cash_ex)}</Table.Cell>
                                                <Table.Cell style={textRight}></Table.Cell>
                                                <Table.Cell style={textRight}>รวมซื้อขาย<sup>[2]</sup></Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(this.state.overview.cashier_bill.total_cash_in)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(this.state.overview.cashier_bill.total_cash_ex)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(this.state.overview.cashier_bill.total_cash_in - this.state.overview.cashier_bill.total_cash_ex)}</Table.Cell>
                                                <Table.Cell ></Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell style={textRight}></Table.Cell>
                                                <Table.Cell style={textRight}></Table.Cell>
                                                <Table.Cell style={textRight}></Table.Cell>
                                                <Table.Cell style={textRight}></Table.Cell>
                                                <Table.Cell style={textRight}></Table.Cell>
                                                <Table.Cell style={textRight}>รวมออมทอง<sup>[3]</sup></Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(this.state.overview.cashier_saving.total_cash_in)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(this.state.overview.cashier_saving.total_cash_ex)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(this.state.overview.cashier_saving.total_cash_in - this.state.overview.cashier_saving.total_cash_ex)}</Table.Cell>
                                                <Table.Cell ></Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell style={textRight}></Table.Cell>
                                                <Table.Cell style={textRight}></Table.Cell>
                                                <Table.Cell style={textRight}></Table.Cell>
                                                <Table.Cell style={textRight}></Table.Cell>
                                                <Table.Cell style={textRight}></Table.Cell>
                                                <Table.Cell style={textRight}>รวมอื่นๆ + ยกมา<sup>[4]</sup></Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(this.state.overview.before_cashier + this.state.overview.before_safe + this.state.overview.cashier_all.total_cash_in + this.state.overview.safe_sum.total_cash_in)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(this.state.overview.cashier_all.total_cash_ex + this.state.overview.safe_sum.total_cash_ex)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(this.state.overview.before_cashier + this.state.overview.before_safe + (this.state.overview.cashier_all.total_cash_in - this.state.overview.cashier_all.total_cash_ex) + (this.state.overview.safe_sum.total_cash_in - this.state.overview.safe_sum.total_cash_ex))}</Table.Cell>
                                                <Table.Cell ></Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell style={textRight}></Table.Cell>
                                                <Table.Cell style={textRight}></Table.Cell>
                                                <Table.Cell style={textRight}></Table.Cell>
                                                <Table.Cell style={textRight}></Table.Cell>
                                                <Table.Cell style={textRight}></Table.Cell>
                                                <Table.Cell style={textRight}>รวมทั้งหมด</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(this.state.overview.before_cashier + this.state.overview.before_safe + this.state.summary.in)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(this.state.summary.ex)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(this.state.overview.before_cashier + this.state.overview.before_safe + this.state.summary.in - this.state.summary.ex)}</Table.Cell>
                                                <Table.Cell ></Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell colSpan='10' style={{...textLeft, color: 'red'}}>
                                                    <sup>[1]</sup>เป็นรายการหมวดหมู่ "ขายฝาก", "ค่าดอกเบี้ย", "เพิ่มเงินต้น", "ลดเงินต้น" และ "เงินต้น+ดอกเบี้ยไถ่คืน"<br/>
                                                    <sup>[2]</sup>เป็นรายการหมวดหมู่ "ขายทอง", "ซื้อทอง" และ "เปลี่ยนทอง"<br/>
                                                    <sup>[3]</sup>เป็นรายการหมวดหมู่ "ฝากเงินเปิดบัญชีออมทอง", "ฝากเงินออมทอง", "ถอนเงินออมทอง", "ถอนเงินออมทองซื้อทอง", "ถอนเงินออมทองเปลี่ยนทอง" และ "ถอนเงินออมทองไถ่คืน"<br/>
                                                    <sup>[4]</sup>เป็น ยอดยกมา และรายการหมวดหมู่ทั้งหมด ยกเว้น <sup>[1]</sup>, <sup>[2]</sup> และ <sup>[3]</sup>
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    }

                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(() => {
                                mywindow.close();
                            }, 2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}
export default connect(
    mapStateToProps,
)(PrintPreview)