/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Segment,
    Header,
    Grid,
    Statistic,
    Loader,
    Dimmer
} from 'semantic-ui-react';
import moment from 'moment';
import Settings from '../../Settings';
import Utility from '../../Utility';

import { connect } from 'react-redux'


class Gole extends Component {

    constructor(props) {
        super(props);

        this.state = {
            income: 0,
            expenses: 0,
            total: 0,
            safe_all: 0,
            cash_in: 0,
            cash_ex: 0,
            daily_ledger_balance: 0,
        }
        this.loadLedger = this
            .loadLedger
            .bind(this)
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({ visible: true })
        localStorage.setItem("goldpric", JSON.stringify({ gold_bar_sell: this.state.gold_bar_sell, gold_bar_buy: this.state.gold_bar_buy, gold_ornaments_buy: this.state.gold_ornaments_buy, time: moment() }));

        setTimeout(() => {
            this.setState({ visible: false })
        }, 1000)
        if (this.props.onSubmit) {
            this
                .props
                .onSubmit()
        }
    }

    componentDidMount() {

        const { auth } = this.props
        const { branch } = auth
        this.loadLedger(branch)
    }

    loadLedger(branch) {
        if (branch != undefined) {
            let s1 = {
                start_date: Utility.formatDate2(moment()),
                end_date: Utility.formatDate2(moment())
            }
            s1.branch = branch.id

            let date_before = new Date(s1.start_date)
            date_before.setDate(date_before.getDate() - 1)
            date_before = Utility.formatDate2(date_before)

            let s = Utility.jsonToQueryString(s1)
            var ledger = Utility.getFetch(Settings.baseUrl + '/ledger/?is_enabled=1&' + s);
            var daily_ledger = Utility.getFetch(Settings.baseUrl + "/daily_ledger_balance/?date=" + date_before + "&branch=" + branch.id)
            var safe = Utility.getFetch(Settings.baseUrl + '/safe-summary/?branch=' + branch.id);
            Promise
                .all([ledger, daily_ledger, safe])
                .then((values) => {
                    const data_branch = (values[2].data || []).find(item => item.id === branch.id)
                    let income = 0
                    let expenses = 0
                    let n_income = 0
                    let n_expenses = 0
                    for (let i in values[0]) {

                        if (values[0][i].kind == 'IN') {
                            income += parseFloat(values[0][i].total)
                            n_income++;
                        } else {
                            expenses += parseFloat(values[0][i].total)
                            n_expenses++
                        }
                    }

                    let daily_ledger_balance = 0
                    if (values[1].length != 0) {
                        daily_ledger_balance = Number(values[1][0].cash)
                    }

                    this.setState({
                        income: income,
                        expenses: expenses,
                        total: (daily_ledger_balance + income) - expenses,
                        n_income: n_income,
                        n_expenses: n_expenses,
                        is_ready: true,
                        daily_ledger_balance: daily_ledger_balance,
                        safe_all: data_branch.safe_all.reduce((sum, item) => sum + (item.last_balance), 0),
                        cash_ex: data_branch.cashier_all.reduce((sum, item) => sum + (item.total_cash_ex), 0) + data_branch.cashier_bill.reduce((sum, item) => sum + (item.total_cash_ex), 0) + data_branch.cashier_lease.reduce((sum, item) => sum + (item.total_cash_ex), 0) + data_branch.cashier_saving.reduce((sum, item) => sum + (item.total_cash_ex), 0),
                        cash_in: data_branch.cashier_all.reduce((sum, item) => sum + (item.total_cash_in), 0) + data_branch.cashier_bill.reduce((sum, item) => sum + (item.total_cash_in), 0) + data_branch.cashier_lease.reduce((sum, item) => sum + (item.total_cash_in), 0) + data_branch.cashier_saving.reduce((sum, item) => sum + (item.total_cash_in), 0),
                    })

                })
        }
    }

    render() {
        return (

            <div>
                <Grid.Row width={16}>
                    <Grid columns={2}>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <Segment color='green'>
                                    <Header as='h2'>รายรับ</Header>
                                    <right>
                                        <Statistic size='tiny' color='green'>
                                            <Statistic.Value >{Utility.priceFormat(this.state.cash_in)}</Statistic.Value>
                                            <small><Statistic.Value >{Utility.priceFormat(this.state.income)} (ทั้งหมด)</Statistic.Value></small>
                                        </Statistic>
                                    </right>
                                    <Dimmer active={!this.state.is_ready} inverted>
                                        <Loader inverted>Loading..</Loader>
                                    </Dimmer>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Segment color='red'>

                                    <Header as='h2'>รายจ่าย</Header>
                                    <right>
                                        <Statistic size='tiny' color='red'>
                                            <Statistic.Value >{Utility.priceFormat(this.state.cash_ex)}</Statistic.Value>
                                            <small><Statistic.Value >{Utility.priceFormat(this.state.expenses)} (ทั้งหมด)</Statistic.Value></small>
                                        </Statistic>
                                    </right>
                                    <Dimmer active={!this.state.is_ready} inverted>
                                        <Loader inverted>Loading..</Loader>
                                    </Dimmer>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Grid.Column />
                    <Grid.Column width={16}>
                        <Grid columns={2}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Segment color='grey'>

                                        <Header as='h2'>เงินสดยกมา</Header>
                                        <right>
                                            <Statistic size='tiny' color='grey'>
                                                <Statistic.Value >{Utility.priceFormat(this.state.daily_ledger_balance)}</Statistic.Value>
                                                <small><Statistic.Value >&nbsp;</Statistic.Value></small>
                                            </Statistic>
                                        </right>
                                        <Dimmer active={!this.state.is_ready} inverted>
                                            <Loader inverted>Loading..</Loader>
                                        </Dimmer>
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column>
                                    <Segment color='yellow'>

                                        <Header as='h2'>คงเหลือ Cashier</Header>
                                        <right>
                                            <Statistic size='tiny' color='yellow'>
                                                <Statistic.Value >{Utility.priceFormat(this.state.daily_ledger_balance + this.state.cash_in - this.state.cash_ex)}</Statistic.Value>
                                                <small><Statistic.Value >{Utility.priceFormat(this.state.total)} (ทั้งหมด)</Statistic.Value></small>
                                            </Statistic>
                                        </right>
                                        <Dimmer active={!this.state.is_ready} inverted>
                                            <Loader inverted>Loading..</Loader>
                                        </Dimmer>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <Grid columns={2}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Segment color='pink'>
                                        <Header as='h2'>คงเหลือเซฟ</Header>
                                        <right>
                                            <Statistic size='tiny' color='pink'>
                                                <Statistic.Value >{Utility.priceFormat(this.state.safe_all)}</Statistic.Value>
                                                <small><Statistic.Value >&nbsp;</Statistic.Value></small>
                                            </Statistic>
                                        </right>
                                        <Dimmer active={!this.state.is_ready} inverted>
                                            <Loader inverted>Loading..</Loader>
                                        </Dimmer>
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column>
                                    <Segment color='blue'>
                                        <Header as='h2'>คงเหลือทั้งหมด</Header>
                                        <right>
                                            <Statistic size='tiny' color='blue'>
                                                <Statistic.Value >{Utility.priceFormat((this.state.daily_ledger_balance + this.state.cash_in - this.state.cash_ex) + this.state.safe_all)}</Statistic.Value>
                                                <small><Statistic.Value >{Utility.priceFormat(this.state.total + this.state.safe_all)} (ทั้งหมด)</Statistic.Value></small>
                                            </Statistic>
                                        </right>
                                        <Dimmer active={!this.state.is_ready} inverted>
                                            <Loader inverted>Loading..</Loader>
                                        </Dimmer>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </div>
        );
    }
}

const mapStateToProps = state => {

    return ({
        branches: state.branches,
        auth: state.auth
    })
}
export default connect(
    mapStateToProps
)(Gole)
