/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/
import React, { Component } from 'react';
import { Grid, Header, Label, Icon } from 'semantic-ui-react';
import GoldPrice from './GoldPrice';
import StockProduct from './StockProduct'
import StockCategory from './StockCategory'
import SellYear from './SellYear'
import Income from './Income';
import AmountCustomer from './AmountCustomer';
import AmountProduct from './AmountProduct';
import DropdownBranch from '../Widget/DropDown/DropDownBranch'
import { activations_branch } from '../../actions'
import { connect } from 'react-redux'
import Settings from '../../Settings';
var config = require('../../config')


class Dashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            branch: props.auth.branch == undefined ? '' : props.auth.branch.id,
            is_ready: false
        }
    }

    handlerInput(e, { value }) {

        if (value != this.state.branch) {
            const { branches } = this.props
            const branch = branches.find((item) => item.id == value)
            this.props.handleBranchChange(branch)
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ is_ready: true })
        }, 1000)

    }

    render() {
        const { branch, role } = this.props.auth
        const { is_ready } = this.state
        return (
            <div>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={6}>
                            <Header floated='left' as='h3'>
                                <div  ><Icon name='dashboard' /> Dashboard </div>
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Income />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        {/* <Grid columns={2}>
                            <Grid.Row> */}
                        {/* <Grid.Column width={8}>
                                    <AmountCustomer /> <br />
                                    <AmountProduct />
                                </Grid.Column> */}
                        <Grid.Column width={8}>
                            <GoldPrice />
                        </Grid.Column>
                        {/* </Grid.Row>
                        </Grid> */}
                    </Grid.Row>
                    {/* <Grid.Row>
                        <Grid.Column width={8}>
                            <StockProduct />
                            <StockCategory />
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <SellYear />
                        </Grid.Column>
                    </Grid.Row> */}

                </Grid>
                <br /><br />
                <center>
                    {config.env == 'production' ? <br /> : <Label as='i' color='blue'>{config.env}</Label>}
                    <Label><i>V{Settings.version}</i></Label>
                </center>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}
const mapDispatchToProps = dispatch => ({
    handleBranchChange: (branch) => {
        dispatch(activations_branch(dispatch, branch))
    }
})
export default connect(
    mapStateToProps, mapDispatchToProps
)(Dashboard)

